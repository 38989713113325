Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.orientationVideoAPi = "bx_block_upload_media/videos?video_name=intro video"

exports.apiContentType = "application/json";
exports.apiGetMethodType = "GET";
exports.HgFormulaContentType="application/json"
//exports.HgFormulaUrl="bx_block_dashboard/hg_formulas"; not use this URL 
exports.HgFormulamethodtype="GET"


exports.apiVideoCallUrl="bx_block_upload_media/videos?video_type=onboarding"

//All Videos
exports.apiAllVideoCallUrl="bx_block_upload_media/videos"

exports.apiPostIntroVideoCallMethodType = "POST";
exports.apiPostIntroVideoCallUrl="bx_block_student/track_time/update_video_detail"

exports.apiGetIntroVideoTimeCallMethodType = "POST";
exports.apiGetIntroVideoTimeCallUrl="bx_block_student/track_time/onboarding_video_detail"


exports.hgFormulaUrl="bx_block_dashboard/hg_formulas"
exports.apiUserCallUrl="bx_block_customisableuserprofiles/profiles/6"

exports.calcGradePage="bx_block_student/grades"
exports.calcProgramPage="bx_block_student/programs"
exports.calcAccreditionPage="bx_block_student/accreditations"
exports.feesProgram = "bx_block_dashboard/program_fees";
exports.test = "testnew"
exports.newData = "Test"
// Customizable Area End