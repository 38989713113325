//@ts-nocheck
import React from 'react';
import { createStyles, makeStyles,  Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';

import Backdrop from "@material-ui/core/Backdrop";
import Popover from '@material-ui/core/Popover';



import {
   // Customizable Area Start
  Box,
  Button,
  Modal

  // Customizable Area End
} from "@material-ui/core";
import { logo, avatar, groupmenu, } from '../../blocks/dashboard/src/assets';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'; 
import { removeStorageData, getStorageData } from '../../framework/src/Utilities';
import SessionManage from './SessionManage.web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    logoutModal: {
      position: "relative"
    },
    
    logoutMessage:{
      textAlign:'center',
      fontFamily:"Poppins, sans-serif",
      margin:'0'
    },
    confirmationMessage:{
      textAlign:'center',
      fontSize:'large',
      color:'rgb(146, 146, 157)',
      fontFamily:"Poppins, sans-serif",
      marginTop:'10px'
    },
    logoutButton: {
      backgroundColor: 'rgb(250, 176, 61)', color: 'white', padding: '5px 15px', fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: 'Poppins, sans-serif',
      '&:hover': {
        backgroundColor: 'rgb(250, 176, 61)',
        boxShadow: 'none',
      },
    },
    cancelButton: {
      position: "absolute",
      top: "-3%",
      background: "rgb(185, 142, 214)",
      color: "white",
      right: "-10px",
      borderRadius: '20px',
      border: 'none',
      cursor: 'pointer',
      
      padding: '5px 10px'
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,

    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: "#fff"
    },
    overrides: {

      MuiAppBar: {
        root: {
          backgroundColor: "white"
        }
      },

    }
  }),
);


const AppWithoutDrawer = ({ navigation,props }: any) => {

  const classes = useStyles();
  const [watchComplete, setWatchComplete] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [backdrop, setBackdrop] = React.useState(false)
  const token = async() => {
    const drop = await getStorageData('backdrop');
    if (drop !== null ){
      setBackdrop(drop);
    }
  }

  const setButton = async() => {
    const test = await getStorageData('complete');
    if (test === "true") {
      setWatchComplete(true)
    } else {
      setWatchComplete(false)
    }
  }

  React.useEffect (() => {
    setButton()
    token()
  },[])

  
  const handleClickOpenLogOutModal = () => {
   
    setOpen(true);
  };

  const handleCloseLogOutModal = () => {
  
    setOpen(false)
  };




  const handleClickDrop = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
  
    handleCloseDrop()
    handleClickOpenLogOutModal()
   
  }

  const logoutButtonHandler:any = () => {

   
    localStorage.removeItem('authToken')
    localStorage.removeItem('StudID')
    localStorage.removeItem('hglink')
    removeStorageData('complete')
    removeStorageData('curentTime')
    // history.push("home")
    window.location.replace("/")
    // props.navigation.navigate('EmailAccountLoginBlock')
    handleCloseLogOutModal()
  }

  const navigateTOHome: any = () => {
    if (navigation) {
    localStorage.removeItem('hglink')
    watchComplete ? navigation.navigate('DashBoardDayInLifeVideo')
      : navigation.navigate('DashBoardNonExpandablePage')
    }

     
  }

  return (
    <div className={classes.root}>
      <SessionManage />
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <span style={{ width: '100%' }}>
          {backdrop && <Backdrop style={{ zIndex: 100, color: "#fff" }} open={backdrop}></Backdrop>}
        </span>
        <Toolbar style={{ justifyContent: 'space-between', backgroundColor: '#fff' }}>
          <Box style={{ width: '20%' }}>
            <img src={logo} alt='logo image' style={webStyle.imgLogoStyle} onClick={navigateTOHome} />

          </Box>
          <Box style={{ display: 'flex', alignItems: 'end', textAlign: 'end', justifyContent: 'space-between' }}>
            <Avatar alt="client avatar" src={avatar} style={webStyle.avatarStyle} />
            <Typography style={{ color: '#000', cursor:'pointer' }} onClick={handleClickDrop} >{localStorage.getItem('username')}</Typography>

            <ArrowDropDownIcon style={{ color: '#92929d', cursor: 'pointer' }} onClick={handleClickDrop} />
            <Popover 
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseDrop}
              // style={{ marginTop: '30px' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Button onClick={handleLogOut} style={{ textTransform: 'none' , width:'200px', height: '50px', fontFamily: 'Poppins, sans-serif',}}>Logout</Button>
              </Popover >
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                <h2 className={classes.logoutMessage}>You are attempting to logout of HG Application.</h2>
                  <Typography className={classes.confirmationMessage}>Are you sure?</Typography>
                  <button className={classes.cancelButton} autoFocus onClick={handleCloseLogOutModal}>
                    X
                  </button>
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                      variant="contained"
                      className={classes.logoutButton} onClick={logoutButtonHandler}>LOGOUT</Button>
                  </div>
                </Box>
              </Modal>

          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
const webStyle = {
  imgLogoStyle: {
    width: '100%',
    cursor: 'pointer',
  },
  avatarStyle: {
    width: '32px',
    height: '32px',
    marginRight: '10px',
  }
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',

  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: "none",
};


export default AppWithoutDrawer;