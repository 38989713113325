import React, { useState,  useRef, useEffect } from "react";

import {
  Box,
  Button,
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import {  makeStyles } from '@material-ui/core/styles';
import PauseIcon from '@material-ui/icons/Pause';
import { getStorageData } from "../../framework/src/Utilities";



const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },


});

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
    // cursor: "pointer"
  },
  controlWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  controlIcons: {
    color: '#777',
    fontSize: 50,
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  bottomIcons: {
    color: '#eeeee4',
    transform: 'scale(0.9)',
    "&:hover": {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  volumeSlider: {
    width: 100,
  },
});



const IntroVideo = ({ handleNext, handleWatchLater, introVideoURL, watchTime  , isIntroVideo,height, storage }: any) => {

  const classes = useStyles();
  const [watchComplete, setWatchComplete] = useState(false)
  const [watchCompleted, setWatchCompleted] = useState(false)
  const [playing, setPlaying] = useState(true)
  const [videoTotalTime, setVideoTotalTime] = useState(0)
  const [videoCurrentTime, setVideoCurrentTime] = useState(watchTime)
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef<any>(null)

  useEffect(() => {
    setButton()
  }, []);

  const setButton = async() => {
    const test = await getStorageData('complete');
    if (test === "true") {
      setWatchComplete(true)
    } else {
      setWatchComplete(false)
    }
  }
  
  const handleWatchComplete = async ({ played }: any) => {
    const test = await getStorageData('complete');
    if (test === "true") {
      setWatchComplete(true)
    } else {
      setWatchComplete(false)
    }
    if (played > 0.999) {
      setWatchCompleted(true)
      setVideoTotalTime(playerRef.current.getDuration())
      setVideoCurrentTime(playerRef.current.getCurrentTime())
      if (storage && test) {
      localStorage.setItem('curentTime', String(videoCurrentTime))
      localStorage.setItem('complete', String(watchCompleted))
      console.log(videoTotalTime)
      }
    }
    else {
      setVideoTotalTime(playerRef.current.getDuration())
      setVideoCurrentTime(playerRef.current.getCurrentTime())
      setWatchCompleted(false)
      if (storage && test) {
      // localStorage.setItem('curentTime', String(videoCurrentTime))
      // localStorage.setItem('complete', String(watchCompleted))
      }
    }
  }
  const handlePlayPause = () => {
    setPlaying(!playing)
  }
  const handlePlay = () => {
    setPlaying(!playing)
  }

  const onReady = React.useCallback(() => {
    if (!isReady) {
      playerRef.current.seekTo(watchTime, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  return (

    // Customizable Area Start
    <>
      <ThemeProvider theme={theme}>

        <Box style={{ display: 'flex', alignItems: 'center' }} >

        <div className={classes.playerWrapper}>
  <ReactPlayer
    config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate', disablePictureInPicture: true } } }}
    // Disable right click
    onContextMenu={(e: any) => {
      e.preventDefault()
    }}
    ref={playerRef}
    width="100%"
    //height={height} // Adjust the height here
    url={introVideoURL}
    muted={false}
    playing={playing}
    controls={true}
    onProgress={handleWatchComplete}
    onReady={onReady}
    pip={false}
    // onEnded={() => {
    //   handleNext(videoCurrentTime, watchCompleted)
    // }}
    style={{ overflow: 'hidden' }}
  />

  {isReady ?
    <div className={classes.controlWrapper}>
      <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
        {
          playing                     
        }
      </Box>
    </div>
    : null
  }
</div>


        </Box>

        {isReady ?
        isIntroVideo
        ? (
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1em' }}>
            {watchComplete
              ? <Button variant='contained' style={webStyle.btnWatchLater} onClick={() => handleNext(videoCurrentTime, watchCompleted)}>Next </Button>
              : <Button variant='contained' style={webStyle.btnWatchLater} onClick={() => handleWatchLater(videoCurrentTime, watchCompleted)}>Watch Later </Button>
            }
          </Box>)
        : (
          <Box style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', position: "relative" }}>
            <Button variant='contained' style={{ textTransform: "none", ...webStyle.btnWatchLater }} onClick={handlePlay}>Click to watch video </Button>
          </Box>
        )
       : null 
      }
      </ThemeProvider>
    </>
  );
}

// Customizable Area Start
const webStyle = {
  btnWatchLater: {
    width: "270px",
    height: "52px",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginBottom: '2em',
    zIndex: 1,
    marginTop: '3px'
  },
};

export default IntroVideo;
// Customizable Area End
