import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { pwdRequire } from "../../../components/src/asset";
export interface Root {
  [key: string]: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string
  errors: Root
  snackbarOpenFalse: boolean;
  loadingSignin: boolean;
  loaderButtonDisable: boolean;
  passwordInputState: string;
  passwordState: boolean;
  password: string;
  snackbarPasswordOpenFalse: boolean;
  passwordLoading: boolean;
  passwordButtonLoading: boolean;
  forgetPasswordAPIError: string;
  snackbarMailSuccess: boolean;
  resetToken: string
  newPasswordAPIError: string;
  snackbarPasswordSuccess: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailForgotCallId: string = "";
  apiNewPasswordCallId: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      email: '',
      errors: {},
      snackbarOpenFalse: false,
      loadingSignin: false,
      loaderButtonDisable: false,
      passwordInputState: configJSON.pwd,
      passwordState: true,
      password: "",
      snackbarPasswordOpenFalse: false,
      passwordLoading: false,
      passwordButtonLoading: false,
      forgetPasswordAPIError: '',
      snackbarMailSuccess: false,
      resetToken: '',
      newPasswordAPIError: '',
      snackbarPasswordSuccess: false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (token) {
      this.setState({ resetToken: token });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailForgotCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({
            forgetPasswordAPIError: responseJson.errors[0].account,
            snackbarOpenFalse: true,
            loaderButtonDisable: true
          })
        }
        else {
          this.setState({
            forgetPasswordAPIError: responseJson.message,
            snackbarMailSuccess: true,
            loadingSignin: true,
            loaderButtonDisable: false
          })
        }
      }
      if (apiRequestCallId === this.apiNewPasswordCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({
            newPasswordAPIError: this.handleKeyError(responseJson.errors[0].password, responseJson.errors[0].token),
            snackbarPasswordOpenFalse: true,
            passwordButtonLoading: true,
          })
        } else {
          this.setState({
            newPasswordAPIError: "Password reset successfully. Plase login again",
            snackbarPasswordSuccess: true,
            passwordLoading: true,
            passwordButtonLoading: false
          })
        }
      }
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area Start
  onEmailChange = (text: string) => {
    this.setState({
      email: text,
      loadingSignin: false
    })
    this.state.errors["email"] = ""
  }

  formValidateEmail = () => {
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (this.state.email === "") {
      this.state.errors["email"] = "Email address is required";
      isFormValid = false;
    } else if (!regex.test(this.state.email)) {
      isFormValid = false;
      this.state.errors["email"] = "Enter valid email address";
    }
    return isFormValid;
  };

  navigateToLogin = () => {
    this.props.navigation.navigate('Home');
  }

  handleCloseAlert1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenFalse: false, loaderButtonDisable: false, loadingSignin: false })
  };

  handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ loadingSignin: true, snackbarMailSuccess: false })
  };

  sentEmailSubmit = () => {
    if (this.formValidateEmail()) {
      this.setState({ loaderButtonDisable: true, loadingSignin: true })
      this.state.errors["email"] = ''
      this.forgotPasswordAPI()
    } else {
      this.setState({
        errors: this.state.errors,
      });
    }
  }

  changePasswordState = () => {
    if (this.state.passwordState) {
      this.setState({ passwordState: false })
      this.setState({ passwordInputState: configJSON.txt })
    }
    else {
      this.setState({ passwordState: true })
      this.setState({ passwordInputState: configJSON.pwd })
    }
  }

  formValidatePassword = () => {
    let isFormValid = true;
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\/{}\\[?:";'|<>,.~@#$%^&*()_+])[A-Za-z\d!\/{}\\[?:";'|<>,.~@#$%^&*()_+]{8,}$/
    if (this.state.password === "") {
      this.state.errors["password"] = pwdRequire;
      isFormValid = false;
    }

    if(this.isStringNullOrBlank(this.state.password)){
      this.state.errors["password"] =  `Password must not be empty`
      isFormValid = false;
    } else if (!passwordRegx.test(this.state.password)) {
      this.state.errors["password"] = `Password must contain Min 8 characters with both upper and lower cases, atleast one digit and one special character required`
      isFormValid = false;
    }

    return isFormValid;
  };

  onPasswordChange = (text: string) => {
    this.setState({
      password: text,
      passwordLoading: false
    })
  }

  resetPasswordSubmit = () => {
    if (this.formValidatePassword()) {
      this.setState({ passwordLoading: true, passwordButtonLoading: true })
      this.state.errors["password"] = ''
      this.newPasswordAPI()
    } else {
      this.setState({
        errors: this.state.errors,
      });
    }
  }

  handleCloseAlertPassword = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarPasswordOpenFalse: false, passwordButtonLoading: false, passwordLoading: false })
  };

  handleCloseAlertPasswordSuccess = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarPasswordSuccess: false, passwordLoading: true })
  };

  forgotPasswordAPI = () => {
    const body = {
      "data": {
        "email": this.state.email
      }
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailForgotCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPassword
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  newPasswordAPI = () => {
    if (this.state.resetToken) {
      const body = {
        "data": {
          "new_password": this.state.password
        }
      }
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        "token": this.state.resetToken
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiNewPasswordCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.newPassword
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    } else {
        this.setState({
            newPasswordAPIError: "Token doesn't exist, Please re-sent mail again.",
            snackbarPasswordOpenFalse: true,
            passwordLoading: true,
        })
    }
  }

  isStringNullOrBlank(strin: string) {
    return strin === null || strin.length === 0;
  }

  handleKeyError = (key1: string, key2: string) => {
    let error = ''
    error = key1 || key2
    return error;
  }
  // Customizable Area End
}
