import React, { useState} from "react";

import {
    // Customizable Area Start
    
   Grid,
   
    // Customizable Area End
} from "@material-ui/core";
import TicketUploadComponent from "./TicketUploadComponent.web"

// Customizable Area Start



const TicketFileUpload = ({ uploadFiles = () => {}, ticketFile }:any) => {
  const [acceptType] = useState( {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc'],
  })
  console.log("ValueuploadFiles:", uploadFiles);
  return (
    // Customizable Area Start
    <>
    
          <Grid container style={webStyle.gridContainerStyle}>
            <Grid container style={{marginTop:"20px"}}>
              <TicketUploadComponent uploadFiles={uploadFiles} ticketFile={ticketFile} purpose='ticketFile' acceptType={acceptType}  />
           </Grid>
          </Grid>
     </>
  );
}

// Customizable Area Start
const webStyle = {
  gridContainerStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
};

export default TicketFileUpload;
// Customizable Area End