export const pwdRequire = "Password is required"
export const pwd = "password"
export const txt = "text"

export const toggleCheckboxState = (selectedStudentIds:number[] , id: number) => {
    if (selectedStudentIds.includes(id)) {
      return selectedStudentIds.filter((selectedId) => selectedId !== id);
    } else {
      return [...selectedStudentIds, id];
    }
  };