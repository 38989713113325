import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';

const TicketUploadComponent = ({ uploadFiles, purpose, acceptType, ticketFile }: any) => {
  const [fileRecommendation, setFileRecommendation] = useState({
    name: '',
    size: '',
    type: '',
  });
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let array = [];
    if (ticketFile) {
      array.push(ticketFile)
      onDrop(array)
    }
  }, [ticketFile])

  const onDrop = useCallback((acceptedFiles) => {
    setIsError(false);
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.type.startsWith("image/")) {
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setPreviewImage(null);
      }
      if (acceptedFiles[0]?.type.startsWith("image/") || acceptedFiles[0]?.type === acceptType) {
        setFileRecommendation({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2) + 'MB',
          type: file.type,
        });
        uploadFiles(file, purpose);
      } else {
        setIsError(true);
      }
    }
  }, [uploadFiles, purpose, acceptType]);
  

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isFocused } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptType
  });

  const style = useMemo(() => ({
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: isFocused ? '#f2f2f2' : '#f9f9f9',
    width: '100%',
    cursor: 'pointer',
    border: '2px dashed #eeeeee',
  }), [isFocused]);

  return (
    <>
      <Grid item xs={12}>
        <Box {...getRootProps({ style })}>
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {previewImage ? (
              <img src={previewImage} alt="Preview" style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'contain' }} />
            ) : (
              <>
                <CloudUploadOutlinedIcon style={{ color: '#b1b1b1' }} />
                {!isDragActive && (
                  <>
                    <Typography style={{ color: '#b1b1b1', fontFamily: "'Poppins-Medium', sans-serif", fontSize: '11px', fontWeight: 500, lineHeight: '22px' }}>
                      Drag and drop a file here or click
                    </Typography>
                    <Typography style={{ color: '#009aff', fontFamily: "'Poppins-Medium', sans-serif", fontSize: '11px', fontWeight: 400, lineHeight: '22px', textDecoration: 'underline' }}>
                      Browse file
                    </Typography>
                  </>
                )}
              </>
            )}
          </Box>

          {isDragAccept && (
            <Typography style={{ color: '#00e676', fontFamily: "'Poppins-Medium', sans-serif", fontSize: '11px', fontWeight: 500, lineHeight: '22px' }}>
              This file will be accepted
            </Typography>
          )}
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>

          <Box>
            {isError && purpose !== 'picture' && (
              <Typography style={{ color: 'red', fontFamily: "'Poppins-Medium', sans-serif", fontSize: '11px', fontWeight: 500, lineHeight: '22px' }}>
                Error: Only .pdf/.docx/.jgeg/.png files supported. File should not be more than 5MB.
              </Typography>
            )}
            {isError && purpose === 'picture' && (
              <Typography style={{ color: 'red', fontFamily: "'Poppins-Medium', sans-serif", fontSize: '11px', fontWeight: 500, lineHeight: '22px' }}>
                Error: Only jpeg file is supported. File should not be more than 5MB.
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default TicketUploadComponent;