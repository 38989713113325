// Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Button,
    InputLabel,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Paper,
    CardMedia,
    Select,
    FormControl

} from "@material-ui/core";
import {
    Backgroundimg,
    HomeSchoolRecomender,
    questions,

} from './assets';
import DashboardController from "./DashboardController.web";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import CircularProgress from '@material-ui/core/CircularProgress';
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import HgVideo from "../../../components/src/HgVideo.web";
import ChatBot from "../../../components/src/ChatBot.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";



const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
        },
        MuiMenu: {
            paper: {
                maxHeight: 'calc(50% - 96px)',
                top: '280px'
            },
        },
    },
});

const StyledTextField = withStyles({
    root: {
        maxWidth: '100%',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '16',
            fontWeight: 400,
            width: '222.98px',
            height: '19px',
            color: '#44444f'

        },
        "& .MuiInputBase-input.MuiInput-input": {
            fontFamily: 'Poppins, sans-serif',
        }
    },

})((props: any) => <TextField {...props} />);
const GetErrorStyledayinLife = (props: any) => {
    const { stateType } = props;
    return (
        <Box>
            {
                stateType
                    ? <small style={webStyle.errorStyle}>{stateType}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
            }
        </Box>
    )
  }

export default class DashBoardDayInLifeVideo extends DashboardController {
    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <AppWithoutDrawer navigation={this.props.navigation} />
            <Sidebarmenu />
             <ThemeProvider theme={theme}>
                <Container maxWidth={false}>
                  
                    <Grid container>
                        <Grid item>
                            <Container maxWidth={false} >
                              
                                <Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>

                                    <Box style={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Typography style={webStyle.welcomeText} >Welcome {this.state?.userData?.fullName}</Typography>
                                        {
                                            !this.state.userData?.isActive && this.state.userData?.isActive !== '' ?
                                                <Typography style={webStyle.verifyText} >Verify your Email Address to continue with enrollment!</Typography>
                                                : null
                                        }

                                        <Button style={webStyle.welcomeText1}
                                         onClick={this.handleNavigation}                                        
                                        >
                                            ENROLL NOW
                                        </Button>
                                    </Box>
                                    <Grid container style={{ display: 'flex' }}>
                                        <HorizontalLine />
                                    </Grid>

                                    <Container maxWidth={false} style={{ padding: '0' }}>
                                       
                                        <Grid container spacing={3} style={{ display: "flex", alignItems: "flex-start", marginTop: '2em' }}>

                                           
                                            <Grid item xs={12} lg={7} >
                                                <Typography align='left' style={webStyle.headerTextStyle}>Our Programs</Typography>
                                                <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerProgram as React.CSSProperties }}  >
                                                    <div style={webStyle.backgroundOverlay as React.CSSProperties}></div>
                                                    <Grid container style={{ display: 'flex', alignItems: 'stretch', boxSizing: 'border-box', padding: '0.5vw', position: "relative", zIndex: 2 }}>
                                                      
                                                        <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5vw', border: '2px solid #fab03d', borderRadius: '15px' }}>
                                                            <Box>
                                                                <Typography align='left' style={webStyle.programMainTxt}>Touch</Typography>
                                                                <Typography align='left' style={webStyle.programMainSubTxt}>
                                                                    Parent-led learning with comprehensive advisor support
                                                                    involving parental instruction
                                                                </Typography>
                                                            </Box>

                                                            <Paper id="handleTouchPrgId1" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Curated</Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>
                                                                            Customizable curriculum with comprehensive support and guidance from seasoned Advisor
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                         

                                                            <Paper id="handleTouchPrgId2" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Charlotte Mason </Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>Customizable Charlotte
                                                                            Mason curriculum with
                                                                            comprehensive support and
                                                                            guidance from a Charlotte
                                                                            Mason Specialist</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                           

                                                            <Paper id="handleTouchPrgId3" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Classical</Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>Classical Christian curriculum
                                                                            with comprehensive support
                                                                            and guidance through weekly
                                                                            community classes, powered
                                                                            by Classical Conversations
                                                                            Philippines</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                       

                                                     


                                                        <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #B98ED6', borderRadius: '15px' }} >
                                                            <Box>
                                                                <Typography align='left' style={webStyle.programMainTxt}>Tech</Typography>
                                                                <Typography align='left' style={webStyle.programMainSubTxt}>
                                                                    Parent-led learning with platform- driven essential advisor support involving parental supervision
                                                                </Typography>
                                                            </Box>

                                                            <Paper id="handleTechPrgId1" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>BJU (Blended)</Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>
                                                                            High-quality blended curriculum with essential Advisor support</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                          

                                                            <Paper id="handleTechPrgId2" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>AOP (Fully Online) </Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>High-quality fully online
                                                                            curriculum with essential
                                                                            Advisor support</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                          

                                                            <Paper id="handleTechPrgId3" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>

                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Custom (Open)</Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>Customizable curriculum
                                                                            with platformdriven essential
                                                                            Advisor support and basic
                                                                            academic assistance for
                                                                            records designed for
                                                                            home schooling families 3
                                                                            years and up</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                     
                                                        <Grid item style={{ margin: '0.5vw', width: '30%', flexGrow: 1, backgroundColor: 'rgba(255,255,255,0.5)', padding: '0.5em', border: '2px solid #8FBE49', borderRadius: '15px' }}>
                                                            <Box>
                                                                <Typography align='left' style={webStyle.programMainTxt}>Online Academies</Typography>
                                                                <Typography align='left' style={webStyle.programMainSubTxt}>
                                                                    Flexible online learning with teacher
                                                                    supervision, grading, and optional
                                                                    academic classes involving minimal
                                                                    parental supervision
                                                                </Typography>
                                                            </Box>
                                                            <Paper id="handleAcadmiesPrgId1" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>
                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Online Academy</Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>
                                                                            Customizable curriculum
                                                                            with comprehensive
                                                                            support and guidance
                                                                            from seasoned Advisor
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                            

                                                            <Paper id="handleAcadmiesPrgId2" elevation={0} style={webStyle.gridProgramContainerStyle} >
                                                                <Avatar alt="program avatar" style={webStyle.programAvatar}
                                                                    src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                                                />
                                                                <Box style={webStyle.programBox}>
                                                                    <Box>
                                                                        <Typography align='left' style={webStyle.ProgramInnerTextHeader1}>Online Academy Plus </Typography>
                                                                        <Typography align='left' style={webStyle.ProgramInnerSubTxt}>Customizable Charlotte
                                                                            Mason curriculum with
                                                                            comprehensive support and
                                                                            guidance from a Charlotte
                                                                            Mason Specialist</Typography>

                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>

                                          
                                            <Grid item xs={12} lg={5}  >

                                                <Typography align='left' style={{...webStyle.headerTextStyle, paddingBottom:0}}>Homeschool Recommender</Typography>
                                                <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerRecommender }} >
                                                    <Box style={webStyle.boxOrientation}>
                                                        <CardMedia
                                                            component="img"
                                                            image={HomeSchoolRecomender}
                                                            title="HomeSchoolRecomender"
                                                            style={{ height: '70%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '1.2em' }}

                                                        />
                                                        <Button
                                                            variant='contained'
                                                            style={{ textTransform: "none", ...webStyle.btnRecommendation }}
                                                            data-test-id='btnHomeRecomm'
                                                            onClick={this.handlePersonalisedNavigate}
                                                        >Get your Personalised Recommendation</Button>
                                                    </Box>

                                                </Paper>

                                            </Grid>
                                        </Grid>


                                       
                                        <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch" }}>

                                           

                                            <Grid item xs={12} lg={7}  >
                                                <Typography align='left' style={webStyle.headerTextStyle}>Day in the Life of Homeschooler</Typography>
                                                <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerTestimonial }}  >
                                                    <Box style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                        {this.state.allVideos_homeSchoolerVideoURL ?
                                                            <>
                                                                <HgVideo introVideoURL={this.state.allVideos_homeSchoolerVideoURL} />
                                                                <Box style={webStyle.boxGreenHomeSchooler}>
                                                                    <Typography align='center' style={webStyle.btnHomeSchoolerText}>{this.state?.allVideos_homeSchoolerVideoName}</Typography>
                                                                    <Typography align='center' style={webStyle.btnHomeSchoolerSubText}>Home Schooler</Typography>
                                                                </Box>
                                                            </>
                                                            :
                                                            <Box style={webStyle.circularLoader}> <CircularProgress /></Box>
                                                        }
                                                    </Box>
                                                </Paper>
                                            </Grid>

                                        
                                            <Grid item xs={12} lg={5}  >

                                                <Typography align='left' style={webStyle.headerTextStyle}>Fees Calculator</Typography>
                                                <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerFeesCalc }} >

                                                    <Grid container style={{ boxSizing: 'border-box', padding: '2em' }}>
                                                     
                                                        <Grid item xs={12} >


                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>Grade Level * </InputLabel>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        required
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={this.state.grade}
                                                                        label="Select Grade Level"
                                                                        onChange={this.gradeChangeHandler}
                                                                        data-test-id="selectGradeDropdown"
                                                                        placeholder="Select Grade Level"
                                                                    >
                                                                        <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose">
                                                                            <span style={{ color: "darkgray" }}>Select Grade Level</span>
                                                                        </MenuItem>
                                                                        {
                                                                            this.state.gradeApiData?.map((item: any, index: number) => (
                                                                                <MenuItem
                                                                                    key={item?.id}
                                                                                    value={item?.id}
                                                                                    selected={item?.attributes?.grade}
                                                                                >
                                                                                    {item?.attributes?.grade}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            <GetErrorStyledayinLife stateType={this.state.ticketErrorsDaylife.grade} />
                                                        </Grid>

                                                       

                                                        <Grid item xs={12} >
                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>Program * </InputLabel>
                                                                <StyledTextField
                                                                    select
                                                                    required
                                                                    InputLabelProps={{ shrink: false }}
                                                                    fullWidth={true}

                                                                    name='program'
                                                                    value={this.state.program}
                                                                    onChange={this.programChangeHandler}
                                                                    data-test-id="selectProgramDropdown"
                                                                >
                                                                    <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose">
                                                                        <span style={{ color: "darkgrey" }}>Please Select</span>
                                                                    </MenuItem>
                                                                    {
                                                                        this.state.programApiData?.map((item: any, index: number) => (
                                                                            <MenuItem
                                                                                key={item?.id + index}
                                                                                value={item?.id}
                                                                            >
                                                                                {item?.attributes?.program_name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledTextField>
                                                            </Box>
                                                            <GetErrorStyledayinLife stateType={this.state.ticketErrorsDaylife.program} />
                                                        </Grid>

                                                       

                                                        <Grid item xs={12} >
                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>Accreditation * </InputLabel>
                                                                <StyledTextField
                                                                    select
                                                                    required
                                                                    InputLabelProps={{ shrink: false }}
                                                                    fullWidth={true}
                                                                    name='accreditation'
                                                                    value={this.state.accreditation}
                                                                    onChange={this.accredationChangeHandler}
                                                                    onFocus={this.hideErrorfive}
                                                                    data-test-id="selectAccreditationDropdown"
                                                                >

                                                                    <MenuItem style={{ display: "none", color: 'darkgray' }} value="choose">
                                                                        <span style={{ color: "darkgrey" }}>Please Select</span>
                                                                    </MenuItem>
                                                                    {
                                                                        this.state.accreditationApiData?.map((item: any, index: number) => (
                                                                            <MenuItem
                                                                                key={item?.id + index}
                                                                                value={item?.id}
                                                                            >
                                                                                {item?.attributes?.name}
                                                                            </MenuItem>
                                                                        ))
                                                                    }

                                                                </StyledTextField>
                                                            </Box>
                                                            <GetErrorStyledayinLife stateType={this.state.ticketErrorsDaylife.accreditation} />
                                                        </Grid>

                                                     
                                                            {
                                                                this.state.toggleFeeCaculator ?
                                                                    <>
                                                                        {this.state.feesProgramApiData[0]?.attributes ?
                                                                            <Grid item xs={12}>
                                                                                <Typography>The Fees is : {this.state.feesProgramApiData[0]?.attributes?.fees} </Typography>
                                                                            </Grid>
                                                                            :
                                                                            <Grid>
                                                                                <Typography style={{color: 'red'}}>{this.state.feesProgramApiData} </Typography>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                    :
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Typography style={{ visibility: 'hidden' }}>"" </Typography>
                                                                    </Grid>
                                                                </>

                                                        }
                                                       
                                                        <Grid item xs={12}>
                                                            <Box style={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                                                                <Button
                                                                    variant='contained'
                                                                    style={{ textTransform: "none", ...webStyle.btnQuestions }}
                                                                    onClick={() => this.feeCalculatorHandler()}
                                                                    data-test-id='btnFeeCalc'
                                                                >
                                                                    Calculate
                                                                </Button>
                                                            </Box>
                                                        </Grid>

                                                     

                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                       
                                        <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                                           
                                            <Grid item xs={12} lg={7}  >
                                                <Typography align='left' style={webStyle.headerTextStyle}>Got more questions? Meet the HG Community</Typography>
                                                <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerQuestions }}  >

                                                    <Box style={{ width: '100%', minHeight: '15em' }}>
                                                        <img src={questions} style={{ objectFit: 'cover', width: '100%', height: '100%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} />
                                                    </Box>
                                                </Paper>
                                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button variant='contained' style={{ textTransform: "none", ...webStyle.btnQuestions }}>
                                                        <a style={webStyle.btnQuestionsLink} target='_blank' href='https://www.tickettailor.com/events/homeschoolgloballearninginc'>Register for free</a>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                           
                                            <ChatBot />
                                        </Grid>
                                    </Container>
                                </Container>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
            </div>
        )
    }

    // Customizable Area End
}

// Customizable Area Start

const webStyle = {
    welcomeText1: {
        height: "52px",
        fontFamily: "'Poppins-Medium', sans-serif",
        width: "235px",
        fontSize: "16px",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "10px",
        backgroundColor: "#fab03d"
    },
    verifyText: {
        color: '#a8a8a8;',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontWeight: 400,
        fontStyle: 'italic',
    },
    welcomeText: {
        color: '#171725',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '24px',
        fontWeight: 600,
    },
    HomeSchoolRecomender: {
        color: " #171725",
        fontFamily: "'Poppins-Medium', sans-serif",
        fontSize: "18px",
        fontWeight: 600,
        textAlign: 'left'
    },
    paperContainerProgram: {
        position: 'relative',
        alignText: 'center',
        minHeight: '20vw',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        padding: '0.5vw',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'hidden',
    },
    paperContainerTestimonial: {

        alignText: 'center',
        width: '100%',
        minHeight: '400px',
        borderRadius: '15px',
        backgroundImage: `url(${Backgroundimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1em',
        flexDirection: 'column' as 'column'
    },
    paperContainerQuestions: {
        alignText: 'center',
        width: '100%',
        minHeight: '100px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',


    },
    modalTextSubTitleVideo: {
        color: '#171725',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '25px',
    },
    paperContainerRecommender: {

        width: '100%',
        minHeight: '300px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignText: 'center',
    },
    paperContainerFeesCalc: {
        alignText: 'center',
        width: '100%',
        minHeight: '100px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: ' 0px 5px 15px rgba(68,68,79,0.1)',
    },

    gridProgramContainerStyle: {

        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
        padding: '10px',

        display: 'flex',
        marginTop: '10px',
        boxSizing: 'border-box' as 'border-box',
        cursor: 'pointer'
    },

    programAvatar: {
        width: '40px',
        height: '40px',
    },

    programBox: {
        marginLeft: '8px',
        marginRight: '2px',
        display: 'flex',
        flexWrap: 'wrap' as 'wrap',
    },

    ProgramInnerTextHeader1: {

        color: ' #757575',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '1.2'
    },

    ProgramInnerSubTxt: {

        color: ' #757575',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '13px',
        fontWeight: 300,
        lineHeight: '1.2'
    },
    programMainTxt: {

        color: ' #757575',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontWeight: 800,
        alignText: 'left',

    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: 'Poppins, sans-serif',
        fontSize: ' 12px',
    },
    programMainSubTxt: {

        color: ' #757575',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '15px',
        fontWeight: 400,
        marginBottom: '10px',
        lineHeight: '1.2'
    },

    headerTextStyle: {
        color: " #171725",
        fontFamily: 'Poppins, sans-serif',
        fontSize: "18px",
        fontWeight: 600,
        paddingBottom: "1em",
    },
    btnRecommendation: {
        height: "52px",
        fontFamily: "'Poppins-Medium', sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        color: '#fff',
        borderRadius: "10px",
        backgroundColor: "#fab03d",
        marginBottom: '0.5em',
        marginTop: '25px'
    },

    btnQuestions: {
        width: '220px',
        height: "60px",
        fontFamily: "'Poppins-Medium', sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        color: '#fff',
        borderRadius: "10px",
        backgroundColor: "#fab03d",
        marginTop: '1em',
    },
    btnQuestionsLink: {
        fontFamily: "'Poppins-Medium', sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        color: '#fff',
        textDecoration: 'none',
    },

    boxOrientation: {
        boxSizing: 'border-box' as 'border-box',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputSelectLabelStyle: {
        marginTop: '2em',
        marginBottom: '1em',
        width: '220px',
        height: '22px',
        color: '#555555',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
    },

    inputBoxStyle: {
        marginTop: '14px',
    },

    modalTextSubTitle: {
        color: '#92929d',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        marginTop: '10px'
    },

    boxGreenHomeSchooler: {
        width: '400px',
        height: "45px",
        borderRadius: "10px",
        backgroundColor: "#8FBE40",
        marginTop: '-10px',
        zIndex: 100,
    },
    btnHomeSchoolerText: {
        fontFamily: "'Poppins-Medium', sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        color: '#fff',
    },
    btnHomeSchoolerSubText: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "12px",
        fontWeight: 400,
        color: '#fff',
    },

    circularLoader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${Backgroundimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.5,
        zIndex: 1,
        borderRadius: '15px',
    }

}

// Customizable Area End