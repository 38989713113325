import React from "react";

import {
    // Customizable Area Start
    Box,
    InputLabel,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Switch,
    TextareaAutosize

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormStep1Controller from "./FormStep1Controller";

const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiInput: {
            input: {
                color: "black",
            }
        }
    }
});

const StyledTextField = withStyles({
    root: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '16',
            fontWeight: 400,
            width: '222.98px',


        },
    }
})((props: any) => <TextField {...props} />);

export default class FormStep1 extends FormStep1Controller {

    renderError = (errorMessage: any) => {
        if (errorMessage) {
            return (
                <small data-test-id="error-element" style={webStyle.errorStyle}>{errorMessage}</small>
            );
        }

        return (
            <small style={{ visibility: 'hidden' }}>""</small>
        );
    }

    render() {
        const {
            studentFormData,
            studentFormErrors,
            countryList,
            citiesList,
            barangayList,
            religionList,
            nationalitiesList,
            languageList,
            scholarshipList,
        } = this.state;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Typography style={webStyle.heading} >Student Profile</Typography>
                <Grid container spacing={4} xs={12} style={{ padding: '2em' }}>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Student's Last Name </InputLabel>
                        <StyledTextField
                            placeholder="last name"
                            fullWidth
                            name='studentLastName'
                            value={studentFormData.studentLastName}
                            // onChange={this.handleChangePersonal}
                            onFocus={this.hideError}
                            inputProps={{ style: { textTransform: "capitalize" } }}
                        />

                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentLastName) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}> Student's First Name </InputLabel>
                        <StyledTextField
                            placeholder="first name"
                            fullWidth
                            name='studentFirstName'
                            value={studentFormData.studentFirstName}
                            // onChange={this.handleChangePersonal}
                            onFocus={this.hideError}
                            inputProps={{ style: { textTransform: "capitalize" } }}
                        />
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentFirstName) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <InputLabel style={webStyle.inputLabelStyle}>Student's Middle Name </InputLabel>
                        <StyledTextField
                            placeholder="middle name"
                            fullWidth
                            name='studentMiddleName'
                            value={studentFormData.studentMiddleName}
                            // onChange={this.handleChangePersonal}
                            onFocus={this.hideError}
                            inputProps={{ style: { textTransform: "capitalize" } }}
                        />

                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentMiddleName) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <InputLabel style={webStyle.inputLabelStyle}>Gender </InputLabel>
                        <StyledTextField
                            select
                            color="darkgrey"
                            fullWidth
                            name="studentGender"
                            InputLabelProps={{ shrink: false }}
                            value={studentFormData.studentGender}
                            // onChange={this.genderChange}
                        >
                            <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}>Choose your Gender</span></MenuItem>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>

                        </StyledTextField>
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentGender) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <InputLabel style={webStyle.inputLabelStyle}>Date of Birth</InputLabel>
                        <TextField
                            id="date"
                            type="date"
                            fullWidth
                            InputProps={{ inputProps: { max: this.dateFunction() } }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            name="studentBirthDate"
                            value={studentFormData.studentBirthDate}
                            // onChange={this.datechange}
                        />
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentBirthDate) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Country of birth</InputLabel>
                        <Autocomplete
                            value={studentFormData.studentBirthCountry?.value?.name}
                            onChange={(e: any, newValue: any) => {
                                // this.handleChangeCountry(e, newValue, 'birth')
                            }}
                            options={countryList}
                            getOptionLabel={(option: any) => option.name}
                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                            renderInput={(params: any) =>
                                < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                                    name='studentCountry' InputLabelProps={{ shrink: false }}
                            />}
                        />
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentBirthCountry) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>City of birth</InputLabel>
                        <Autocomplete
                            onChange={(e: any, newValue: any) => {
                                // this.handleChangeCity(e, newValue, 'birth')
                            }}
                            options={citiesList}
                            getOptionLabel={(option: any) => option.name}
                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                            renderInput={(params: any) =>
                                < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                                    name='studentBirth' InputLabelProps={{ shrink: false }}
                                />}
                        />
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentBirthCity) }
                        </Box>
                    </Grid>

                    {(barangayList?.length > 0 && studentFormData.studentBirthCity?.value != "") &&
                        <>
                            <Grid item xs={12} md={6}>
                                <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                                <StyledTextField
                                    select
                                    color="darkgrey"
                                    fullWidth
                                    name="barangayBirth"
                                    InputLabelProps={{ shrink: false }}
                                    value={studentFormData.studentBirthBarangay.value}
                                    // onChange={(e: any) => this.barangayChange(e, 'birth')}
                                >
                                    <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                    {

                                        barangayList?.map((item: any) => {
                                            return (

                                                <MenuItem
                                                    key={item?.value}
                                                    value={item?.label}
                                                >
                                                    {item?.label}
                                                </MenuItem>
                                            )

                                        })

                                    }
                                </StyledTextField>
                                <Box style={webStyle.FormAlignment}>
                                    { this.renderError(studentFormErrors.studentBirthBarangay) }
                                </Box>
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Age</InputLabel>
                        <StyledTextField
                            color="darkgrey"
                            fullWidth
                            name="studentAge"
                            disabled
                            InputLabelProps={{ shrink: false }}
                            value={studentFormData.studentAge}
                        >

                        </StyledTextField>
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentAge) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <InputLabel style={webStyle.inputLabelStyle}>Religion</InputLabel>
                        <StyledTextField
                            select
                            color="darkgrey"
                            fullWidth
                            name="studentReligion"
                            InputLabelProps={{ shrink: false }}
                            // value={studentFormData.studentReligion.value}
                            // onChange={(e: any) => this.religionDataChange(e)}
                        >
                            <MenuItem style={webStyle.StudentMenuItems} value="choose"
                                data-test-id='religion-list'> <span style={{ color: "darkgrey" }}> please select </span></MenuItem>
                            {
                                religionList?.map((item: any, index: number) => (
                                    <MenuItem
                                        key={item?.value}
                                        value={item?.value}
                                        // selected={item.value == studentFormData.studentReligion.value}
                                    >
                                        {item?.label}
                                    </MenuItem>
                                ))
                            }
                        </StyledTextField>
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentReligion) }
                        </Box>
                    </Grid>
                    {studentFormData.studentReligion.value == "Other" &&
                        <Grid item xs={12} md={6}>

                            <InputLabel style={webStyle.inputLabelStyle}>Enter Religion *</InputLabel>
                            <StyledTextField
                                placeholder="Religion "
                                fullWidth
                                name='studentReligionOthers'
                                value={studentFormData.studentReligion}
                                // onChange={this.handleChangePersonal}
                                onFocus={this.hideError}
                            />
                            <Box style={webStyle.FormAlignment}>
                                { this.renderError(studentFormErrors.studentReligion) }
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Nationality</InputLabel>
                        <StyledTextField
                            select
                            color="darkgrey"
                            fullWidth
                            name="studentNationality"
                            InputLabelProps={{ shrink: false }}
                            value={studentFormData.studentNationality.value}
                            // onChange={(e: any) => this.nationalitiesDataChange(e)}
                        >
                            <MenuItem style={webStyle.StudentMenuItems} value="choose" data-test-id='nationality-list'> <span style={{ color: "darkgrey" }}> please select </span></MenuItem>
                            {
                                nationalitiesList?.map((item: any) => {
                                    return (

                                        <MenuItem
                                            key={item?.value}
                                            value={item?.value}
                                            // selected={item.value == studentFormData.studentNationality.value}
                                        >
                                            {item?.label}
                                        </MenuItem>
                                    )
                                })
                            }
                        </StyledTextField>
                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentNationality) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Country of Residence</InputLabel>
                        <Autocomplete
                            value={studentFormData.studentResidenceCountry?.value?.name}
                            onChange={(e: any, newValue: any) => {
                                // this.handleChangeCountry(e, newValue, 'res')
                            }}
                            options={countryList}
                            getOptionLabel={(option: any) => option.name}
                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                            renderInput={(params: any) =>
                                < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
                                    name='studentCountryOfResidence' InputLabelProps={{ shrink: false }}
                                />}
                        />

                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentResidenceCountry) }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>City of Residence</InputLabel>
                        <Autocomplete
                            // onChange={(e: any, newValue: any) => {
                                // this.handleChangeCity(e, newValue, 'res')
                            // }}
                            data-test-id="autocom-res"
                            options={citiesList}
                            getOptionLabel={(option: any) => option.name}
                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                            renderInput={(params: any) =>
                                <StyledTextField data-test-id="autocom-res-stf" {...params} placeholder='Please Select' fullWidth={true}
                                    name='studentCityOfResidence' InputLabelProps={{ shrink: false }}
                                />}
                        />

                        <Box style={webStyle.FormAlignment}>
                            { this.renderError(studentFormErrors.studentResidenceCity) }
                        </Box>
                    </Grid>
                    {(barangayList?.length > 0 && studentFormData.studentResidenceCity.value != "") &&
                        <>
                            <Grid item xs={12} md={6}>

                                <InputLabel style={webStyle.inputLabelStyle}>Barangay</InputLabel>
                                <StyledTextField
                                    select
                                    color="darkgrey"
                                    fullWidth
                                    name="barangaySubData"
                                    InputLabelProps={{ shrink: false }}
                                    value={studentFormData.studentResidenceBarangay.value}
                                    // onChange={(e: any) => this.barangayChange(e, 'res')}
                                >
                                    <MenuItem style={webStyle.StudentMenuItems} value="choose" data-test-id={"dropdown-res-brgy"}> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                    {
                                        barangayList?.map((item: any) => {
                                            return (
                                                <MenuItem
                                                    key={item?.value}
                                                    value={item?.label}
                                                >
                                                    {item?.label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </StyledTextField>
                                <Box style={webStyle.FormAlignment}>
                                    {this.renderError(studentFormErrors.studentResidenceBarangay)}
                                </Box>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.inputLabelStyle}>Child's First Language *</InputLabel>
                        <StyledTextField
                            select
                            color="darkgrey"
                            fullWidth
                            name="studentChildsFirstLanguage"
                            InputLabelProps={{ shrink: false }}
                            value={studentFormData.studentChildsFirstLanguage.value}
                            // onChange={(e: any) => this.languageDataChange(e)}
                        >
                            <MenuItem style={webStyle.StudentMenuItems} value="choose" data-test-id={"dropdown-languages"}> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                            {
                                languageList?.map((item: any) => {
                                    return (
                                        <MenuItem
                                            key={item?.value}
                                            value={item?.value}
                                        // selected={item.value == studentFormData.studentChildsFirstLanguage.value}
                                        >
                                            {item?.label}
                                        </MenuItem>
                                    )
                                })
                            }
                        </StyledTextField>
                        <Box style={webStyle.FormAlignment}>
                            {this.renderError(studentFormErrors.studentChildsFirstLanguage)}
                        </Box>
                    </Grid>
                    {studentFormData.studentChildsFirstLanguage.value == "Others" &&
                        <Grid item xs={12} md={6}>

                            <InputLabel style={webStyle.inputLabelStyle}> Enter Child's First Language * </InputLabel>
                            <StyledTextField
                                placeholder="First Language"
                                fullWidth
                                name='studentChildsFirstLanguage'
                                value={studentFormData.studentChildsFirstLanguage}
                                // onChange={this.handleChangePersonal}
                                onFocus={this.hideError}
                            />
                            <Box style={webStyle.FormAlignment}>
                                {this.renderError(studentFormErrors.studentChildsFirstLanguage)}
                            </Box>
                        </Grid>
                    }
                </Grid>
                <Grid container style={{ paddingLeft: '3.1em', paddingRight: '2em' }} spacing={4}  >
                    <Grid item xs={12} md={6} style={{ paddingLeft: '0', marginRight: '-0.5em' }}>

                        <InputLabel style={webStyle.WouldYouLikeToApplyForaScholarship}>Would you like to apply for a Scholarship? * </InputLabel>
                        <Switch
                            name="studentWouldYouLikeToApplyForAScholarShip"
                            checked={studentFormData.studentWouldYouLikeToApplyForAScholarShip}
                            onChange={this.handleCheckedStudentScholarShip}
                            inputProps={{ 'aria-label': 'controlled' }}
                            data-test-id='scholarshipCheck'
                        />
                    </Grid>

                    {studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
                        <Grid item xs={12} md={6} style={{ paddingRight: '3em', paddingLeft: "0em" }}>

                            <InputLabel style={webStyle.inputLabelStyle}>Scholarship Type*</InputLabel>
                            <StyledTextField
                                select
                                color="darkgrey"
                                fullWidth
                                name="studentScholarShopType"
                                InputLabelProps={{ shrink: false }}
                                value={studentFormData.studentScholarShipType}
                                // onChange={this.handleChangeScholarshipType}
                            >
                                <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                <MenuItem value="Individual">Individual</MenuItem>
                                <MenuItem value="Family">Family</MenuItem>
                            </StyledTextField>
                            <Box style={webStyle.FormAlignment}>
                                {this.renderError(studentFormErrors.studentScholarShipType)}
                            </Box>
                        </Grid>}
                </Grid>
                {studentFormData.studentWouldYouLikeToApplyForAScholarShip &&
                    <Grid container spacing={1} style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                        <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingBottom: '0', paddingLeft: '0', paddingRight: '3em' }}>

                            <InputLabel style={webStyle.inputLabelStyle}>Types of Scholarship *</InputLabel>
                            <StyledTextField
                                select
                                color="darkgrey"
                                fullWidth
                                helperText={<span style={webStyle.scholarshipChange}>Please update the required documents in the file upload section to be followed</span>}
                                name="studentTypesOfScholarShip"
                                InputLabelProps={{ shrink: false }}

                                value={studentFormData.studentTypesOfScholarShip.value}
                                // onChange={(e: any) => this.scholarshipChange(e)}

                            >
                                <MenuItem style={webStyle.StudentMenuItems} value="choose" data-test-id='scholarship-List'> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                {

                                    scholarshipList?.map((item: any) => {
                                        return (
                                            <MenuItem
                                                key={item?.value}
                                                value={item?.value}
                                            >
                                                {item?.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </StyledTextField>

                            <Box style={webStyle.FormAlignment}>
                                { this.renderError(studentFormErrors.studentTypesOfScholarShip) }
                            </Box>
                        </Grid>
                        {(studentFormData.studentTypesOfScholarShip == "" || studentFormData.studentTypesOfScholarShip.value == "HG Staff Student Scholarship") && (
                            <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingLeft: '0', paddingRight: '2.5em' }}>
                                <InputLabel style={webStyle.inputLabelStyle}>Proof of relationship *</InputLabel>
                                <StyledTextField
                                    select
                                    color="darkgrey"
                                    fullWidth
                                    name="studentProofOfRelationship"
                                    InputLabelProps={{ shrink: false }}
                                    value={studentFormData.studentProofOfRelationship}
                                    // onChange={this.handleProofOfRelationship}
                                >
                                    <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                    <MenuItem value="Child">Child</MenuItem>
                                    <MenuItem value="Sister">Sister</MenuItem>
                                    <MenuItem value="Brother">Brother</MenuItem>
                                    <MenuItem value="Nephew">Nephew</MenuItem>
                                    <MenuItem value="Niece">Niece</MenuItem>
                                </StyledTextField>

                                <Box style={webStyle.FormAlignment}>
                                    {this.renderError(studentFormErrors.studentProofOfRelationship)}
                                </Box>
                            </Grid>
                        )}

                        {(studentFormData.studentTypesOfScholarShip == "" || studentFormData.studentTypesOfScholarShip.value == "The Jovi Villote Memorial Scholarship") && (
                            <>
                                <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingRight: '2em', marginLeft: '-0.3em' }}>

                                    <InputLabel style={webStyle.inputLabelStyle}>Date of father's death *</InputLabel>
                                    <TextField
                                        id="date"
                                        type="date"
                                        fullWidth
                                        defaultValue={<span>yy-mm-dd</span>}
                                        InputProps={{ inputProps: { max: this.dateFunction() } }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="studentFatherDeath"
                                        value={studentFormData.studentFatherDeathDate}
                                        // onChange={this.handleClearFatherDeath}
                                    />

                                    <Box style={webStyle.FormAlignment}>
                                        {this.renderError(studentFormErrors.studentFatherDeathDate)}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} style={{ paddingTop: '0em', paddingBottom: '0', paddingLeft: '0', paddingRight: '3em' }} >

                                    <InputLabel style={webStyle.inputLabelStyle}>Cause of the death *</InputLabel>
                                    <StyledTextField
                                        select
                                        color="darkgrey"
                                        fullWidth
                                        name="studentCauseOfDeath"
                                        InputLabelProps={{ shrink: false }}
                                        value={studentFormData.studentFatherCauseOfDeath}
                                        // onChange={this.handleStudentCauseOfDeath}
                                    >
                                        <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Enter Cause </span></MenuItem>
                                        <MenuItem value="Illness">Illness</MenuItem>
                                        <MenuItem value="Accident">Accident</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </StyledTextField>

                                    <Box style={webStyle.FormAlignment}>
                                        {this.renderError(studentFormErrors.studentFatherCauseOfDeath)}
                                    </Box>
                                </Grid>
                            </>
                        )}

                        {(studentFormData.studentTypesOfScholarShip.value == "Scholarship for HG Servant Leaders") && (
                            <Grid item xs={12} md={6} style={{ paddingTop: '1em', paddingLeft: '0', paddingRight: '2.5em' }} >
                                <InputLabel style={webStyle.inputLabelStyle}> specification *</InputLabel>
                                <StyledTextField
                                    select
                                    color="darkgrey"
                                    fullWidth
                                    name="studentSpecification"
                                    InputLabelProps={{ shrink: false }}
                                    value={studentFormData.studentSpecification}
                                    // onChange={this.handlestudentSpecification}
                                >
                                    <MenuItem style={webStyle.StudentMenuItems} value="choose"> <span style={{ color: "darkgrey" }}> Please Select </span></MenuItem>
                                    <MenuItem value="HighUniteRevolutionariesCoreTeam">High Unite Revolutionaries Core Team</MenuItem>
                                    <MenuItem value="HGndFAfamilies">HG and FA families</MenuItem>
                                    <MenuItem value="HGStaffs"> HG Staffs</MenuItem>

                                </StyledTextField>

                                <Box style={webStyle.FormAlignment}>
                                    {this.renderError(studentFormErrors.studentSpecification)}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                }
                <Grid container spacing={4} style={{ paddingTop: '2em', paddingLeft: "2em", paddingRight: '2em' }}>
                    <Grid item xs={12} md={6}>
                        <InputLabel style={webStyle.studentRefferalProgram}>Do you have an Advocate code (Referral Program)? *</InputLabel>
                        <Switch
                            name="studentRefferalProgram"
                            checked={studentFormData.studentRefferalProgram}
                            // onChange={this.handleAdvocatecode}
                            inputProps={{ 'aria-label': 'controlled' }}
                            data-test-id="advocateId"
                        />
                    </Grid>
                    {studentFormData.studentRefferalProgram &&
                        <Grid item xs={12} md={6} style={{ paddingRight: '4em' }}>

                            <InputLabel style={webStyle.inputLabelStyle}>Advocate code (Referral Program)*</InputLabel>
                            <StyledTextField
                                placeholder="Enter code"
                                fullWidth
                                name="studentAdvocatecode"
                                value={studentFormData.studentAdvocatecode}
                                // onChange={this.handleChangePersonal}
                                onFocus={this.hideError}
                            />
                            <Box style={webStyle.FormAlignment}>
                                { this.renderError(studentFormErrors.studentAdvocatecode) }
                            </Box>
                        </Grid>}
                </Grid>
                <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: "2em", paddingRight: '2em' }}>
                    <Grid item xs={12} md={6} style={{ paddingRight: '3em' }}>
                        <InputLabel style={webStyle.studentRefferalProgram}>Applying for a PWD discount?</InputLabel>
                        <Switch
                            name="studentPwd"
                            checked={studentFormData.studentPwd}
                            // onChange={this.handlePwdDiscount}
                            inputProps={{ 'aria-label': 'controlled' }}
                            data-test-id="pwdId"
                        />
                    </Grid>
                    {studentFormData.studentPwd &&
                        <Grid item xs={12} md={6} style={{ paddingRight: '4em' }} >

                            <InputLabel style={webStyle.inputLabelStyle}>PWD ID Number *</InputLabel>
                            <StyledTextField
                                placeholder="Enter ID Number"
                                fullWidth
                                name="studentPwdIdNumber"
                                value={studentFormData.studentPwdIdNumber}
                                // onChange={this.handleChangePersonal}
                                onFocus={this.hideError}
                            />
                            <Box style={webStyle.FormAlignment}>
                                { this.renderError(studentFormErrors.studentPwdIdNumber) }
                            </Box>
                        </Grid>}
                </Grid>
                <Grid container spacing={4} style={{ paddingTop: '1.5em', paddingLeft: "2em", paddingRight: '2em' }}>
                    <Grid item xs={12} md={6} >

                        <InputLabel style={webStyle.studentRefferalProgram}>Applying for a solo parent discount?</InputLabel>
                        <Switch
                            name="studentParentDiscount"
                            checked={studentFormData.studentParentDiscount}
                            // onChange={this.handleParentDiscount}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Grid>
                    {studentFormData.studentParentDiscount &&
                        <Grid item xs={12} md={6} style={{ paddingRight: '4em' }}>

                            <InputLabel style={webStyle.inputLabelStyle}>Solo Parent ID Number *</InputLabel>
                            <StyledTextField
                                placeholder="Enter Number"
                                fullWidth
                                name="studentSoloParentId"
                                value={studentFormData.studentSoloParentId}
                                // onChange={this.handleChangePersonal}
                                onFocus={this.hideError}
                            />
                            <Box style={webStyle.FormAlignment}>
                                { this.renderError(studentFormErrors.studentSoloParentId) }
                            </Box>
                        </Grid>}
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginTop: '1.5em', paddingLeft: "2em", paddingRight: "4em" }}>

                    <InputLabel style={webStyle.inputLabelStyle}>Residential Addresss</InputLabel>
                    <TextareaAutosize
                        placeholder="Unit / Bldg No. , Bldg Name / Lot No. , Street Name"
                        aria-label="minimum height" minRows={3}
                        style={webStyle.inputAdress}
                        name="studentResidentialAddress"
                        value={studentFormData.studentResidentialAddress}
                        // onChange={this.handleChangePersonal}
                        onFocus={this.hideError}
                    ></TextareaAutosize>
                    <Box style={{ marginTop: '5px' }}>
                        { this.renderError(studentFormErrors.studentResidentialAddress) }
                    </Box>
                </Grid>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    StudentMenuItems: {
        display: "none", color: 'darkgray'
    },
    inputAdress: {
        paddingLeft: '20px',
        height: "91px",
        width: '100%',
        background: "#f9f9f9",
        fontFamily: 'Poppins, sans-serif',
        backgroundBlendMode: "normal",
        outline: "none", border: "none"
    },
    studentRefferalProgram: {
        width: "290px", fontWeight: 400, height: "22px",
        color: "#757575",
           fontFamily: "Poppins, sans-serif",
        fontSize: "12px",
        lineHeight: "22px"
    },
    scholarshipChange: {
        top: " 66px",
        height: "22px",
        color: " #757575",
           fontFamily: "Poppins, sans-serif",
        fontSize: "11px",
        fontStyle: "italic",
        fontWeight: 400,
        lineHeight: "22px"
    },
    WouldYouLikeToApplyForaScholarship: {
        fontWeight: 400, height: "22px",
        color: "#757575",
           fontFamily: "Poppins, sans-serif",
        fontSize: "12px",
        width: "247px"
    },
    FormAlignment: { marginTop: '3px' },
    heading: {
        left: "53px",
        top: "57px",
        width: " 344px",
        height: " 30px",
        color: " #171725",
        fontFamily: "Poppins, sans-serif",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "30px",
        letterSpacing: "0.08333328px",
        marginTop: "3em",
        marginBottom: "1em",
        paddingLeft: '1.4em'

    },
    inputLabelStyle: {
        marginBottom: '10px',
        height: '22px',
        color: "#757575",
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
    },
    errorStyle: {
        padding: '5px',
        color: 'red',
        fontFamily: 'Poppins, sans-serif',
        fontSize: ' 12px',
    }
};
// Customizable Area End