import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData } from "../../../framework/src/Utilities";

const worldMapData = require('city-state-country');



export interface ICountryData {
  id: number;
  sortname: string,
  name: string,
  phoneCode: number
}
interface IReasonData {
  id: string,
  type: string,
  attributes: {
    reason: string,
  }
}
interface IBarangayDataOne {
  id: string,
  type: string,
  attributes: {
    barangay_name: string,
  }
}
export interface IDItem {
  label: string,
  value: string,
}
export interface ICityData {
  id: string,
  name: string,
  country_id: string
}
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  reasonListOne: IDItem[]
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  citiesListOne: IDItem[];
  countryListOne: IDItem[];
  countryPhoneCodeListOne: IDItem[];
  barangayListOne: IDItem[];
  profileId: string;
  barangayPlaceholder:string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  saveButtonDisable: boolean;
  enableField: boolean;
  open: boolean;
  updateOpen: boolean;
  AccountAboutUs: string;
  barangayAccountValue: string;
  byrangayDataAccount: string;
  
  getAllCities: any[];
  cityAccountAutoValue: string;
  countryAccountAutoValue: string;
  getAllCountries: any[];
  snackbarOpen: boolean;
  snackbarOpenError: boolean;
  AccountHomeSchoolingValue: string;
  errors: any;
  confirmNewPassword: any;
  AccountFirstName: any;
  AccountLastName: any;
  AccountCountryCode: any;
  AccountMobileNumber: any;
  AccountEmail: any;
 
  provincesData: any;
  passwordInputState: any;
  passwordConfirmState: boolean
  passwordState: boolean;
  passwordConfirmInputState: any;
  passwordCreateState: boolean;
  passwordCreateInputState: any;
  AccountHomeSchoolingId: string;
  barangayAccountId: string;
  accountStateFormData: any;
  changePasswordAccountState: any;
  accountCountryAutoHandlerCode: any
  reasonForHomeSchoolingData: any;
  snackbarMessage: any;
  isloadingPassword: boolean;
  uniqueCountryCodeData: any;
  gettingDetailsOfStudent: boolean;
  errorsObj: any
  uniqueCountryCodeDataNew: any;
  country: any;
  state: any;
  cities: any;
  residenceCountries: string,
  residenceCities: string,
  residenceBarangayIId: string,
  residenceBarangay1: string,
  getStatesOfCountry: any,
  homeSchoolingReasonOne: string,
  logoutModal: boolean
  residenceBarangayacc : string


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  GetAccountSettingDatadataId: string = "";
  GetAccountSettingPutId: string = "";
  GetAccountSettingUpdateId: string = "";
  intervalId: string = "";
  apiProvincesCallId: string = "";
  labelEmail: string = "";
  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";
  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getStatesOfCountry: string = ""
  passwordChangeApi: string = ''
  countries: any;
  createAccountApiCallId = "";
  getReasonCallId = "";
  getBarangayCallId = "";
  getProvincesCallId = "";
  

  // countries: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      barangayPlaceholder: configJSON.dropdownPlaceHolderOne,
      homeSchoolingReasonOne: "",
      reasonListOne:[],
      citiesListOne: [],
      countryListOne: [],
      countryPhoneCodeListOne: [],
      barangayListOne: [],
      residenceCountries: "",
      residenceCities: "",
      residenceBarangayIId: "",
      accountCountryAutoHandlerCode: "",
      getStatesOfCountry: "",
      reasonForHomeSchoolingData: [],
      AccountFirstName: "",
      AccountEmail: "",
    
      AccountCountryCode: "",
      AccountMobileNumber: "",
      AccountLastName: "",
      profile: {},
      profileImageUrl: "",
      fields: [],
      snackbarMessage: "",


      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      uniqueCountryCodeData: [],
      isloadingPassword: false,
      gettingDetailsOfStudent: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      open: false,
      AccountAboutUs: "",
      updateOpen: false,
      barangayAccountValue: "",
      byrangayDataAccount: "",
    
      getAllCities: [],
      getAllCountries: [],
      countryAccountAutoValue: "",
      cityAccountAutoValue: "",
      snackbarOpen: false,
      snackbarOpenError: false,
      AccountHomeSchoolingValue: "",
      errors: {},
      confirmNewPassword: "",
      provincesData: [],
      passwordInputState: "",
      passwordConfirmState: false,
      passwordState: false,
      passwordConfirmInputState: "",
      passwordCreateState: false,

      passwordCreateInputState:"",
      AccountHomeSchoolingId: "",
      barangayAccountId: "",
      accountStateFormData: [],
      residenceBarangay1: "",
      residenceBarangayacc: "",
      errorsObj: "",
      changePasswordAccountState: [],
      uniqueCountryCodeDataNew: [],
      country: null,
      state: null,
      cities: [],
      logoutModal: false


      // Customizable Area End
    };
    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getSchoolingReason()
    this.getCountriesListOne()
    this.accountSettinGetDataApiFunc()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId !== null) {
        switch (true) {
          case apiRequestCallId === this.getReasonCallId:
            this.onProcessReasonResponseOne(message);
            break;
          case apiRequestCallId === this.GetAccountSettingDatadataId:
            this.setAccountSettingsFormApi(responseJson, errorReponse);
            break;

          case apiRequestCallId === this.GetAccountSettingPutId:
            this.setChangePasswordAccountsettingFormApi(responseJson, errorReponse);
            break;
          case apiRequestCallId === this.GetAccountSettingUpdateId:
            this.setUpdateFormApi(responseJson, errorReponse);
            break;
          case apiRequestCallId === this.getBarangayCallId:
            this.onProcessGetBarangayResponseOne(message);
            break;

          case apiRequestCallId === this.getProvincesCallId:
            this.onProcessGetProviceResponseOne(message);
            break;
          default:
            break;
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start


  
  accountSettinGetDataApiFunc = () => {
   
    const header = {
      'token': localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAccountSettingDatadataId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  putAccountSettinGetDataApiFunc = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      "token": localStorage.getItem('authToken')
    };
 
    const httpBody = {
      data: {
        current_password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmNewPassword
      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAccountSettingPutId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountChangePasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };



  updateUserAccountSettinGetDataApiFunc = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      "token": localStorage.getItem('authToken')
    };
    const httpBody = {
      data: {
        first_name: this.state.AccountFirstName,
        last_name: this.state.AccountLastName,
        country_code: this.state.AccountCountryCode,
        phone_number: this.state.AccountMobileNumber,
        country: this.state.residenceCountries,
        city: this.state.residenceCities,
        home_schooling_reason_id: this.state.homeSchoolingReasonOne,
        informed_about_us: this.state.AccountAboutUs,
        barangay_id : this.state.residenceBarangayacc,
      }

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAccountSettingUpdateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),

      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };


  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {

    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleClose1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenError: false })
  };


  changeOpen = () => {

    this.setState({ open: true })
  }
  handleModelClose = () => {
    this.setState({ open: false })
  }
  handleCloseAccount = () => {
    this.setState({ updateOpen: false })
  }
  onBarangaySelected = (e:any ) => {
    this.setState({
      residenceBarangayacc: e?.target.value,
      residenceBarangay1: e?.target.value
      
    })
  }


  accountCountryAutoHandler = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    this.setState({ countryAccountAutoValue: newValue })
  }

  
  setAccountSettingsFormApi = (responseJson: any, errorReponse: any) => {

    if (responseJson?.data) {
      this.setState({
      
        AccountFirstName: responseJson?.data?.attributes?.first_name,
        AccountLastName: responseJson?.data?.attributes?.last_name,
        AccountMobileNumber: responseJson?.data?.attributes?.phone_number,
        AccountCountryCode: responseJson?.data?.attributes?.country_code,
        AccountEmail: responseJson?.data?.attributes?.email,
        residenceCountries: responseJson?.data?.attributes?.country,
        residenceCities: responseJson?.data?.attributes?.city,
        residenceBarangayacc: responseJson?.data?.attributes?.barangay_id ,
        homeSchoolingReasonOne : responseJson?.data?.attributes?.inquiry_details?.home_schooling_reason_id,
      },()=>{
        this.funxW(responseJson?.data?.attributes?.inquiry_details?.home_schooling_reason, responseJson?.data?.attributes?.inquiry_details.informed_about_us)
        this.getCityListOne(this.state.residenceCountries) 
        this.onGetBarangayListOne(this.state.residenceCities) 
        this.setState({gettingDetailsOfStudent: false})
      })
    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);

    
    }

  }
  funxW=(homeSchoolingReason:any, informed_about_us:any)=>{
    const reasonHome:any = this.state.reasonListOne.find((item:any) => {
      return item && item.label == homeSchoolingReason;
    });
    this.setState(
      {
        homeSchoolingReasonOne: reasonHome ? reasonHome.value : null,
        AccountAboutUs: informed_about_us,
      }
    );
      
  }
  setChangePasswordAccountsettingFormApi = (responseJson: any, errorReponse: any) => {
  
    if (responseJson?.data) {
       this.setState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        errors: {},
      })

    
      this.setState({ snackbarOpen: true, snackbarMessage: "password changed successfully", logoutModal: true, updateOpen: false });
    }
    else {
      if (responseJson?.errors[0]?.password)
      this.setState({ snackbarOpenError: true, snackbarMessage: responseJson.errors[0].password });
      else {
        this.setState({ snackbarOpenError: true, snackbarMessage: "password should be same" });
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  setUpdateFormApi = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data) {
       this.setState({
        AccountFirstName: '',
        AccountLastName: '',
       
        AccountMobileNumber: "",
        residenceCountries: "",
        residenceCities: "",
      
      

        errors: {},
        
      })
      this.setState({ updateOpen: true })
      this.accountSettinGetDataApiFunc()
      this.getCountriesListOne()
      this.getSchoolingReason()
      
    }
    else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  updatePasscode = () => {

    if (this.changePasswordValidation()) {
      this.putAccountSettinGetDataApiFunc()
   
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }

  }


  changePasswordValidation = () => {
    let isFormValid = true;
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\/{}\\[?:";'|<>,.~@#$%^&*()_+])[A-Za-z\d!\/{}\\[?:";'|<>,.~@#$%^&*()_+]{8,}$/


    if (this.state.currentPassword === '') {
      this.state.errors['currentPassword'] = "Password Required"
      isFormValid = false
    } else if (!passwordRegx.test(this.state.currentPassword)) {
      this.state.errors['currentPassword'] = "Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
      isFormValid = false
    }
   
    if (this.state.newPassword === '') {
      this.state.errors['newPassword'] = "Password Required"

      isFormValid = false
    } else if (!passwordRegx.test(this.state.newPassword)) {
      this.state.errors['newPassword'] = "Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
      isFormValid = false
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        errors: {
          ...this.state.errors,
          confirmNewPassword: "",
        },
      });
    }

    if (this.state.confirmNewPassword === '') {
    
      this.state.errors['confirmNewPassword'] = "Password is Required"
      isFormValid = false
    }
    return isFormValid

  }

  changeHandlerGetCitiesAccount = async (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.errors['residenceCountries'] = " "
    this.setState({ residenceCities: '' })
    this.setState({ residenceBarangay1: ""})
    this.setState({ barangayAccountValue: 'choose', barangayAccountId: '' })
  }

  hideError = (event: any) => {

    this.state.errors[event.target.name] = '';
    this.setState({
      errors: this.state.errors
    })
  }
  changePasswordState = () => {
    const containPasswordOne ='password'
    const containTextOne = 'text'
    if (this.state.passwordState) {
      this.setState({ passwordInputState: containPasswordOne })
      this.setState({ passwordState: false })
     
    }
    else {
      this.setState({ passwordState: true })
      this.setState({ passwordInputState: containTextOne })
    }
  }

  changePasswordCreateState = () => {
    const containPassword ='password'
    const containText = 'text'
    if (this.state.passwordCreateState) {
      this.setState({ passwordCreateState: false })
      this.setState({ passwordCreateInputState: containPassword })
    }
    else {
      this.setState({ passwordCreateState: true })
      this.setState({ passwordCreateInputState:containText  })
    }
  }
  changePasswordConfirmState = () => {
    const containPasswordTwo ='password'
    const containTextTwo = 'text'
    if (this.state.passwordConfirmState) {
      this.setState({ passwordConfirmState: false })
      this.setState({ passwordConfirmInputState: containPasswordTwo })
    }
    else {
      this.setState({ passwordConfirmState: true })
      this.setState({ passwordConfirmInputState: containTextTwo })
    }
  }

  onHomeSchoolingReasonSelected = (e:any) => {
    this.setState(state => {
      return {
        homeSchoolingReasonOne: e.target.value,
        
        errors: {
          ...state.errors,
          "homeSchoolingReasonOne": ""
        }
      }
    })
  }

onLearnAboutUsSelectedOne = (item: {label: string, value: string}) => {
  this.setState(state => {
    return {
      AccountAboutUs: item.value,
      errors: {
        ...state.errors,
        "AccountAboutUs": ""
      }
    }
  })
}
 
  accountSettingEdit = () => {
   
    let isFormValid = true;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regexPhone = /^\d{6,11}$/;
    const regexName = /^[a-zA-Z.][a-zA-Z. ]{1,25}$/;

    if (this.state.AccountFirstName === '') {
      
   
      this.state.errors['AccountFirstName'] = "First Name Required"
      isFormValid = false
    }
    
    else if (!regexName.test(this.state.AccountFirstName)) {
      
      this.state.errors['AccountFirstName'] = "First name must contain only letters and be fewer than 25 characters"
      isFormValid = false
    }
    if (this.state.AccountLastName === '') {
    
      this.state.errors['AccountLastName'] = "Last Name Required"
      isFormValid = false
    }
    
    else if (!regexName.test(this.state.AccountLastName)) {
      this.state.errors['AccountLastName'] = "Last name must contain only letters and be fewer than 25 characters"
      isFormValid = false
    }
    if (this.state.AccountCountryCode === '') {
   
      this.state.errors['AccountCountryCode'] = "Code Required"
      isFormValid = false
    }
    if (this.state.AccountMobileNumber === '') {
      this.state.errors['AccountMobileNumber'] = "Phone Required"
      isFormValid = false
    }
    else if (!regexPhone.test(this.state.AccountMobileNumber)) {
      this.state.errors['AccountMobileNumber'] = "Min 6 to Max 11 digits only"
      isFormValid = false
    }

    if (this.state.AccountEmail === '') {
      this.state.errors['AccountEmail'] = "Email Required"
      isFormValid = false
    }
    else if (!regex.test(this.state.AccountEmail)) {

      isFormValid = false;
      this.state.errors['AccountEmail'] = "Incorrect Email"
    }
    if (this.state.residenceCountries.length <= 0 ) {
      this.state.errors['residenceCountries'] = "Country Required"
      isFormValid = false
    }
    if (this.state.residenceCities === '') {
      
      this.state.errors['residenceCities'] = "City Required"
      isFormValid = false
    }
   

  if (this.state.AccountAboutUs === '') {
   
    this.state.errors['AccountAboutUs'] = "Option Required"
    isFormValid = false
  }
  if (this.state.homeSchoolingReasonOne === '') {
    this.state.errors['homeSchoolingReasonOne'] ="Reason Required"
    isFormValid = false
  }

  
   
  
    return isFormValid
  }

  onSubmitAccountSettingOne = () => {
   
    if (this.accountSettingEdit()) {
     
       this.updateUserAccountSettinGetDataApiFunc()
    }
    else {
      this.setState({
        errors: this.state.errors
      })
    }
  }
  onGetPhillipinesProvincesOne = () => {
    this.callApi(configJSON.apiProvincesCallUrl, configJSON.provinceApiMethodOne)
  }
  getSchoolingReason = () => {
    this.callApi(configJSON.homeSchoolingReasonsEndPoints, configJSON.homeSchoolingReasonsMethods)
  }


  getCountriesListOne = () => {
    const countriesResult: ICountryData[] = worldMapData.getAllCountries()
    const countriesListItem: IDItem[] = countriesResult.map(item => {
    
      return {
        label: item.name,
        value: item.name
      }
    })
    const sorted = [...countriesResult].sort(this.sortPhoneCode)
    const phoneCodelistItem: IDItem[] = sorted.map((item) => {
      return {
        label: item.sortname,
        value: item.phoneCode.toString(),
      }
    })
   
    this.setState({
      countryListOne: countriesListItem,
      countryPhoneCodeListOne: phoneCodelistItem,
      barangayListOne: [],
    })
  }
 
  onCountryCodeSelectedWeb = (item: string) => {
  
    this.setState({
      AccountCountryCode: `${item}`,
    })
  }

  onGetBarangayListOne = (city: string) => {
    this.callApi(`${configJSON.barangayApiEndpointOne}?city=${city}`, configJSON.barangayApiMethodOne)
  }

  onProcessGetBarangayResponseOne = (message: Message) => {
    const barangayResponseJson = message?.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const barangayErrorReponse = message?.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(barangayErrorReponse){
      this.parseApiCatchErrorResponse(barangayErrorReponse);
      return;
    }
    if (barangayResponseJson) {
      const listData: IBarangayDataOne[] = barangayResponseJson.data
      if(listData){
        const converted: IDItem[] = listData.map(item => {
          return {
            label: item.attributes.barangay_name,
            value: item.id
          }
        })
        this.setState({
          barangayListOne: converted
        })
      } else {
        this.setState({
          barangayListOne: [],
          barangayPlaceholder: "No barangay"
        })
      }
    }
  }
  sortPhoneCode = (itemA: ICountryData, itemB: ICountryData) => {
    return itemA.phoneCode - itemB.phoneCode
  }

  getCityListOne = (countryName: string) => {
    if (countryName === "Philippines") {
      this.onGetPhillipinesProvincesOne()
    } else {
      const cities: ICityData[] = worldMapData.getAllStatesFromCountry(countryName)
      const citiesListOne: IDItem[] = cities.map(item => {
        return {
          label: item.name,
          value: item.name
        }
      })
      this.setState({
        citiesListOne: citiesListOne
      })
    }
  }

  onChangePhoneNumberOne = (input: string) => {
    this.setState(state => {
      return {
        AccountMobileNumber: input,
        errors: {
          ...state.errors,
          "AccountMobileNumber": ""
        }
      }
    })
  }



  onProcessReasonResponseOne = async(message: Message) => {
    const reasonResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const reasonErrorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(reasonErrorReponse){
      this.parseApiCatchErrorResponse(reasonErrorReponse);
      return;
    }
    if (reasonResponseJson) {
      const reasonListOne: IReasonData[] = reasonResponseJson.data
      const converted: IDItem[] = reasonListOne.map(item => {
        return {
          label: item.attributes.reason,
          value: item.id
        }
      })
   
      this.setState({
        reasonListOne: converted,
     
        
      })
    }
  }



  onProcessGetProviceResponseOne = (message: Message) => {
    const provinceResponseJson = message?.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const barangayErrorReponse = message?.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(barangayErrorReponse){
      this.parseApiCatchErrorResponse(barangayErrorReponse);
      return;
    }
    if (provinceResponseJson) {
      const listData: string[] = provinceResponseJson.data
      if(listData){
        const converted: IDItem[] = listData.map(item => {
          return {
            label: item,
            value: item
          }
        })
        this.setState({
          citiesListOne: converted
        })
      } else {
        this.setState({
          citiesListOne: [],
        })
      }
    }
  }

  onResidenceCitySelectedOne = (item: { label: string, value: string }) => {
    this.setState(state => {
      return {
        residenceCities: item?.value,
        residenceBarangay1: "",
        errors: {
          ...state.errors,
          "residenceCities": ""
        }
      }
    }, () => {
      this.onGetBarangayListOne(item?.label)
     
    })
  }
   handleOptionLabelSelected =  (option: any)  =>{
    return option.value
  }
  handleOptionLabelSelectedTwo = (option: any, value: any) =>{
    return option.value === value
  }

 
  handleOptionLabelSelectedthree = (option:any) => {
  
    return option.label || '';
  };
  
  handleOptionLabelSelectedfour = (option:any) => {
    return option.value || '';
  };
 

  handleResidenseCitynewInputValue = (e:any, newInputValue:any) =>{
    this.setState({ residenceCities: newInputValue })
  }
  
 

  onResidenceCountrySelectedOne = (item: { label: string, value: string }) => {
    this.setState(state => {
      return {
        residenceCountries: item?.value,
        residenceCities: "",
        barangayListOne: [],
        residenceBarangay1: "",
        residenceBarangayacc: "",
        errors: {
          ...state.errors,
          "residenceCountries": ""
        }
      }
    }, () => {
      this.getCityListOne(item?.label)
    })
  }

 
  callApi = async (
    endpoint: string,
    method: "GET" | "POST",
    body?: Object
  ) => {
    const headers = {
      "Content-Type": configJSON.searchApiContentType,
    };

    const apiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (endpoint == configJSON.homeSchoolingReasonsEndPoints) {
      this.getReasonCallId = apiMessage.messageId;
    }
    if (endpoint == configJSON.accountsAPiEndPoint) {
      this.createAccountApiCallId = apiMessage.messageId
    }
    if (endpoint == configJSON.apiProvincesCallUrl) {
      this.getProvincesCallId = apiMessage.messageId
    }
    if (endpoint.includes(configJSON.barangayApiEndpointOne)) {
      this.getBarangayCallId = apiMessage.messageId
    }

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }

  loginAgain = () => {
    removeStorageData('authToken')
    removeStorageData('StudID')
    removeStorageData('hglink')
    removeStorageData('complete')
    removeStorageData('curentTime')
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }
  // Customizable Area End
}
