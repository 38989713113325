Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
const loadChatWidget = () => {
    window.HFCHAT_CONFIG = {
        EMBED_TOKEN: '2b491210-58a2-11ef-9e06-ef74d09d7171',
        ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
          onload: function() {
            var HappyFoxChat = this;
            HappyFoxChat.setVisitorInfo({
              name: localStorage.getItem('username'),
              email:  localStorage.getItem('emailState'),
              phoneNumber: localStorage.getItem('phoneNumber'),
            }, function(err, resp) {
              if (err) {
                console.error('Failed to set visitor details. Error:', err);
              } else {
                console.log('Added visitor details:', resp);
              }
            });
          }
    };
  
    (function () {
        var scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = window.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js';
  
        var s = document.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
            s.parentNode.insertBefore(scriptTag, s);
        } else {
            document.head.appendChild(scriptTag);
        }
    })();
  }

  exports.loadChatWidget = loadChatWidget;