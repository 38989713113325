import React, { CSSProperties } from "react";

import {

	// Customizable Area Start
	Container,
	Box,
	Button,
	Typography,
	Grid,
	styled,
	IconButton

	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { imgDownloadIcon } from "../../../blocks/customform3/src/assets";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";


const theme = createTheme({

	palette: {
		primary: {
			main: "#nnn",
			contrastText: "#fff",
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: "20px 0px",
		},
	},
});

const Boxcontainer = styled(Box)({
	marginTop: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
	"@media (max-width: 768px)": {

		flexWrap: "wrap",
		flexDirection: "column"
	}

})
const HrizontalBox = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#fab03d'

})
const Boxcontainerupper = styled(Box)({
	display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "14px"

})


const HrizontalBoxtwo = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#9167ac'

})
const HrizontalBoxthree = styled(Box)({
	width: '100%', height: '3px', backgroundColor: '#99cb51'

})

const downLoadIconStudentsDetails = [
	{
		id: 1,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 2,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 3,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
	{
		id: 4,
		Birthcertificate: "Birth certificate",
		FileType: "jpeg",
		SizeFile: "100kb",
		Dicon: imgDownloadIcon
	},
]

const ParentTwocoverAll = styled(Box)({
	paddingLeft: "3rem",
	'@media (max-width: 600px)': {
		paddingInline: "unset",

		// Adjust the padding for smaller screens
	},
});



// Customizable Area End

import Customform3Controller, {
	Props,
	Document,
	DocumentEntry
} from "../../customform3/src/Customform3Controller";

class StudentsDetails extends Customform3Controller {
	popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End 
	}

	// Customizable Area Start

	resolveComplexcityFunc = (student: any) => {
		let studentData: any = JSON.parse(student)
		return (
			<>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_address == null || studentData?.attributes?.previous_school?.data?.attributes?.school_address == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_address}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_email == null || studentData?.attributes?.previous_school?.data?.attributes?.school_email == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_email}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number == null || studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_contact_number}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.grade == null || studentData?.attributes?.previous_school?.data?.attributes?.grade == "choose" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.grade}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.last_year_of_school == null || studentData?.attributes?.previous_school?.data?.attributes?.last_year_of_school == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.last_year_of_school}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.child_language == "" || studentData?.attributes?.child_language == null ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.child_language}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.previous_school?.data?.attributes?.learner_reference_number == null || studentData?.attributes?.previous_school?.data?.attributes?.learner_reference_number == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.learner_reference_number}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{studentData?.attributes?.scholarship_type == null || studentData?.attributes?.scholarship_type == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{studentData?.attributes?.scholarship_type}</Typography>
					}
				</Typography>
					{this.scholar(studentData?.attributes?.scholarship_category, studentData?.attributes?.scholarship_type)}

			</>
		)
	}

	scholar = (category: string, type: string) => {
		return (
			<>
				<Typography style={webStyle.headingVal}>
					{category == null || category == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{category}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal}>
					{type == null || type == "" ?
						<Typography style={webStyle.headingVal}>Not selected</Typography> :
						<Typography style={webStyle.headingVal}>{type}</Typography>
					}
				</Typography>
			</>
		)
	}

	StudentsInformationFunc = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData?.attributes?.first_name)
		return (
			<>

				<Grid item xs={6} sm={3}>
					<Typography style={webStyle.headings} >Student's First Name:</Typography>
					<Typography style={webStyle.headings}>Student's Middle Name: </Typography>
					<Typography style={webStyle.headings}>Student's Last Name:</Typography>
					<Typography style={webStyle.headings}>Gender:</Typography>
					<Typography style={webStyle.headings}>Date of Birth:</Typography>
					<Typography style={webStyle.headings}>Age:</Typography>
					<Typography style={webStyle.headings}>Place of Birth (City):</Typography>
					<Typography style={webStyle.headings}>Place of Birth (Country):</Typography>
					<Typography style={webStyle.headings}>Religion:</Typography>
					<Typography style={webStyle.headings}>Nationality:</Typography>
					<Typography style={webStyle.headings}>Country of Residence:</Typography>
					<Typography style={webStyle.headings}>City:</Typography>
					<Typography style={webStyle.headings}>Barangay:</Typography>
					<Typography style={webStyle.headings}>Residential Address:</Typography>
					<Typography style={webStyle.headings}>Scholarship:</Typography>
					<Typography style={webStyle.headings}>Advocate / Referral Program:</Typography>
					<Typography style={webStyle.headings}>PWD Discount:</Typography>
					<Typography style={webStyle.headings}>Solo Parent Discount:</Typography>
					<Typography style={webStyle.headings}>Solo Parent ID:</Typography>
				</Grid>

				<Grid item xs={6} sm={3}>

					<Typography style={webStyle.headingVal} >{studentData?.attributes?.first_name}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.middle_name}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.last_name}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.gender}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.date_of_birth}</Typography>
					<Typography style={webStyle.headingVal} >20</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.city_of_birth}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.country_of_birth}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.religion}</Typography>
					{studentData?.attributes.nationality ? (
						<Typography style={webStyle.headingVal} >{studentData?.attributes.nationality}</Typography>
					) : (
						<Typography style={webStyle.headingVal}>N/A</Typography>
					)}
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.country_of_residence}</Typography>
					<Typography style={webStyle.headingVal} >{studentData?.attributes?.city_of_residence}</Typography>
					{studentData?.attributes.barangay ? (
						<Typography style={webStyle.headingVal} >{studentData?.attributes.barangay}</Typography>
					) : (
						<Typography style={webStyle.headingVal}>N/A</Typography>
					)}
					{studentData?.attributes.residential_address ? (
						<Typography style={webStyle.headingVal} >{studentData?.attributes.residential_address}</Typography>
					) : (
						<Typography style={webStyle.headingVal}>N/A</Typography>
					)}
					{studentData?.attributes?.scholarship ? (
						<Typography style={webStyle.headingVal} >Yes</Typography>
					) : (
						<Typography style={webStyle.headingVal}>No</Typography>
					)}
					{studentData?.attributes?.referral ? (
						<Typography style={webStyle.headingVal} >Yes</Typography>
					) : (
						<Typography style={webStyle.headingVal}>No</Typography>
					)}
					{studentData?.attributes?.pwd_discount ? (
						<Typography style={webStyle.headingVal} >Yes</Typography>
					) : (
						<Typography style={webStyle.headingVal}>No</Typography>
					)}
					{studentData?.attributes?.solo_parent_discount ? (
						<Typography style={webStyle.headingVal} >Yes</Typography>
					) : (
						<Typography style={webStyle.headingVal}>No</Typography>
					)}
					{studentData?.attributes?.solo_parent_id ? (
						<Typography style={webStyle.headingVal} >{studentData?.attributes?.solo_parent_id}</Typography>
					) : (
						<Typography style={webStyle.headingVal}>N/A</Typography>
					)}					
				</Grid>



			</>
		)
	}

	StudentsInfromationFunctwo = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)
		return (
			<>
				<Grid item xs={6} sm={3}>
					<Typography style={webStyle.headings} >Hub: </Typography>
					<Typography style={webStyle.headings} >Incoming Grade Level: </Typography>
					<Typography style={webStyle.headings}>Program:</Typography>
					<Typography style={webStyle.headings}>Accreditation:</Typography>
					<Typography style={webStyle.headings}>Cycle:</Typography>
					<Typography style={webStyle.headings}>Previous School Attended:</Typography>
					<Typography style={webStyle.headings}>Previous School Address:</Typography>
					<Typography style={webStyle.headings}>Previous School Email Address:</Typography>
					<Typography style={webStyle.headings}>Previous School Contact Number:</Typography>
					<Typography style={webStyle.headings}>Last Grade Level:</Typography>
					<Typography style={webStyle.headings}>Last School Year:</Typography>
					<Typography style={webStyle.headings}>Child's First Language:</Typography>
					<Typography style={webStyle.headings}>Learner Reference Number:</Typography>
					<Typography style={webStyle.headings}>Scholarship Type:</Typography>
					<Typography style={webStyle.headings}>Type of Scholarship:</Typography>
					<Typography style={webStyle.headings}>Advocate / Referral Code:</Typography>
					<Typography style={webStyle.headings}>Scholarship Category: </Typography>
					<Typography style={webStyle.headings}>Scholarship Type: </Typography>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.program_detail?.data?.attributes?.hub == null || studentData?.attributes?.program_detail?.data?.attributes?.hub == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.hub}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.program_detail?.data?.attributes?.grade == null || studentData?.attributes?.program_detail?.data?.attributes?.grade == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.grade}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>

						{studentData?.attributes?.program_detail?.data?.attributes?.program == null || studentData?.attributes?.program_detail?.data?.attributes?.program == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.program}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.program_detail?.data?.attributes?.accreditation == null || studentData?.attributes?.program_detail?.data?.attributes?.accreditation == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.accreditation}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.program_detail?.data?.attributes?.cycle == null || studentData?.attributes?.program_detail?.data?.attributes?.cycle == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.cycle}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.previous_school?.data?.attributes?.school_attended == null || studentData?.attributes?.previous_school?.data?.attributes?.school_attended == "" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.previous_school?.data?.attributes?.school_attended}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.scholarship_category == null || studentData?.attributes?.scholarship_category == "choose" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.scholarship_category}</Typography>
						}
					</Typography>
					<Typography style={webStyle.headingVal}>
						{studentData?.attributes?.referral_code == null || studentData?.attributes?.referral_code == "" ?
							<Typography style={webStyle.headingVal}>Not selected</Typography> :
							<Typography style={webStyle.headingVal}>{studentData?.attributes?.referral_code}</Typography>
						}
					</Typography>

					{this.resolveComplexcityFunc(localStorage.getItem("getStudentsViewId"))}
				</Grid>
			</>
		)
	}

	StudentsDetailsFuncChild = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)
		return (
			<>
				<Typography style={webStyle.headingVal} >
					{studentData?.attributes?.parent?.data?.attributes?.father_email == "" || studentData?.attributes?.parent?.data?.attributes?.father_email == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_email}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal} >
					{studentData?.attributes?.parent?.data?.attributes?.mother_first_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_first_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_first_name}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal} >
					{studentData?.attributes?.parent?.data?.attributes?.mother_middle_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_middle_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_middle_name}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal} >
					{studentData?.attributes?.parent?.data?.attributes?.mother_last_name == "" || studentData?.attributes?.parent?.data?.attributes?.mother_last_name == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_last_name}</Typography>
					}
				</Typography>
				<Typography style={webStyle.headingVal} >
					{studentData?.attributes?.parent?.data?.attributes?.mother_occupation == "" || studentData?.attributes?.parent?.data?.attributes?.mother_occupation == null ?
						<Typography style={webStyle.headingVal} >Not selected</Typography>
						: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_occupation}</Typography>
					}
				</Typography>
			</>
		)
	}



	StudentsDetailsFunc = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)

		return (
			<>
				<Grid container style={{ display: 'flex', padding: '24px' }} spacing={4} >
					<Grid item xs={6} sm={3}>
						<Typography style={webStyle.headings} >Father's First Name:</Typography>
						<Typography style={webStyle.headings}>Father's Middle Name: </Typography>
						<Typography style={webStyle.headings}>Father's Last Name:</Typography>
						<Typography style={webStyle.headings}>Father's Occupation:</Typography>
						<Typography style={webStyle.headings}>Father's Mobile Number:</Typography>
						<Typography style={webStyle.headings}>Father's Email Address: </Typography>
						<Typography style={webStyle.headings}>Mother's First Name (Maiden):</Typography>
						<Typography style={webStyle.headings}>Mother's Middle Name (Maiden):</Typography>
						<Typography style={webStyle.headings}>Mother's Last Name (Maiden):</Typography>
						<Typography style={webStyle.headings}>Mother's Occupation: </Typography>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.father_first_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_first_name == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_first_name}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.father_middle_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_middle_name == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_middle_name}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.father_last_name == "" || studentData?.attributes?.parent?.data?.attributes?.father_last_name == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_last_name}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.father_occupation == "" || studentData?.attributes?.parent?.data?.attributes?.father_occupation == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_occupation}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.father_mobile_number == "" || studentData?.attributes?.parent?.data?.attributes?.father_mobile_number == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.father_mobile_number}</Typography>
							}
						</Typography>

						{this.StudentsDetailsFuncChild(localStorage.getItem("getStudentsViewId"))}


					</Grid>
					<Grid item xs={6} sm={3}>
						<Typography style={webStyle.headings} >Mother's Mobile Number:</Typography>
						<Typography style={webStyle.headings}>Mother's Email Address:</Typography>
						<Typography style={webStyle.headings}>Marital Status:</Typography>
						<Typography style={webStyle.headings}>Guardian's Name:</Typography>
						<Typography style={webStyle.headings}>Guardian's Address:</Typography>
						<Typography style={webStyle.headings}>Guardian's Occupation:</Typography>
						<Typography style={webStyle.headings}>Guardian's Mobile Number:</Typography>
						<Typography style={webStyle.headings}>Guardian's Email Address:</Typography>
						<Typography style={webStyle.headings}>Relationship to the student:</Typography>
					</Grid>
					<Grid item xs={6} sm={3}>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number == "" || studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_mobile_number}</Typography>
							}

						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.mother_email == "" || studentData?.attributes?.parent?.data?.attributes?.mother_email == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.mother_email}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.marital_status == "" || studentData?.attributes?.parent?.data?.attributes?.marital_status == "choose" ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.marital_status}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.guardian_name == "" || studentData?.attributes?.parent?.data?.attributes?.guardian_name == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.guardian_name}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.guardian_address == "" || studentData?.attributes?.parent?.data?.attributes?.guardian_address == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.guardian_address}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.guardian_occupation == "" || studentData?.attributes?.parent?.data?.attributes?.guardian_occupation == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.guardian_occupation}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.guardian_mobile_number == "" || studentData?.attributes?.parent?.data?.attributes?.guardian_mobile_number == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.guardian_mobile_number}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.guardian_email == "" || studentData?.attributes?.parent?.data?.attributes?.guardian_email == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.guardian_email}</Typography>
							}
						</Typography>
						<Typography style={webStyle.headingVal} >
							{studentData?.attributes?.parent?.data?.attributes?.relationship_to_the_student == "" || studentData?.attributes?.parent?.data?.attributes?.relationship_to_the_student == null ?
								<Typography style={webStyle.headingVal} >Not selected</Typography>
								: <Typography style={webStyle.headingVal} >{studentData?.attributes?.parent?.data?.attributes?.relationship_to_the_student}</Typography>
							}
						</Typography>
					</Grid>
				</Grid>
			</>
		)
	}

	studentsFuncTwo = (student: any) => {
		let studentData: any = JSON.parse(student)
		console.log("testt12", studentData)
		return (
			<Container>
				<Grid container style={{ display: "flex", paddingTop: '1em' }}>
					<Grid item xs={12} lg={4} style={{ display: "flex", }} >
						<div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
							<Avatar alt="student avatar" src={"studentAvatar"} style={webStyle.cardAvatarStyles} />
							{/* <Avatar alt="student avatar" src={avatar} style={webStyle.cardAvatarStyle} /> */}

							<Typography style={webStyle.studentDetailsHeadingsVal} >{studentData?.attributes?.first_name}</Typography>
						</div>

					</Grid>

					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings} >
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>
									Grade Level:
								</span>
							</div>

							<div>
								<Typography style={webStyle.headingVal}>
									{studentData?.attributes?.program_detail?.data?.attributes?.grade == null || studentData?.attributes?.program_detail?.data?.attributes?.grade == "choose" ?
										<Typography style={webStyle.headingVal}>Not selected</Typography> :
										<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.grade}</Typography>
									}
								</Typography>
							</div>
						</Boxcontainerupper>


					</Grid>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings}>
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>Program Selected:
								</span>
							</div>
							<div>
								<Typography style={webStyle.headingVal}>
									{studentData?.attributes?.program_detail?.data?.attributes?.program == null || studentData?.attributes?.program_detail?.data?.attributes?.program == "choose" ?
										<Typography style={webStyle.headingVal}>Not selected</Typography> :
										<Typography style={webStyle.headingVal}>{studentData?.attributes?.program_detail?.data?.attributes?.program}</Typography>
									}
								</Typography>
							</div>
						</Boxcontainerupper>
					</Grid>

				</Grid>
				<hr style={{ border: "1px solid #ececf1", height: "1px" }}></hr>
				<Grid container style={{ display: "flex" }}>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings} >
						<Boxcontainerupper>
							<div>
								<span style={webStyle.studentDetailsHeadingsVal}>Submitted on:</span>
							</div>
							<div>
								<Typography style={webStyle.headingVal} >{studentData?.attributes?.submitted_date}</Typography>
							</div>
						</Boxcontainerupper>
					</Grid>
					<Grid item xs={12} lg={4} style={webStyle.studentDetailsHeadings}>
						<span style={webStyle.studentDetailsHeadingsVal}>Student Advisor:</span>
						<div>
							<Typography style={webStyle.headingVal}>Not selected</Typography>
						</div>
					</Grid>
					<Grid item xs={12} lg={4} >
						{/* <Button variant='contained' style={{ textTransform: 'none', background: "#99CB51",...webStyle.ProceedCheckout }}>Approved for Reenrollment</Button> */}
						<Button
							variant='contained'
							style={{
								minWidth: '245px',
								height: '52px',
								borderRadius: '10px',
								border: "none",
								backgroundColor: "#99CB51",
								color: '#fafafb',
								fontFamily: "'Poppins-Medium', sans-serif",
								fontSize: ' 14px',
								fontWeight: 600,
								backgroundBlendMode: 'normal',
								textTransform: 'capitalize'

							}}>
							Approved for Reenrollment</Button>
					</Grid>

				</Grid>
				<hr style={{ border: "1px solid #ececf1", height: "1px" }}></hr>

			</Container>
		)
	}

	returnKey = (item: DocumentEntry) => {
		return (Object.keys(item).find(k => k !== 'label'))
	}

	returnUrl = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).url : '')
	}

	returnContent = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).content_type : '')
	}

	returnSize = (key: string | undefined, get_studentDetailsData: DocumentEntry) => {
		return (key && get_studentDetailsData[key] && typeof get_studentDetailsData[key] === 'object' ? (get_studentDetailsData[key] as Document).file_size : '')
	}

	// Customizable Area End

	render() {

		return (
			// Customizable Area Start

			<>


				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<AppWithoutDrawer navigation={this.props.navigation} />
					<Sidebarmenu />
					
					<ThemeProvider theme={theme}>
						<Container maxWidth={false} style={{ alignItems: 'center', justifyContent: 'center' }} >
							<ParentTwocoverAll>
								<Grid container style={{ display: 'block' }}>
									<Grid style={webStyle.studenContentBlock} item>
										<Container maxWidth={false}>
											<Container maxWidth={false} style={{ margin: '0 auto', width: '100%', paddingRight: "block" }}>
												<div style={{ paddingLeft: "3.5rem", paddingRight: "6rem" }}>
													<Boxcontainer style={{}}>
														<Typography style={webStyle.welcomeTextStyles} >Students </Typography>
														<Button variant='contained' style={{ textTransform: 'none', ...webStyle.ProceedCheckout }}>Proceed to Checkout</Button>
													</Boxcontainer>
													<Grid container style={{ display: 'flex', }}>
														<Grid item xs={4}>
															<HrizontalBox><p></p></HrizontalBox>
														</Grid>
														<Grid item xs={4}>
															<HrizontalBoxtwo><p></p></HrizontalBoxtwo>
														</Grid>
														<Grid item xs={4}>
															<HrizontalBoxthree><p></p></HrizontalBoxthree>
														</Grid>
													</Grid>
													<Typography style={webStyle.mainheadings} >Student Details</Typography>
												</div>

												<Container maxWidth={false} style={webStyle.mainWrappers}>
													<Container maxWidth={false} style={webStyle.GridWrappers}>
														<Container maxWidth={false} style={webStyle.GridWrappers1}>
															{this.studentsFuncTwo(localStorage.getItem("getStudentsViewId"))}
															<Typography style={webStyle.mainheadings} >Student Information</Typography>
															<Grid container style={{ padding: '16px 24px', display: 'flex', fontFamily: "'Poppins, sans-serif" }} spacing={4} >
																{this.StudentsInformationFunc(localStorage.getItem("getStudentsViewId"))}
																{this.StudentsInfromationFunctwo(localStorage.getItem("getStudentsViewId"))}
															</Grid>
															<hr style={{ border: "1px solid #ececf1", margin: "20px 24px", height: "1px" }}></hr>
															<Typography style={webStyle.subheadings} >Family Details</Typography>
															<div>
																{this.StudentsDetailsFunc(localStorage.getItem("getStudentsViewId"))}
															</div>
															<Box style={{
																justifyContent: 'space-between', marginTop: '1.5em', display: 'flex',
																paddingLeft: "1.5em", paddingRight: "1.5em"
															}}>
																<Typography
																	style={{
																		fontWeight: 600,
																		color: "#171725",
																		fontSize: "16px"
																	}}>Files</Typography>
															</Box>
															<Box style={{ paddingLeft: "1em", paddingRight: "1em" }}>
																<hr style={{ border: "1px solid #ececf1", margin: "0.5em 0.5em 0 0.5em", height: "1px" }}></hr>
															</Box>

															<Box style={{ padding: '1.5em 1.5em 0 1.5em' }}>
																<Container maxWidth={false} style={webStyle.fileNames}>
																	<Grid container style={{ display: 'block' }}>
																		<Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																			<Grid style={{ width: "300px", display: "flex", alignItems: "center" }}>
																				<Box style={{ color: "white" }}>
																				</Box>
																				<Box style={{marginLeft: '15px', ...webStyle.namesStyless}}>File Name</Box>
																			</Grid>
																			<Grid style={{ width: "150px" }}>
																				<Box style={webStyle.namesStyless}>File Type</Box>
																			</Grid>
																			<Grid style={{ width: "100px" }}>
																				<Box style={webStyle.namesStyless}>Sizes</Box>
																			</Grid>
																			<Grid style={{ width: "100px" }}>
																				<Box style={webStyle.namesStyless}>Action</Box>
																			</Grid>
																			{/* <Box style={{ paddingRight: '2em' }}>{null}</Box> */}
																		</Box>
																	</Grid>
																</Container>
															</Box>
															<Box style={{ maxHeight: '300px', overflowY: 'auto', minHeight: '10px' }}>
																{this.state.finalDocumentList.length > 0 ? this.state.finalDocumentList.map((get_studentDetailsData, index) => {
																	const key = this.returnKey(get_studentDetailsData)
																	const url = this.returnUrl(key, get_studentDetailsData)
																	const content_type = this.returnContent(key, get_studentDetailsData)
																	const file_size = this.returnSize(key, get_studentDetailsData)
																	 return (<Box key={index} style={{ padding: '1.5em 1.5em 0 1.5em' }}>
																		<Container maxWidth={false} style={webStyle.fileNamestwo}>
																			<Grid container style={{ display: 'block' }}>
																				<Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																					<Grid style={{ width: "300px", display: "flex", alignItems: "center" }}>
																						<Box style={{ color: "#000000" }}>
																						</Box>
																						<Box style={webStyle.namesStylesstwo}>{get_studentDetailsData.label}</Box>
																					</Grid>
																					<Grid style={{ width: "150px" }}>
																						<Box style={webStyle.namesStylesstwo}>{content_type}</Box>
																					</Grid>
																					<Grid style={{ width: "100px" }}>
																						<Box style={webStyle.namesStylesstwo}>{file_size}</Box>
																					</Grid>
																					<Grid style={{ width: "100px" }}>
																						<div>
																							<img src={imgDownloadIcon} onClick={()=>this.downloadDoc(url)} alt="HG_application" style={{ width: "38px", height: '38px', cursor: 'pointer' }} />
																						</div>
																					</Grid>
																				</Box>
																			</Grid>
																		</Container>
																	</Box>
																	)
																})
																	:
																	<Box>
																		<Typography style={{textAlign: 'center', marginTop: '16px'}}>No Files available</Typography>
																	</Box>}
															</Box>
															<Box style={{ paddingLeft: "1.5em", paddingRight: "1em" }}>
																<hr style={{ border: "1px solid #ececf1", margin: "1em 0.5em", height: "1px" }}></hr>
															</Box>
															<Box style={{ padding: '1.5em' }}>
																<Button variant='contained' style={{ textTransform: 'none', ...webStyle.Checkout }}>Proceed to Checkout</Button>
															</Box>
														</Container>
													</Container>
												</Container>
											</Container>
										</Container>
									</Grid>
								</Grid>
							</ParentTwocoverAll>
						</Container >
					</ThemeProvider>
				</div>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

const webStyle: Record<string, CSSProperties> = {
	ProceedCheckout: {
		minWidth: '235px',
		height: '52px',
		borderRadius: '10px',
		border: "none",
		backgroundColor: "#fab03d",
		color: '#fafafb',
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: ' 16px',
		fontWeight: 600,
		backgroundBlendMode: 'normal',
	},
	studenContentWrapper: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	studenContentBlock: {
		[theme.breakpoints.down("md")]: {
			marginLeft: "0 !important",
			maxWidth: "calc(100%) !important",// or any value you desire for smaller screens
		},
	},
	studentDetailsHeadingsVal: {
		fontWeight: 600,
		fontFamily: "Poppins, sans-serif",
		paddingRight: "10px",
		fontSize: "16px",
		color: "#171725"
	},
	tableCol1: {
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "16px",
		fontWeight: 600,
		flexGrow: 1,
		maxWidth: "200px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "normal",
	},
	tableCol2: {
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "14px",
		fontWeight: 500,
		flexGrow: 1,
		textTransform: "capitalize",
		maxWidth: "200px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "normal",
	},
	fileNames: {
		borderRadius: "10px",

		padding: "0.6rem",
		border: "1px solid #e2e2ea",
		backgroundColor: '#9167ac',
	},
	fileNamestwo: {
		borderRadius: "10px",
		// height: '50px',
		padding: "1rem",
		border: "1px solid #e2e2ea",
		// backgroundColor: '#9167ac',
	},
	Checkout: {
		width: '235px',
		height: '52px',
		borderRadius: '10px',
		border: "none",
		backgroundColor: "#fab03d",
		color: '#fafafb',
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: ' 16px',
		fontWeight: 600,
		backgroundBlendMode: 'normal',
	},
	namesStyless: {
		color: "#ffffff",
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "14px",
		fontWeight: 600,
		flexGrow: 1,

	},
	namesStylesstwo: {
		color: "#000000",
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "14px",
		fontWeight: 600,
		flexGrow: 1,

	},
	studentDetailsHeadingss: {
		color: " #171725",
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: " 14px",
		fontWeight: 400,
		marginTop: '8px',
	},
	studentDetailsHeadings: {
		display: "flex",
		alignItems: "center"
	},
	headings: {
		color: "#555555",
		fontFamily: "Poppins, sans-serif",
		fontSize: "12px",
		fontWeight: 500,
		lineHeight: "22px",
	},
	welcomeTextStyles: {
		//marginTop: '50px',
		color: '#171725',
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: '24px',
		fontWeight: 600,
		width: "100%"
	},
	subheadings: {
		color: "#424244",
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "16px",
		fontWeight: 600,
		marginTop: '2em',
		paddingLeft: '24px'
	},
	headingVal: {
		color: "#55555",
		fontFamily: "Poppins, sans-serif",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "22px",
	},
	mainWrappers: {
		display: 'flex',
		flexDirection: 'row' as 'row',
		height: '100%',
		justifyContent: 'center',
		boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
		padding: "1rem",


	},
	mainheadings: {
		color: "#424244",
		fontFamily: "'Poppins-Medium', sans-serif",
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "30px",
		letterSpacing: "0.075px",
		marginTop: "2em",
		marginBottom: "1em",
		paddingLeft: '24px',
		width: "100vw"
	},
	GridWrappers: {
		borderRadius: "15px",
		background: "#ffffff",
		border: "1px solid #f1f1f5",
		padding: "inherit",
		maxWidth: "auto",


		[theme.breakpoints.up('sm')]: {
			padding: "2em !important",
		},
	},
	GridWrappers1: {
		borderRadius: "15px",
		background: "#ffffff",
		border: "1px solid #f1f1f5",
		padding: '0em',
		boxShadow: "1px 0px 14px 2px #e2e2e2",
		fontFamily: "'Poppins-Medium', sans-serif",
	},
	cardAvatarStyles: {
		width: '42px',
		height: '42px',
		marginRight: '10px'
	},
	EmailOutlinedIcon: {
		position: "relative",
		fontSize: "20px",
		marginLeft: "4px",
		marginTop: "5px",
		color: "#FFF",
		fontWeight: "bold"
	},
	EmailOutlinedIconC: {
		position: "relative",
		fontSize: "20px",
		marginLeft: "4px",
		marginTop: "5px",
		color: "#9167AC",
		fontWeight: "bold"
	},
	EmailOutlinedIconCC: {
		position: "absolute",
		fontSize: "1.9rem",
		color: "#9167AC"

	},


}
export default StudentsDetails
// Customizable Area End
