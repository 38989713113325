// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTextField } from "../../../components/src/EnrollFormHelperFunctions";


export interface SixStep {
  error: boolean
}

type FileErrors = {
  [key: string]: string;
};

interface Accreditation {
  id: string;
  attributes: {
    name: string;
  };
}

export interface Error {
  nationality?: string
  first_name?: string
  last_name?: string
}


  const worldMapData = require('city-state-country');
  let Country: any = require('country-state-city').Country;
  let City: any = require('country-state-city').City;

export interface ICountryData {
  id: number;
  sortname: string,
  name: string,
  phoneCode: number
}

export interface IDItem {
  label: string,
  value: string,
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

}

interface S {
  openD: boolean;
  drawerWidth: number;
  loadingPendingStudent: boolean;
  activeStep: number;
  newEnrollYesNo: boolean;
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarMessage: any;
  firstStepData: any;
  secondStepData: any;
  thirdStepData: any;
  fourthStepData: any;
  fifthStepData: any;
  sixStepData: SixStep;
  reasonForRegistering: any,
  religionData: any;
  religionDataResponseApi: any;
  Savedata:any;
  savetoDraftResponseApi:any;
  nationalitiesData: any;
  nationalitiesDataResponseApi: any;
  languagesData: any;
  languagesDataResponseApi: any;
  scholarshipSelectedData: any;
  scholarshipData: any;
  scholarshipDataResponseApi: any;
  barangayDataBirth: any;
  barangayDataBirthResponseApi: any;
  getAllCountries: any;
  getAllCountriesOne: any;
  provincesData: any;
  provincesDataResponseApi: any;
  getAllCities: any;
  getAllCitiesOne: any;
  uniqueCountryCodeData: any;
  getResidenceCountries: any,
  getResidenceCountriesOne: any,
  getResidenceCities: any,
  getResidenceCitiesOne: any,
  uniqueResidenceCountryCode: any,
  gradeData: any,
  gradeDataResponseApi: any,
  curriculumData: any,
  curriculumDataResponseApi: any,
  programData: any,
  programDataResponseApi: any,
  communityData: any,
  communityDataResponseApi: any,
  hubData: any,
  programIdData:any,
  cycleDataProgram:any,
  accreditationsDataProgram:any,
  gradeDataProgram:any,
  hubDataResponseApi: any,
  progarmDataGETid:any,
  accreditationData: any,
  accreditationDataResponseApi: any,
  reasonData: any,
  reasonDataResponseApi: any,
  termsAndConditionData: any,
  termsAndConditionDataResponseApi: any,
  generalTermsAndConditions: boolean,
  refundPolicy: boolean,
  privacyPolicy: boolean,
  pictureAuthorisation: boolean,
  formId:any,
  isSubmit:any,
  open:any
  draftTrueFalse: boolean
  loadingNext: boolean
}

interface SS {
  id: any;
}

//istanbul ignore next
export default class CustomEnrollController extends BlockComponent<Props, S, SS> {

  apiSavetoDraftId: any;
  apiReligionCallId: any;
  apiNationalitiesCallId: any;
  apilanguagesCallId: any;
  scholarshipCallId: any;
  apiBarangaysBirthCallId: string = "";
  apiProvincesCallId: string = "";
  apiGradeCallId: any;
  apiCurriculumCallId: any;
  apiProgramCallId: any;
  apiCommunityCallId: any;
  apiHubCallId: any;
  apiProgramByID:any;
  apiAccreditationCallId: any;
  apiReasonCallId: any;
  apiTermsAndConditionCallId: any;
  apiCallIdSubmit:any;
  apiCallIdSaveDraft:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      openD: true,
      drawerWidth: 300,
      loadingPendingStudent: false,
      activeStep: 0,
      newEnrollYesNo: false,
      isLoading: false,
      snackbarOpen: false,
      snackbarMessage: {},
      firstStepData: {
        studentFirstName: "",
        studentMiddleName: "",
        studentLastName: "",
        studentGender:  "choose" ,
        studentDOB: "",
        studentCountry: { id: "", value: "choose" },
        studentBirthCity: { id: "", value: "choose" },
        studentCountryAutoCompleteValue: { value: "choose" },
        studentBirthAutoCompleteValue: { id: "", value: "choose" },
        studentAge: "",
        studentReligion: { id: "", value: "choose" },
        studentReligionOthers: '',
        studentNationality: { id: "", value: "choose" },
        studentCountryOfResidence: { id: "", value: "choose" },
        countryResiAutoCompleteValue: { value: "" },
        studentCountryOfResidenceArray: [],
        studentCityOfResidence: { id: '', value: "choose" },
        studentChildsFirstLanguage: { id: "", value: "choose" },
        cityResiAutoCompleteValue: { value: "" },
        barangayBirth: { id: '', value: "choose" },
        barangaySubData: { id: '', value: "choose" },
        studentEnterChildsFirstLanguage: '',
        studentWouldYouLikeToApplyForAScholarShip: false,
        studentScholarShipType:  { id: '', value: "choose" },
        studentTypesOfScholarShip:  { id: '', value: "choose" },
        studentProofOfRelationship: { id: '', value: "choose" },
        studentCauseOfDeath: 'choose',
        studentfatherDeath: '',
        studentSpecification:  { id: '', value: "choose" },
      
        studentPwdIdNumber: '',
        studentAdvocatecode: "",
        studentSoloParentId: "",


        studentPwd: false,
        studentReferralProgram: false,
        studentParentDiscount: false,

       
       
        
        studentResidentialAddress: '',
        studentErrors: {

        },
      },
      secondStepData: {
        prevSchoolAttended: '',
        prevGradeLevel: { id: '', value: 'choose' },
        prevSchoolCurriculum: { id: '', value: 'choose' },
        prevSchoolEmail: '',
        prevSchoolNumber: '',
        prevSchoolYear: '',
        prevSchoolAddress: '',
        learnerRefNumber: '',
        prevSchoolErrors: {
          prevGradeLevel: { id: '', value: 'choose' },
          prevSchoolCurriculum: { id: '', value: 'choose' },
          prevSchoolAttended: '',
          prevSchoolEmail: '',
          prevSchoolNumber: '',
          prevSchoolAddress: '',
          learnerRefNumber: '',
          prevSchoolYear: '',
        },
        studentErrors: {}


      },
      thirdStepData: {
        hub: { id: '', value: 'choose' },
        program: { id: '', value: 'choose' },
        community: { id: '', value: 'choose' },
        cycle: { id: '', value: 'choose' },
        accreditation: { id: '', value: 'choose' },
        incomingGradeLevel: { id: '', value: 'choose' },
        shsTrack: { id: '', value: 'choose' },
        programErrors: {
          hub: '',
          community: '',
          cycle: '',
          program: '',
          incomingGradeLevel: '',
          accreditation: '',
          shsTrack:""
        }
      },
      fourthStepData: {
        fatherFirstName: '',
        fatherLastName: '',
        fatherMiddleName: '',
        fatherOccupation: '',
        fatherMobileNumber: '',
        fatherMobileNumberCode: '+91',
        motherFirstName: '',
        fatherEmail: '',
        motherMiddleName: '',
        motherLastName: '',
        motherOccupation: '',
        motherMobileNumber: '',
        motherEmail: '',
        motherMobileNumberCode: '+91',
        maritalStatus: '',
        guardianName: '',
        guardianAddress: '',
        guardianOccupation: '',
        guardianMobileNumber: '',
        guardianMobileNumberCode: '+91',
        guardianEmail: '',
        countryPhoneCodeListOne: [] as IDItem[],
        relationWithStudent: '',
        parentsErrors: {
          fatherFirstName: '',
          fatherLastName: '',
          fatherMiddleName: '',
          fatherOccupation: '',
          fatherMobileNumber: '',
          fatherEmail: '',
          motherFirstName: '',
          motherLastName: '',
          motherOccupation: '',
          motherMiddleName: '',
          motherMobileNumber: '',
          motherEmail: '',
          guardianName: '',
          maritalStatus: '',
          guardianAddress: '',
          guardianOccupation: '',
          guardianMobileNumber: '',
          relationWithStudent: '',
          guardianEmail: '',
        },
      },
      fifthStepData: {
        letterOfRecomendation: '',
        internationalLetter: '',
        chrissie: '',
        internationalAchevement: '',
        ministry: '',
        hgServant: '',
        proofIncome: '',
        financial: '',
        death: '',
        letterCommittee: '',
        birthStudent: '',
        recentBill: '',
        birthLuovaStaff: '',
        birthParent: '',
        pwdID: '',
        birthCertificate: '',
        reportCard: '',
        learnerRefNumberFile: '',
        schoolRecords: '',
        picture: '',
        soloParentId: '',
        validParentId: '',
        fileErrors: {
          letterOfRecomendation: '',
          pwdID: '',
          reportCard: '',
          schoolRecords: '',
          birthCertificate: '',
          learnerRefNumberFile: '',
          picture: '',
          validParentId: '',
          soloParentId: '',
          chrissie: '',
          internationalLetter: '',
          internationalAchevement: '',
          hgServant: '',
          ministry: '',
          financial: '',
          proofIncome: '',
          death: '',
          birthStudent: '',
          recentBill: '',
          letterCommittee: '',
          birthParent: '',
          birthLuovaStaff: ''
        }
      },
      sixStepData: {
        error: false
      },
      reasonForRegistering: {
        reason: { value: 'choose' },
        reasonErrors: {
          reasonForRegistering: '',
        }
      },
      religionData: [],
      religionDataResponseApi: {},
      Savedata:[],
      savetoDraftResponseApi:{},
      nationalitiesData: [],
      nationalitiesDataResponseApi: {},
      languagesData: [],
      languagesDataResponseApi: {},
      scholarshipSelectedData: [],
      scholarshipData: [],
      barangayDataBirth: [],
      scholarshipDataResponseApi: {},
      barangayDataBirthResponseApi: {},
      getAllCountries: [],
      getAllCountriesOne: [],
      getAllCitiesOne: [],
      getAllCities: [],
      uniqueCountryCodeData: [],
      getResidenceCountries: [],
      getResidenceCountriesOne: [],
      getResidenceCities: [],
      getResidenceCitiesOne: [],
      uniqueResidenceCountryCode: [],
      provincesData: [],
      provincesDataResponseApi: {},
      gradeData: [],
      gradeDataResponseApi: {},
      curriculumData: [],
      curriculumDataResponseApi: {},
      programData: [],
      programDataResponseApi: {},
      communityData: [],
      communityDataResponseApi: {},
      hubData: [],
      programIdData:[],
      cycleDataProgram:[],
      accreditationsDataProgram:[],
      gradeDataProgram:[],
      hubDataResponseApi: {},
      progarmDataGETid: {},
      accreditationData: [],
      accreditationDataResponseApi: {},
      reasonData: [],
      reasonDataResponseApi: {},
      termsAndConditionData: [],
      termsAndConditionDataResponseApi: {},
      generalTermsAndConditions: false,
      refundPolicy: false,
      privacyPolicy: false,
      pictureAuthorisation: false,
      formId:"",
      isSubmit:false,
      open:false,
      draftTrueFalse: false,
      loadingNext: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    this.savetoDraft();
    this.religionDataApiCall();
    this.nationalitiesDataApiCall();
    this.languagesDataApiCall();
    this.scholarshipDataApiCall();
    this.getProvincesDataApiCall()
    this.getAllCountriesData();
    this.getResidenceCountriesData();
    this.getGradeDataApiCall();
    this.getCurriculumDataApiCall();
    this.programDataApiCall();
    this.communityDataApiCall();
    this.hubDataApiCall();
    this.accreditationDataApiCall();
    this.reasonDataApiCall();
    this.termsAndConditionDataApiCall();
 
  }

  //Api calls start 

  religionDataApiCall = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReligionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointReligionDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  savetoDraft = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem('authToken')

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSavetoDraftId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointSavetoDraft
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  nationalitiesDataApiCall = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNationalitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointNationalitiesDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  languagesDataApiCall = () => {

    const header = {
      "Content-Type": configJSON.GradeAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apilanguagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointlanguagesDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  scholarshipDataApiCall = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scholarshipCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointScholarshipDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getBarangaysDataBirthApiCall = (cityBirthName: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem('authToken')
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBarangaysBirthCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `get_barangays?city=${cityBirthName}`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProvincesDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvincesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiProvincesCallUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGradeDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGradeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGradesDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCurriculumDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCurriculumCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointCurriculumDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  programDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointProgramDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  communityDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCommunityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointCommunityDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hubDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHubCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointHubDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  accreditationDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAccreditationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointAccreditationDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  reasonDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReasonCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointReasonDetail
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  termsAndConditionDataApiCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.apiTermsAndConditionCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointTnCDetail
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  studentsViewDeatilsByID = (studentId: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'token': localStorage.getItem('authToken')

    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProgramByID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointByIDProgram + studentId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  //Api calls ends


  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && apiResponse) {
        switch (apiRequestCallId) {
          case this.apiReligionCallId:
            this.religionDataApiResponse(apiResponse)
            break;
          case this.apiSavetoDraftId:
            this.SavetoDataApiResponse(apiResponse)
            break;
          case this.apiNationalitiesCallId:
            this.nationalitiesDataApiResponse(apiResponse)
            break;
          case this.apilanguagesCallId:
            this.languagesDataApiResponse(apiResponse)
            break;
          case this.scholarshipCallId:
            this.scholarshipDataApiResponse(apiResponse)
            break;
          case this.apiBarangaysBirthCallId:
            this.getBarangaysDataBirthApiResponse(apiResponse)
            break;
          case this.apiProvincesCallId:
            this.getProvincesDataApiResponse(apiResponse)
            break;
          case this.apiGradeCallId:
            this.gradeDataApiResponse(apiResponse)
            break;
          case this.apiCurriculumCallId:
            this.curriculumDataApiResponse(apiResponse)
            break;
          case this.apiProgramCallId:
            this.programDataApiResponse(apiResponse)
            break;
          case this.apiCommunityCallId:
            this.communityDataApiResponse(apiResponse)
            break;
          case this.apiHubCallId:
            this.hubDataApiRespnse(apiResponse)
            break;
          case this.apiProgramByID:
            this.ProgramDatabyIdApiRespnse(apiResponse)
              break;
          case this.apiAccreditationCallId:
            this.accreditationDataApiResponse(apiResponse)
            break;
          case this.apiReasonCallId:
            this.reasonDataApiResponse(apiResponse)
            break;
          case this.apiTermsAndConditionCallId:
            this.termsAndConditionDataApiResponse(apiResponse)
            break;
            case this.apiCallIdSubmit:
              this.setUpdateFormApi(apiResponse);
              break;
        }
      }
    }
  }

  //Api response functions
  religionDataApiResponse(apiResponse: any) {
    this.setState({ religionDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ religionData: apiResponse.data })
    }
    else {
      console.log('religion error', apiResponse.errors)
    }
  }
  SavetoDataApiResponse(apiResponse: any) {
    this.setState({ savetoDraftResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ Savedata: apiResponse.data })
    }
    else {
      console.log('religion error', apiResponse.errors)
    }
  }
  nationalitiesDataApiResponse(apiResponse: any) {
    this.setState({ nationalitiesDataResponseApi: apiResponse })

    if (apiResponse.data) {
      this.setState({ nationalitiesData: apiResponse.data })
    }
    else {
      console.log('nationality error', apiResponse.errors)
    }
  }
  languagesDataApiResponse(apiResponse: any) {
    this.setState({ languagesDataResponseApi: apiResponse })
    if (apiResponse.data) {
      this.setState({ languagesData: apiResponse.data })
    }
    else {
      console.log('language error', apiResponse.errors)
    }
  }

  scholarshipDataApiResponse(apiResponse: any) {
    this.setState({ scholarshipDataResponseApi: apiResponse })

    if (apiResponse.data) {
      this.setState({ scholarshipData: apiResponse.data })
      this.state.scholarshipData.map((item: any) => {
        this.state.scholarshipSelectedData.push({
          id: item.id,
          scholarship_name: item.attributes.scholarship_name,
        })
      })
    }
    else {
      console.log('scholarship error', apiResponse.errors)
    }
  }

  handlegradeData = (apiResponse: any) => {
    if (apiResponse.data) {
      this.setState({ barangayDataBirthResponseApi: apiResponse, barangayDataBirth: apiResponse.data })
    }
    else {
      console.log('barangay birth error', apiResponse.errors)
    }
  }
  getBarangaysDataBirthApiResponse(apiResponse: any) {
    this.handlegradeData(apiResponse)

  }

  getProvincesDataApiResponse(apiResponse: any) {
    this.setState({ provincesDataResponseApi: apiResponse })
    if (apiResponse.data) {
      this.setState({ provincesData: apiResponse.data })
    }
    else {
      console.log('province error', apiResponse.error)
    }
  }

  gradeDataApiResponse(apiResponse: any) {
    this.setState({ gradeDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ gradeData: apiResponse.data })
    }
    else {
      console.log('grade error', apiResponse.errors)
    }
  }

  curriculumDataApiResponse(apiResponse: any) {
    this.setState({ curriculumDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ curriculumData: apiResponse.data })
    }
    else {
      console.log('curriculum error', apiResponse.errors)
    }
  }

  programDataApiResponse(apiResponse: any) {
    this.setState({ programDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ programData: apiResponse.data })
    }
    else {
      console.log('program errors', apiResponse.data)
    }
  }

  communityDataApiResponse(apiResponse: any) {
    this.setState({ communityDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ communityData: apiResponse.data });
    }
    else {
      console.log('community errors ', apiResponse.errors);
    }
  }

  hubDataApiRespnse(apiResponse: any) {
    this.setState({ hubDataResponseApi: apiResponse });
    if (apiResponse.data) {
      this.setState({ hubData: apiResponse.data })
    }
    else {
      console.log('hub errors', apiResponse.errors)
    }
  }
  ProgramDatabyIdApiRespnse(apiResponse: any) {
    this.setState({ progarmDataGETid: apiResponse });
    if (apiResponse.data) {
      const hubDataID = apiResponse.data.hubs;
      const hubList = Object.keys(hubDataID);
      Object.keys(hubDataID).forEach((hubName) => {
        const cycles = Object.keys(hubDataID[hubName].cycles);
        console.log(`Cycles for ${hubName}:`, cycles);
        this.setState({cycleDataProgram: cycles})
      });
      this.setState({ programIdData: hubList })
      Object.keys(hubDataID).forEach((hubName) => {
        Object.keys(hubDataID[hubName].cycles).forEach((cycleName) => {
          const accreditations = Object.keys(hubDataID[hubName].cycles[cycleName].accreditation);
          this.setState({accreditationsDataProgram: accreditations})
          console.log(`Accreditations for ${hubName} - ${cycleName}:`, accreditations);
        });
      });
      
      Object.keys(hubDataID).forEach((hubName) => {
        Object.keys(hubDataID[hubName].cycles).forEach((cycleName) => {
          Object.keys(hubDataID[hubName].cycles[cycleName].accreditation).forEach((accreditationName) => {
            const grades = hubDataID[hubName].cycles[cycleName].accreditation[accreditationName].grades;
            console.log(`Grades for ${hubName} - ${cycleName} - ${accreditationName}:`, grades);
            this.setState({gradeDataProgram: grades})
          });
        });
      });
      
      console.log(this.state.programIdData, 'shivam');
    }
    
      
    else {
      console.log('hub errors', apiResponse.errors)
    }
  }


  accreditationDataApiResponse(apiRespnse: any) {
    this.setState({ accreditationDataResponseApi: apiRespnse })
    if (apiRespnse.data) {
      this.setState({ accreditationData: apiRespnse.data })
    }
    else {
      console.log('accreditation errors', apiRespnse.errors)
    }
  }

  reasonDataApiResponse(apiResponse: any) {
    this.setState({ reasonDataResponseApi: apiResponse })
    if (apiResponse.data) {
      this.setState({ reasonData: apiResponse.data })
    }
    else {
      console.log('reason for registering errors', apiResponse.errors)
    }
  }

  termsAndConditionDataApiResponse(apiResponse: any) {
    this.setState({ termsAndConditionDataResponseApi: apiResponse })
    if (apiResponse.data) {
      this.setState({ termsAndConditionData: apiResponse.data })
    }
    else {
      console.log(' terms and condition errors ', apiResponse.errors)
    }
  }

  setUpdateFormApi(apiResponse: any) {
    this.setState({ loadingNext: false })
    if (apiResponse.data) {
      this.setState({ formId: apiResponse.data.id })
      this.checkNext()
      this.formCheck(apiResponse.data.id)
    }
    else if (apiResponse.errors) {
      const lastNameError = apiResponse.errors.find((error: any) => error?.last_name);
      const lastNameErrorMessage = lastNameError ? lastNameError?.last_name : "";
      const firstNameError = apiResponse.errors.find((error: any) => error?.first_name);
      const firstNameErrorMessage = firstNameError ? firstNameError?.first_name : "";
      if (firstNameErrorMessage) {
        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
              ...prevState.firstStepData.studentErrors,
              studentFirstName: firstNameErrorMessage,
            },
          },
        }));
      }

      if (lastNameErrorMessage) {
        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
              ...prevState.firstStepData.studentErrors,
              studentLastName: lastNameErrorMessage,
            },
          },
        }));
      }

      if (apiResponse.errors[0].nationality) {
        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
              ...prevState.firstStepData.studentErrors,
              studentNationality: apiResponse.errors[0].nationality,
            },
          },
        }));
      }


    }
  }

  //handle functions 
  getSteps = () => {
    return ['Student', 'Previous School', 'Program Details', 'Parents', 'Files', 'Review', 'T&C'];
  };

  handleBackMain = async () => {
    if (this.state.activeStep === 7 && this.state.newEnrollYesNo === false) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoardNonExpandablePage");
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
    } else if (this.state.activeStep === 7 && this.state.newEnrollYesNo === true) {
      this.setState({ activeStep: 0 })
    } else {
      this.setState({ activeStep: this.state.activeStep - 1 })
    }
  };

  handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return null;
    }
    this.setState({ snackbarOpen: false })
  };

  studentEnrollmentvalidatio1 = () => {
    const {
        studentLastName,
        studentFirstName,
        studentMiddleName,
        studentGender,
        studentDOB,
        studentCountry,
        studentResidentialAddress,
        studentChildsFirstLanguage,
        studentCityOfResidence,
        studentCountryOfResidence,
        studentBirthCity,
        studentAge,
        studentReligion,
        studentNationality,
        studentTypesOfScholarShip,
        studentScholarShipType,
        studentWouldYouLikeToApplyForAScholarShip,
        studentAdvocatecode,
        studentReferralProgram,
        studentPwdIdNumber,
        studentSoloParentId,
        studentPwd,
        studentParentDiscount,
        studentSpecification,
        studentProofOfRelationship,
        studentCauseOfDeath,
        studentfatherDeath 
    } = this.state.firstStepData;

    const errors :any= {
        studentLastName:this.validateRequired1(studentLastName, "Please provide your last name. It is required."),
        studentFirstName:this.validateRequired1(studentFirstName, "Please provide your first name. It is required."),
        studentMiddleName:this.validateRequired1(studentMiddleName, "Please provide your middle name. It is required."),


        studentDOB:this.validateRequired4(studentDOB, "Please provide your DOB. It is required."),
        studentAge:this.validateRequired4(studentDOB, "Please provide your age. It is required."),

        studentGender: this.validateValue(studentGender, "choose", "Please provide your gender. It is required."),
        studentCountry: this.validateValue(studentCountry?.value, "", "Please provide your country. It is required"),
        studentBirthCity: this.validateValue(studentBirthCity?.value, "", "Please provide your birth city. It is required"),

       
       
        studentReligion: this.validateValue(studentReligion.value, "choose", "Please provide your religion. It is required"),
        studentNationality: this.validateValue(studentNationality.value, "choose", "Please provide your nationality. It is required"),
        
        studentCountryOfResidence: studentCountryOfResidence?.value ? "" : "Please provide your nationality. It is required",
        studentCityOfResidence: studentCityOfResidence?.value ? "" : "Please provide your nationality. It is required",
        studentChildsFirstLanguage: this.validateValue(studentChildsFirstLanguage.value, "choose", "Please provide your language. It is required"),

    
        studentPwdIdNumber: studentPwd && this.validateNumberPWd (studentPwdIdNumber),
        studentSoloParentId: studentParentDiscount && this.validateNumbersolo(studentSoloParentId),
        studentAdvocatecode: studentReferralProgram && this.validateNumberAdcovate(studentAdvocatecode),
        studentResidentialAddress: this.validateStudentResidentialAddress(studentResidentialAddress),

        studentSpecification: (studentTypesOfScholarShip?.value == "Scholarship for HG Servant Leaders") && this.validateValue(studentSpecification.value, "choose", "Please provide your specification. It is required"),
        studentTypesOfScholarShip: this.validateValue(studentWouldYouLikeToApplyForAScholarShip && studentTypesOfScholarShip.value ,"choose" , "Please provide your Types of Scholarship . It is required." ),
        studentScholarShipType: this.validateValue(studentWouldYouLikeToApplyForAScholarShip && studentScholarShipType.value ,"choose", "Please provide your Scholarship Type. It is required."),
        studentProofOfRelationship: (studentTypesOfScholarShip?.value == "HG Staff Student Scholarship") && this.validateValue(studentProofOfRelationship.value , "choose" ," Please select proof of relationship "),
        studentCauseOfDeath: (studentTypesOfScholarShip?.value == "The Jovi Villote Memorial Scholarship") && this.validateValue(studentCauseOfDeath, "choose", "Please enter cause of the death"),
        studentfatherDeath: (studentTypesOfScholarShip?.value == "The Jovi Villote Memorial Scholarship") && this.validateValue(studentfatherDeath, '', "Please Enter Date of father's death  "),
      };


    const studentErrors = Object.entries(errors).reduce((acc :any, [key, value]) => {
        if (value) acc[key] = value;
        return acc;
    }, {});

    const hasErrors = Object.keys(studentErrors).length > 0;

    this.setState(prevState => ({
        firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
                ...prevState.firstStepData.studentErrors,
                ...studentErrors,
            },
        },
    }));
    return !hasErrors;
};
 validateStudentResidentialAddress = (address:any) => {
  if (address.length > 75) {
    return "Address cannot be longer than 75 characters.";
  }
  return address?.trim() ? "" : "Please provide your residential address. It is required.";
};
validateRequired1 = (value:any, errorMessage:any) => {

  if (!value.trim()) {
     return errorMessage;
   }
   if (value.trim().length > 50) {
     return "Name cannot be longer than 50 characters.";
   }
   return "";
 };
validateRequired2 = (value:any, errorMessage:any) => {
  return value.trim() ? "" : errorMessage;
};
validateRequired3 = (value:any, errorMessage:any) => {
  return value.trim() ? "" : errorMessage;
};
validateRequired4= (value:any, errorMessage:any) => {
  return value.trim() ? "" : errorMessage;
};

studentEnrollmentvalidatio2 = () => {
  const { 
      prevSchoolAttended, 
      prevSchoolCurriculum, 
      prevSchoolEmail, 
      prevSchoolNumber, 
      prevSchoolAddress, 
      prevSchoolYear, 
      learnerRefNumber, 
      prevGradeLevel 
  } = this.state.secondStepData;


  const errors = {
    prevGradeLevel: prevSchoolAttended.toLowerCase() == "n/a" ? "" :this.validateValue(prevGradeLevel.value, "choose", "Please provide your level. It is required."),
    prevSchoolCurriculum:prevSchoolAttended.toLowerCase() == "n/a" ? "" : this.validateValue(prevSchoolCurriculum.value, "choose", "Please provide your curriculum. It is required."),
      prevSchoolAttended: this.validateRequired(prevSchoolAttended, "Please provide your Attended. It is required."),
      prevSchoolEmail:prevSchoolAttended.toLowerCase() == "n/a" ? "" : this.validateEmail(prevSchoolEmail),
      prevSchoolNumber: prevSchoolAttended.toLowerCase() == "n/a" ? "" :this.validateNumber(prevSchoolNumber),
      prevSchoolAddress:prevSchoolAttended.toLowerCase() == "n/a" ? "" : this.validateRequired(prevSchoolAddress, "Please provide your address. It is required."),
      prevSchoolYear: prevSchoolAttended.toLowerCase() == "n/a" ? "" :this.validateYear(prevSchoolYear),
      learnerRefNumber: prevSchoolAttended.toLowerCase() == "n/a" ? "" :this.validateRequired(learnerRefNumber, "Please provide your number. It is required."),
  };

 
  const hasErrors = Object.values(errors).some(error => error !== "");

 
  this.setState(prevState => ({
      secondStepData: {
          ...prevState.secondStepData,
          studentErrors: {
              ...prevState.secondStepData.studentErrors,
              ...errors
          }
      }
  }));

 
  return !hasErrors;
};

 validateValue = (value:any, targetValue:any, errorMessage:any) => {
  return value === targetValue ? errorMessage : "";
};
validateRequired = (value: any, errorMessage: any) => {
  if (!value.trim()) {
    return errorMessage;
  }
  if (value.trim().length > 50) {
    return "Address cannot be longer than 50 characters.";
  }
  return "";
};


 validateYear = (year:any) => {
  const regexYear = /^\d{4}$/;
  const currentYear = new Date().getFullYear();

  if (!year.trim()) {
    return "Please provide the year in the format YYYY.";
  }

  if (!regexYear.test(year)) {
    return "Please provide the year in the format YYYY.";
  }

  const inputYear = parseInt(year, 10);

  if (inputYear > currentYear) {
    return `Future year not allowed.`;
  }

  return "";
};





 validateEmail = (email:any) => {
  const regexEmail =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return email.trim() && regexEmail.test(email) ? "" : "Please provide a valid email address.";
};

  validateNumber = (number:any) => {
    const regexSchoolNumber = /^\d+$/;
    const minLength = 6;
    const maxLength = 11;
    const trimmedNumber = number.trim();    
    if (regexSchoolNumber.test(trimmedNumber) && trimmedNumber.length >= minLength && trimmedNumber.length <= maxLength) {
      return "";
    } else {
      return "Contact number must contain 6 to 11 digits";
    }
  };
  validateNumberAdcovate = (numbers: any) => {
    const regexNumber = /^[A-Za-z0-9]+$/;
    const minLength = 10;
    return numbers.trim().length === minLength && regexNumber.test(numbers) ? "" : "Advocate code must not exceed 10 characters ";};
    
    validateNumbersolo = (numbers: any) => {
      const regexNumber = /^[A-Za-z0-9]+$/;
      const minLength = 15;
      return numbers.trim().length === minLength && regexNumber.test(numbers) ? "" : "Parent ID must not exceed 15 characters";};
      
    validateNumberPWd = (numbers: any) => {
    const regexNumber = /^[A-Za-z0-9]+$/;
    const minLength = 12;
    return numbers.trim().length === minLength && regexNumber.test(numbers) ? "" : "PWD ID must be a 12 digit number";};
  validateNumber1 = (numbers: any) => {
  const regexNumber = /^[A-Za-z0-9]+$/;
  const minLength = 10;
  return numbers.trim().length === minLength && regexNumber.test(numbers) ? "" : "Please provide a valid code with exactly 10 alphanumeric characters (A-Z, a-z, and 0-9).";};
  studentEnrollmentvalidatio3 = () => {
    const { thirdStepData } = this.state;
    const { program, community, hub, cycle, accreditation, incomingGradeLevel, shsTrack } = thirdStepData;
    
    let fields: any[] = []
    let fieldNames: string[] = [];
    if (this.state.thirdStepData.accreditation.value == 'PH Accreditation' && (this.state.thirdStepData.incomingGradeLevel.value == "Grade 11" || this.state.thirdStepData.incomingGradeLevel.value == "Grade 12")) {
      fields = [program, community, hub, cycle, accreditation, incomingGradeLevel, shsTrack];
      fieldNames = ['program', 'community', 'hub', 'cycle', 'accreditation', 'incomingGradeLevel', 'shsTrack'];
    } else {
      fields = [program, community, hub, cycle, accreditation, incomingGradeLevel];
      fieldNames = ['program', 'community', 'hub', 'cycle', 'accreditation', 'incomingGradeLevel'];
    }

    const errors :any = {};

    fields.forEach((field, index) => {

        if (field.value === 'choose' ||  field.value === '' ) {
            errors[fieldNames[index]] = `${fieldNames[index][0].toUpperCase() + fieldNames[index].slice(1)} is required`;
        }
         else {
            errors[fieldNames[index]] = '';
        } 
        
        
    });
  

    this.setState((prevState) => ({
        thirdStepData: {
            ...prevState.thirdStepData,
            programErrors: { ...prevState.thirdStepData.programErrors, ...errors },
        },
    }));

   
    return !Object.values(errors).some(error => error !== '');
    
};

  gardianParenMobileNumberError = () => {
    const { fourthStepData } = this.state;
    let errors:string = '';
    const value = fourthStepData['guardianMobileNumber'].trim();
    const minLength = 10; // Minimum length
    const regexSchoolNumber = /^\d+$/; // Matches only digits

    if (!regexSchoolNumber.test(value)) {
      errors = "Mobile Number is required";
    } else if (value != 0 && value.length !== minLength) {
      errors = `Mobile number must be ${minLength} digits long.`;
    } else {
      errors = "";
    }
    return errors;
  }

  addErrorMessage = (value: string) => {
    let errors: string;
    const regexEmailall = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value === 'n/a' || value === 'N/A' || value === "N/a" || value === "n/A") {
      errors = ''
    } else {
      if (!regexEmailall.test(value)) {
        errors = 'Valid email is required';
      } else {
        errors = ''

      }
    }
    return errors;
  }

  addErrorMessageString = (value: string) => {
    let errors: string;
    if (value === 'n/a' || value === 'N/A' || value === "N/a" || value === "n/A") {
      errors = ''
    } else {
      if(value.length > 2){
        errors = ''
      } else {
        errors = 'Enter valid data';
      }
    }
    return errors;
  }

  addErrorMessageBasedOnLengthString = (value: string, length: number) => {
    let errors: string;
    if (value === 'n/a' || value === 'N/A' || value === "N/a" || value === "n/A") {
      errors = ''
    } else {
      if(value.length <= length){
        errors = ''
      } else {
        errors = 'Enter valid data 2555';
      }
    }
    return errors;
  }

  studentEnrollmentvalidatio4 = () => {
    const { fourthStepData } = this.state;
    const fields = [
        { name: 'fatherFirstName', message: "Father's first name is required" },
        { name: 'fatherMiddleName', message: "Father's middle name is required" },
        { name: 'fatherLastName', message: "Father's last name is required" },
        { name: 'fatherOccupation', message: "Father's occupation must be fewer than 25 characters" },
        { name: 'fatherMobileNumber', message: "Father's mobile number is required" },
        { name: 'fatherEmail', message: "Father's email is required" },
        { name: 'motherFirstName', message: "Mother's first name is required" },
        { name: 'motherMiddleName', message: "Mother's middle name is required" },
        { name: 'motherLastName', message: "Mother's last name is required" },
        { name: 'motherOccupation', message: "Mother's occupation must be fewer than 25 characters" },
        { name: 'motherMobileNumber', message: "Mother's mobile number is required" },
        { name: 'motherEmail', message: "Mother's email is required" },
        { name: 'maritalStatus', message: 'Marital status is required' },
        { name: 'guardianName', message: "Guardian's name is required" },
        { name: 'guardianAddress', message: 'Address is required' },
        { name: 'guardianOccupation', message: "Guardian's occupation must be fewer than 25 characters" },
        { name: 'guardianMobileNumber', message: 'Mobile number is required' },
        { name: 'guardianEmail', message: "Guardian's email is required" },
        { name: 'relationWithStudent', message: 'Relationship is required' }
    ];

    const errors:any = {};
    const isValidEmail = (email:any) => {
      const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return regexEmail.test(email);
    };
    
    const isValidPhoneNumber = (number:any) => {
      const regexPhoneNumber = /^\d+$/;
      const minLength = 6;
      const maxLength = 11;
      return regexPhoneNumber.test(number) && number.length >= minLength && number.length <= maxLength;
    };
    
    const validateEmailField = (name:any, value:any, errors:any) => {
      if (!isValidEmail(value)) {
        errors[name] = "Please provide a valid email address.";
      }
    };
    
    const validatePhoneNumberField = (name:any, value:any, errors:any) => {
      if (!isValidPhoneNumber(value)) {
        errors[name] = `Contact number must contain between 6 and 11 digits.`;
      }
    };
    fields.forEach(({ name, message }) => {
      const value = fourthStepData[name].trim(); 
      if (!value) {
        errors[name] = message;
      }

      if ((name === "fatherEmail" || name === "motherEmail" || name === "guardianEmail") && this.addErrorMessage(value)){
        errors[name] = message;
      }
    
      if (name !== "guardianMobileNumber" && (value == 0)) {
        errors[name] = message;
      }
      if (["fatherMobileNumber", "motherMobileNumber", "guardianMobileNumber"].includes(name)) {
        if (value != 0) {
          validatePhoneNumberField(name, value, errors);
        }
      }

      if ((name === "relationWithStudent" || name === "guardianAddress" || name === "guardianName") && this.addErrorMessageString(value)){
        errors[name] = message;
      }
      
      if ((name === "guardianOccupation" || name === "motherOccupation" || name === "fatherOccupation") && this.addErrorMessageBasedOnLengthString(value, 25)){
        errors[name] = message;
      }
    });

    this.setState((prevState) => ({
        fourthStepData: {
            ...prevState.fourthStepData,
            parentsErrors: { ...prevState.fourthStepData.parentsErrors, ...errors },
        },
    }));

    return Object.values(errors).every(error => !error);
};

studentEnrollmentvalidatio5 = () => {
  const {
    letterOfRecomendation,
    pwdID,
    birthCertificate,
    reportCard,
    schoolRecords,
    learnerRefNumberFile,
    picture,
    soloParentId,
    validParentId,
    chrissie,
    internationalLetter,
    internationalAchevement,
    ministry,
    hgServant,
    financial,
    proofIncome,
    death,
    letterCommittee,
    birthStudent,
    recentBill,
    birthParent,
    birthLuovaStaff
  } = this.state.fifthStepData;

  const requiredFiles = {
    birthCertificate,
    picture,
    validParentId,
  };

  let LRNFiles = {}
  let reportsFiles = {}

  if(this.state.secondStepData.learnerRefNumber != 0){
    LRNFiles = {
      learnerRefNumberFile,
    }
  }

  if(this.state.secondStepData.prevSchoolAttended.toLowerCase()!= 'n/a'){
    reportsFiles = {
      schoolRecords,
      reportCard,
    }
  }

  let scholarshipValue = this.state.firstStepData.studentTypesOfScholarShip?.value;

  let additionalFiles = {};

  switch (scholarshipValue) {
    case "Chrissie Gracia Scholarship":
      additionalFiles = { chrissie };
      break;
    case "The International/National Achievement Scholarship":
      additionalFiles = { internationalLetter, internationalAchevement };
      break;
    case "The Ministry Leaders Assistance":
      additionalFiles = { ministry };
      break;
    case "Scholarship for HG Servant Leaders":
      additionalFiles = { hgServant };
      break;
    case "Financial Aid":
      additionalFiles = { financial, proofIncome };
      break;
    case "The Jovi Villote Memorial Scholarship":
      additionalFiles = { death, letterCommittee, birthStudent, recentBill };
      break;
    case "HG Staff Student Scholarship":
      additionalFiles = { birthParent, birthLuovaStaff };
      break;
    default:
      additionalFiles = {};
      break;
  }

  if (this.state.firstStepData.studentParentDiscount) {
    additionalFiles = { ...additionalFiles, soloParentId };
  }

  if (this.state.firstStepData.studentPwd) {
    additionalFiles = { ...additionalFiles, pwdID };
  }

  const updatedRequiredFiles = {
    ...requiredFiles,
    ...LRNFiles,
    ...reportsFiles,
    ...additionalFiles,
  };

  const fileErrors: FileErrors = {};
  let hasErrors = false;

  for (const [file, value] of Object.entries(updatedRequiredFiles)) {
    if (!value) {
      fileErrors[file] = "Required";
    } else {
      fileErrors[file] = "";
    }
  }

  this.setState(prevState => ({
    fifthStepData: {
      ...prevState.fifthStepData,
      fileErrors: {
        ...prevState.fifthStepData.fileErrors,
        ...fileErrors,
      },
    },
  }));
  const finalCheck = Object.values(updatedRequiredFiles).every(value => value !== "");
  if (finalCheck) {
    hasErrors = true
  }
  return hasErrors;
};


  studentEnrollmentvalidatio6 = () => {

    const { reason } = this.state.reasonForRegistering;
    if (reason.value === "choose") {

      this.setState((prevState) => ({
        reasonForRegistering: {
          ...prevState.reasonForRegistering,
          reasonErrors: {
            ...prevState.reasonForRegistering.reasonErrors,
            reason: "Required",

          },
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        reasonForRegistering: {
          ...prevState.reasonForRegistering,
          reasonErrors: {
            ...prevState.reasonForRegistering.reasonErrors,
            reason: '',


          },
        },
      }));
      return true;
    }
  };

  studentEnrollmentvalidatio7 = () => {
    const generalTermsAndConditions = this.state.generalTermsAndConditions
    const refundPolicy = this.state.refundPolicy
    const privacyPolicy = this.state.privacyPolicy
    const pictureAuthorisation = this.state.pictureAuthorisation
    if (privacyPolicy && generalTermsAndConditions && refundPolicy && pictureAuthorisation) {
      this.setState({ sixStepData: { error: false } })
      return true;
    }
    else {
      this.setState({ sixStepData: { error: true } })
      return false;
    }
  };



 

  handleNextBtn = (e: any) => {
    e.preventDefault();
    const { activeStep } = this.state;
    this.setState({draftTrueFalse: false})
  
    let isValid = false;

    if (activeStep === 0) {
      isValid = this.studentEnrollmentvalidatio1();
    } else if (activeStep === 1) {
      isValid = this.studentEnrollmentvalidatio2();
    } else if (activeStep === 2) {
      isValid = this.studentEnrollmentvalidatio3();
    } else if (activeStep === 3) {
      isValid = this.studentEnrollmentvalidatio4();
    } else if (activeStep === 4) {
      isValid = this.studentEnrollmentvalidatio5();
    }
    else if (activeStep === 5) {
      isValid = this.studentEnrollmentvalidatio6();
    } else if (activeStep === 6) {
      isValid = this.studentEnrollmentvalidatio7();
    }

    if (isValid) {
      this.setState({ loadingNext: true })
      this.onSave();
         //@ts-ignore
      this.setState((prevState) => {
        if (activeStep === 0) {
          return {
            firstStepData: {
              ...prevState.firstStepData,
                studentErrors: {
                studentLastName: "",
                studentFirstName: "",
                studentMiddleName: "",
                studentGender: "",
                studentDOB: "",
                studentCountry: "",
                studentBirthCity: "",
                studentAge: "",
                studentReligion: "",
                studentNationality: "",
                studentCountryOfResidence: "",
                studentCityOfResidence: "",
                studentChildsFirstLanguage: "",
                studentResidentialAddress: "",
             
              },
            },  
            secondStepData: prevState.secondStepData,
          };
        } else if (activeStep === 1) {
          return {
            secondStepData: {
              ...prevState.secondStepData,
              studentErrors: {
                prevSchoolAttended: "",
                prevSchoolCurriculum: "",
                prevSchoolEmail: "",
                prevSchoolNumber: "",
                prevSchoolAddress: "",
                prevSchoolYear: "",
                learnerRefNumber: "",
                prevGradeLevel: "",
              },
            },
            thirdStepData: prevState.thirdStepData,
          };
        }
        else if (activeStep === 2) {
          return {
            secondStepData: prevState.secondStepData,
            thirdStepData: {
              ...prevState.thirdStepData,
              programErrors: {
                program: "",
                community: '',
                hub: '',
                cycle: "",
                accreditation: "",
                incomingGradeLevel: "",
                shsTrack: ''
              },
            },
          };
        }
        else if (activeStep === 3) {
          return {
            thirdStepData: prevState.thirdStepData,
            fourthStepData: {
              ...prevState.fourthStepData,
              parentsErrors: {
                fatherFirstName: "",
                fatherMiddleName: "",
                fatherLastName: "",
                fatherOccupation: '',
                fatherMobileNumber: '',
                fatherEmail: '',
                motherFirstName: '',
                motherMiddleName: '',
                motherLastName: '',
                motherOccupation: '',
                motherMobileNumber: '',
                motherEmail: '',
                maritalStatus: '',
                guardianName: '',
                guardianAddress: '',
                guardianOccupation: '',
                guardianMobileNumber: '',
                guardianEmail: '',
                relationWithStudent: '',
              },
            },
          };
        } else if (activeStep === 4) {
          return {
            fourthStepData: prevState.fourthStepData,
            fifthStepData: {
              ...prevState.fifthStepData,
              fileErrors: {
                letterOfRecommendation: "",
                chrissie: '',
                internationalLetter: '',
                internationalAchevement: '',
                ministry: '',
                hgServant: '',
                financial: '',
                proofIncome: '',
                death: '',
                letterCommittee: '',
                birthStudent: '',
                recentBill: '',
                birthParent: '',
                birthLuovaStaff: '',
                pwdId: '',
                birthCertificate: '',
                reportCard: '',
                schoolRecords: '',
                learnerRefNumberFile: '',
                picture: '',
                soloParentId: '',
                validParentId: '',

              },
            },
          };
        }
        else if (activeStep === 5) {
          return {
            fifthStepData: prevState.fifthStepData,
            reasonForRegistering: {
              ...prevState.reasonForRegistering,
              reasonErrors: {
                reason: ""


              },
            },
          }
        }  else if (activeStep === 6) {
          return true
        }
        return prevState;
      }

      )

    }
  };

  
  handleChangestudentSoloParentId = (e: any) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\d]*$/; 
  
    if (regex.test(value) && value.length <= 15) { 
      this.setState({
     
        firstStepData: { ...this.state.firstStepData, [e.target.name]: value },    
      });
    }
   
  
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentErrors: {
          ...prevState.firstStepData.studentErrors,
          studentSoloParentId: '',
        },
      },
    }));
  }
  

  uploadLetterOfRecommendationFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          letterOfRecomendation: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            letterOfRecomendation: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.letterOfRecomendation = ""
  }

  chrissieUploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          chrissie: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            chrissie: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.chrissie = ""
  }

  internationalLetterUploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          internationalLetter: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            internationalLetter: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.internationalLetter = ""
  }

  internationalAchevementUploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          internationalAchevement: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            internationalAchevement: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.internationalAchevement = ""
  }

  ministryUploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          ministry: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            ministry: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.ministry = ""
  }

  HGServantUploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          hgServant: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            hgServant: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.hgServant = ""
  }
  
  financialuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          financial: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            financial: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.financial = ""
  }

  proofIncomeuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          proofIncome: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            proofIncome: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.proofIncome = ""
  }

  deathuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          death: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            death: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.death = ""
  }

  letterCommitteeuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          letterCommittee: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            letterCommittee: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.letterCommittee = ""
  }

  birthStudent  = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          birthStudent: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            birthStudent: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.birthStudent = ""
  }

  recentBilluploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          recentBill: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            recentBill: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.recentBill = ""
  }

  birthParentuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {
      
      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          birthParent: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            birthParent: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.birthParent = ""
  }

  birthLuovaStaffuploadFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          birthLuovaStaff: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            birthLuovaStaff: '',
          }
        },
      }));
    } else {
      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.birthLuovaStaff = ""
  }

  uploadPWDIDFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          pwdID: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            pwdID: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.pwdID = ""
  }

  uploadBirthCertificateFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          birthCertificate: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            birthCertificate: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.birthCertificate = ""
  }

  uploadReportCardFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          reportCard: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            reportCard: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.reportCard = ""
  }

  uploadSchoolRecordsFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          schoolRecords: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            schoolRecords: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.schoolRecords = ""
  }

  uploadLearnerRefNumberFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          learnerRefNumberFile: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            learnerRefNumberFile: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.learnerRefNumberFile = ""
  }

  uploadPictureFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {      

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          picture: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            picture: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.picture = ""
  }

  uploadSoloParentIdFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          soloParentId: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            soloParentId: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.soloParentId = ""
  }

  uploadValidParentIdFile = (file: File, purpose: string) => {
    if (purpose && purpose.length > 0) {

      this.setState(prevState => ({
        fifthStepData: {
          ...prevState.fifthStepData,
          validParentId: file,
          fileErrors: {
            ...prevState.fifthStepData.fileErrors,
            validParentId: '',
          }
        },
      }));
    } else {

      console.error("No file selected")
    }
    this.state.fifthStepData.fileErrors.validParentId = ""
  }

handleChangemotherEmail = (e: any) => {

  this.setState({
   
    fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

  });
  this.state.fourthStepData.parentsErrors.motherEmail= '';
}



handleChangeguardianEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
  const emailValuess= e.target.value;
  this.setState({
    fourthStepData: { ...this.state.fourthStepData, [e.target.name]: emailValuess },
  });
  this.state.fourthStepData.parentsErrors.guardianEmail = '';



 


}

handleChangeguardianName = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;
  const motherLastNamerejex = /^[A-Za-z\s]+$/;

  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          guardianName: "Guardian's Name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!motherLastNamerejex.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          guardianName: ' Only alphabets  are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          guardianName: '',
        },
      },
    }));
  }
}


  

handleChangefatherOccupationt = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  // Clear error message
  this.setState(prevState => ({
    fourthStepData: {
      ...prevState.fourthStepData,
      parentsErrors: {
        ...prevState.fourthStepData.parentsErrors,
        fatherOccupation: '',
        
      },
    },
  }));

 
  const regexmotherOccupation = /^[A-Za-z\s]+$/;

 
  if (regexmotherOccupation.test(value)) {
  
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
      },
    }));
  }else {
   
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: '',
      },
    }));
  }
}

handleChangemotherOccupation = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  
  this.setState(prevState => ({
    fourthStepData: {
      ...prevState.fourthStepData,
      parentsErrors: {
        ...prevState.fourthStepData.parentsErrors,
        motherOccupation: '',
        
      },
    },
  }));


  const regexmotherOccupation = /^[A-Za-z\s]+$/;

  
  if (regexmotherOccupation.test(value)) {
 
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
      },
    }));
  }else {
   
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: '',
      },
    }));
  }
}


handleChangemotherFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  // Clear error message
  const motherFirstNamerejex = /^[A-Za-z\s]+$/;
  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
       ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
         ...prevState.fourthStepData.parentsErrors,
          motherFirstName: "Mother's First name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!motherFirstNamerejex.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
       ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
         ...prevState.fourthStepData.parentsErrors,
          motherFirstName: 'Only alphabets are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
       ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
         ...prevState.fourthStepData.parentsErrors,
          motherFirstName: '',
        },
      },
    }));
  }
}
handleChangemotherMiddleName = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;
  const motherMiddleNamerejex = /^[A-Za-z\s]+$/;

  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherMiddleName: "Mother's Middle Name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!motherMiddleNamerejex.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherMiddleName: 'Only alphabets are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherMiddleName: '',
        },
      },
    }));
  }
}


handleChangemotherLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;
  const motherLastNamerejex = /^[A-Za-z\s]+$/;

  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherLastName: "Mother's Last Name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!motherLastNamerejex.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherLastName: ' Only alphabets  are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          motherLastName: '',
        },
      },
    }));
  }
}


handleChangeguardianOccupation = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

 
  this.setState(prevState => ({
    fourthStepData: {
      ...prevState.fourthStepData,
      parentsErrors: {
        ...prevState.fourthStepData.parentsErrors,
        guardianOccupation: '',
        
      },
    },
  }));

  
  const regexGurdianguardianOccupation = /^[A-Za-z\s\/]+$/;

 
  if (regexGurdianguardianOccupation.test(value)) {
 
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
      },
    }));
  }else {
   
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: '',
      },
    }));
  }
}
  onCountryCodeSelectedWeb = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.target;

    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: `+${value}`,
      },
    }));
  }
handleChangefatherEmail = (e: any) => {
   this.setState({
   
    fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

  });
  this.state.fourthStepData.parentsErrors.fatherEmail= '';

}
  
 
handleChangefatherMiddleName = (e: any) => {
  const { name, value } = e.target;
  const regexLastGurdianguardianOccupationfatherMiddleName = /^[A-Za-z\s]+$/;

  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherMiddleName: "Father's Middle Name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!regexLastGurdianguardianOccupationfatherMiddleName.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherMiddleName: ' Only alphabets  are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherMiddleName: '',
        },
      },
    }));
  }
}
handleChangefatherLastName = (e: any) => {
  const { name, value } = e.target;
  const regexLastGurdianguardianOccupationfatherLastName = /^[A-Za-z\s]+$/;

  if (value.length > 25) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherLastName: "Father's Last Name should not exceed 25 characters.",
        },
      },
    }));
  } else if (!regexLastGurdianguardianOccupationfatherLastName.test(value)) {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherLastName: 'Only alphabets are allowed.',
        },
      },
    }));
  } else {
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        [name]: value,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          fatherLastName: '',
        },
      },
    }));
  }
}

  handleChangeguardianAddress = (e: any) => {

    const grusrsAddree= e.target.value
  

  this.setState({fourthStepData: { ...this.state.fourthStepData, [e.target.name]: grusrsAddree}});

this.state.fourthStepData.parentsErrors.guardianAddress = '';
  }
  handleChangefatherFirstName = (e: any) => {
    const { name, value } = e.target;
    const fatherFirstNamer = /^[A-Za-z\s]+$/;
  
    if (value.length > 25) {
      this.setState(prevState => ({
        fourthStepData: {
         ...prevState.fourthStepData,
          [name]: value,
          parentsErrors: {
           ...prevState.fourthStepData.parentsErrors,
            fatherFirstName: "Father's First Name should not exceed 25 characters.",
          },
        },
      }));
    } else if (!fatherFirstNamer.test(value)) {
      this.setState(prevState => ({
        fourthStepData: {
         ...prevState.fourthStepData,
          [name]: value,
          parentsErrors: {
           ...prevState.fourthStepData.parentsErrors,
            fatherFirstName: ' Only alphabets  are allowed.',
          },
        },
      }));
    } else {
      this.setState(prevState => ({
        fourthStepData: {
         ...prevState.fourthStepData,
          [name]: value,
          parentsErrors: {
           ...prevState.fourthStepData.parentsErrors,
            fatherFirstName: '',
          },
        },
      }));
    }
  }
  handleChangelearnerRefNumber = (e: any) => {
    const value = e.target.value;
    const regex = /^\d*$/; // Allow digits 0-9
  
    if (regex.test(value) && value.length <= 12) { // Check if the value contains only digits and is at most 15 characters long
      this.setState({
        secondStepData: { ...this.state.secondStepData, [e.target.name]: value }
      });
    }
    this.setState(prevState => ({
      secondStepData: {
        ...prevState.secondStepData,
        studentErrors: {
          ...prevState.secondStepData.studentErrors,
          learnerRefNumber: '',
        },
      },
    }));
  
  }

  handleChangeprevSchoolEmail = (e: any) => {
    const emailValue = e.target.value;
    this.setState({
      secondStepData: { ...this.state.secondStepData, [e.target.name]: emailValue },
    });
    this.state.secondStepData.studentErrors.prevSchoolEmail = '';
  }
  handleChangeprevSchoolYear = (e:any) => {
    const valuedddd = e.target.value;

   
        this.setState({
            secondStepData: { ...this.state.secondStepData, prevSchoolYear: valuedddd }
        });
    
        this.state.secondStepData.studentErrors.prevSchoolYear = ""
}
  
  
handleChangestudentAdvocatecode = (e: any) => {
  const advocateEnter = e.target.value;
  const studentErrors = { ...this.state.firstStepData.studentErrors };

  // Validate the input
  const errorMessage = this.validateNumberAdcovate(advocateEnter);
  if (errorMessage) {
    studentErrors.studentAdvocatecode = errorMessage;
  } else {
    studentErrors.studentAdvocatecode = "";
  }

  // Update the state
  this.setState({
    firstStepData: {
      ...this.state.firstStepData,
      [e.target.name]: advocateEnter,
      studentErrors: studentErrors,
    },
  });
}
  handleChangeprevSchoolNumber = (e: any) => {
   
    const numberRegex = /^\d*$/;


    if (numberRegex.test(e.target.value)) {
        this.setState({
            secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
         
          
        });
    } else {
      
        console.log('Invalid phone number format');
       
    }
    this.state.secondStepData.studentErrors.prevSchoolNumber = ""
}


  handleChangeprevSchoolAddress = (e: any) => {

    this.setState({
      firstStepData: { ...this.state.firstStepData, [e.target.name]: e.target.value },
      secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
      fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

    });
    this.state.secondStepData.studentErrors.prevSchoolAddress = '';
  }
  
 
  handleChangeText1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    // Clear error message
 
  
    const regexFirstName = /^[A-Za-z\s]+$/;
  
    if (value.length > 25) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
[name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentFirstName: 'First name cannot exceed 25 characters',
          },
        },
      }));
    } else if (!regexFirstName.test(value)) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
   [name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentFirstName: 'First name can only contain letters and spaces',
          },
        },
      }));
    } else {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          [name]: value,
studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentFirstName: '',
          },
        },
      }));
    }
  }
  

  handleChangeprevSchoolAttended = (e: any) => {
    if (!e || !e.target) {
     
      console.error("Event object or event target is null or undefined.");
      return;
    }

    const value = e.target.value; 
    const name = e.target.name;

    this.setState((prevState) => ({
      firstStepData: { ...prevState.firstStepData, [name]: value },
      secondStepData: {
        ...prevState.secondStepData,
        [name]: value,
        studentErrors: {
          ...prevState.secondStepData.studentErrors,
          prevSchoolAttended: value ? '' : 'Please input the previous school attended.',
        }
      },
      fourthStepData: { ...prevState.fourthStepData, [name]: value },
    }));
  };


  residentialAddressChange = (e: any) => {
    const { name, value } = e.target;
    const errorMessage = value.length > 75 ? "Address cannot exceed 75 characters." : "";
    this.setState({
      firstStepData: { ...this.state.firstStepData, [name]: value },
      secondStepData: { ...this.state.secondStepData, [name]: value },
      fourthStepData: { ...this.state.fourthStepData, [name]: value }
    });
    
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentErrors: {
          ...prevState.firstStepData.studentErrors,
          studentResidentialAddress: errorMessage,
        }
      }
    }));
  }

  handleChangeText2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    const regexFirstName = /^[A-Za-z\s]+$/;
  
    if (value.length > 25) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
[name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
           studentMiddleName:' Middle name cannot exceed 25 characters',
          },
        },
      }));
    } else if (!regexFirstName.test(value)) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
   [name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentMiddleName: 'Middle name can only contain letters and spaces',
          },
        },
      }));
    } else {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          [name]: value,
            studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentMiddleName: '',
          },
        },
      }));
    }
  }
  handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const regexFirstName = /^[A-Za-z\s]+$/;
    if (value.length > 25) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
[name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentLastName: 'Last name cannot exceed 25 characters',
          },
        },
      }));
    } else if (!regexFirstName.test(value)) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
   [name]: value,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentLastName: 'Last name can only contain letters and spaces',
          },
        },
      }));
    } else {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          [name]: value,
studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentLastName: '',
          },
        },
      }));
    }
  }
  
  handleChangerelationWithStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        parentsErrors: {
          ...prevState.fourthStepData.parentsErrors,
          relationWithStudent: '',
        },
      },
    }));
  
  
    const regexLastNamerelationWithStudent = /^[A-Za-z\s\/]+$/;
  
   
    if (regexLastNamerelationWithStudent.test(value)) {
     
      this.setState(prevState => ({
        fourthStepData: {
          ...prevState.fourthStepData,
          [name]: value,
        },
      }));
    }else {
     
      this.setState(prevState => ({
        fourthStepData: {
          ...prevState.fourthStepData,
          [name]: '',
        },
      }));
    }
  }
  handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*$/;
    const phonetargetEvent = e?.target.value
    if(re.test(phonetargetEvent)){
      this.setState({
      
        fourthStepData: { ...this.state.fourthStepData, [e.target?.name]:phonetargetEvent },
  
      });
    }
   
    this.state.fourthStepData.parentsErrors.motherMobileNumber = '';
    
    if (e?.target?.name === 'prevSchoolNumber') {
      if (re.test(e.target.value)) {
        this.handlePrevSchoolnumber(e)
      }
    }
    if (e?.target?.name === 'fatherMobileNumber') {
      if (re.test(e.target.value)) {
        this.handleFatherMoobileNumber(e)
      }
    }
    if (e?.target?.name === 'motherMobileNumber') {
      if (re.test(e.target.value)) {
        this.handleMotherMobileNumber(e)
      }
    }
    if (e?.target?.name === 'guardianMobileNumber') {
      if (re.test(e.target.value)) {
        this.handleguardianMobileNumber(e)
      }
    }
  }
  handleInputswitch(e: React.ChangeEvent<HTMLInputElement>) {
    const re = /^[0-9\b]+$/; // Regex pattern for numbers
    
    switch (e?.target?.name) {
      case 'prevSchoolNumber':
        if (re.test(e.target.value)) {
          this.handlePrevSchoolnumber(e);
        }
        break;
      case 'fatherMobileNumber':
        if (re.test(e.target.value)) {
          this.handleFatherMoobileNumber(e);
        }
        break;
      case 'motherMobileNumber':
        if (re.test(e.target.value)) {
          this.handleMotherMobileNumber(e);
        }
        break;
      case 'guardianMobileNumber':
        if (re.test(e.target.value)) {
          this.handleguardianMobileNumber(e);
        }
        break;
      default:
        
    }
  }
  handlePhoneNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*$/;
    const phonetargetEvent = e?.target.value
    if(re.test(phonetargetEvent)){
      this.setState({fourthStepData: { ...this.state.fourthStepData, [e.target?.name]:phonetargetEvent } });
    }
   
    this.state.fourthStepData.parentsErrors.fatherMobileNumber = '';
    
  this.handleInputswitch(e)
  }
  handlePhoneNumberss = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*$/;
    const phonetargetEvent = e?.target.value
    if(re.test(phonetargetEvent)){
      this.setState({
      
        fourthStepData: { ...this.state.fourthStepData, [e.target?.name]:phonetargetEvent },
  
      });
    }
   
    this.state.fourthStepData.parentsErrors.guardianMobileNumber = '';
    
    this.handleInputswitch(e)
  }

  handlePrevSchoolnumber = (e: any) => {
    this.setState((prevState) => ({ secondStepData: { ...prevState.secondStepData, [e.target?.name]: e.target?.value } }))
  }
  handleFatherMoobileNumber = (e: any) => {
    this.setState((prevState) => ({ fourthStepData: { ...prevState.fourthStepData, [e.target?.name]: e.target?.value } }))
  }
  handleMotherMobileNumber = (e: any) => {
    this.setState((prevState) => ({ fourthStepData: { ...prevState.fourthStepData, [e.target?.name]: e.target?.value } }))
  }
  handleguardianMobileNumber = (e: any) => {
    this.setState((prevState) => ({ fourthStepData: { ...prevState.fourthStepData, [e.target?.name]: e.target?.value } }))
  }
  isValidPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length >= 6 && phoneNumber.length <= 11) {
      return true;
    }
    else {
      return false;
    }
  }
  hideError = (event: any) => {

    this.state.firstStepData.studentErrors[event.target.name] = '';
    this.state.secondStepData.prevSchoolErrors[event.target.name] = '';
    this.state.thirdStepData.programErrors[event.target.name] = '';
    this.state.fourthStepData.parentsErrors[event.target.name] = '';
    this.state.fifthStepData.fileErrors[event.target.name] = '';
    this.state.reasonForRegistering.reasonErrors[event.target.name] = '';
    this.setState({
      firstStepData: { studentErrors: this.state.firstStepData.studentErrors },
      secondStepData: { ...this.state.secondStepData, prevSchoolErrors: this.state.secondStepData.prevSchoolErrors },
      thirdStepData: { ...this.state.thirdStepData, programErrors: this.state.thirdStepData.programErrors },
      fourthStepData: { ...this.state.fourthStepData, parentsErrors: this.state.fourthStepData.parentsErrors },
      fifthStepData: { ...this.state.fifthStepData, fileErrors: this.state.fifthStepData.fileErrors },
      reasonForRegistering: { ...this.state.reasonForRegistering, reasonErrors: this.state.reasonForRegistering.reasonErrors }
    })
  };

  genderChange = (e: any) => {
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        [e.target.name]: e.target.value,
        studentErrors: {
          ...prevState.firstStepData.studentErrors,
          studentGender: "", 
        }
      }
    }));
  };


  maritalStatusChange = (e: any) => {
    this.setState((prevState) => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        maritalStatus: e.target.value,
      }
    }))
    this.state.fourthStepData.parentsErrors.maritalStatus = ""
  }

  dateFunction = () => {
    let today: any = new Date();
    let yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
  let dd: any = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  datechange = (e: any) => {
    const selectedDate = e.target.value;
    const maxDate = this.dateFunction();
    const data = selectedDate;
    const dob = new Date(data);
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);
    const calc = Math.abs(age_dt.getUTCFullYear() - 1970);
    const minData = "1970-01-01"

    if (selectedDate <= maxDate) {
      this.setState(prevState => ({
        firstStepData: {       
          ...prevState.firstStepData,
          studentAge: calc,
          studentDOB: selectedDate,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentDOB: "",
            studentAge: ""
        }

        }
    
      }));
    } else {
      e.target.value = maxDate;
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          studentAge: 0,
          studentDOB: maxDate,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentDOB: "Date cannot be in the future.",
            studentAge: ""
          }
        }
      }));
    }

    if (selectedDate < minData) {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          studentAge: 0,
          studentDOB: selectedDate,
          studentErrors: {
            ...prevState.firstStepData.studentErrors,
            studentDOB: "Date cannot be earlier than 1970.",
            studentAge: ""
          }
        }
      }));
    }

  }


  handleGetOptionLabel = (option: any) => {
    return (option.name || "")
  }

  handleGetOptionLabelLanguage = (option: any) => {
    return (option.language || "")
  }

  handleGetOptionSelected = (option: any, value: any) => {
    return (option.value === value)
  }

  handleRenderInput1 = (params: any) => {
    return (
      < StyledTextField {...params} placeholder='Select Country' fullWidth={true}
        InputLabelProps={{ shrink: false }} />)
  }
  handleRenderInput2 = (params: any) => {
    return (
      < StyledTextField {...params} placeholder='Select City' fullWidth={true}
        InputLabelProps={{ shrink: false }} />)
  }
  handleRenderInput3 = (params: any) => {
    return (
      < StyledTextField {...params} placeholder='Please Select' fullWidth={true}
        InputLabelProps={{ shrink: false }} />)
  }



  religionDataChange = (e: React.ChangeEvent<HTMLInputElement>) => 
  {
    this.state.firstStepData.studentErrors.studentReligion = ""
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          studentReligion: {
            ...prevState.firstStepData.studentReligion,
            value: e.target.value,
            id: this.state.religionData.find((o: any) => o.attributes.religion === e.target.value)?.id
          }
        }
      }))
   
  };


  nationalityDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentNationality: {
          ...prevState.firstStepData.studentNationality,
          value: e.target.value,
          id: this.state.nationalitiesData.find((o: any) => o.attributes.name === e.target.value)?.id
        }
      }
    }))
    this.state.firstStepData.studentErrors.studentNationality = ""
  }

 handleChildFirstLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState(prevState => ({
    firstStepData: {
      ...prevState.firstStepData,
      studentChildsFirstLanguage: {
        ...prevState.firstStepData.studentChildsFirstLanguage,
        value: e.target.value,
        id: this.state.languagesData.find((o: any) => o.attributes.language === e.target.value)?.id
      }
    }
  }))
  this.state.firstStepData.studentErrors.studentChildsFirstLanguage = ""
}

  handelGlavel = (value: any) => {
    this.setState(prevState => ({
      secondStepData: {
        ...prevState.secondStepData,
        prevGradeLevel: {
          ...prevState.secondStepData.prevGradeLevel,
          value: value,
          id: this.state.gradeData.find((o: any) => o.attributes.grade === value)?.id
        }
      }
    }))
  }

  handelGLavel2 = (val: any) => {
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        incomingGradeLevel: {
          ...prevState.thirdStepData.incomingGradeLevel,
          value: val,
          id: this.state.gradeData.find((o: { id: string, type: string, attributes: { grade: string; }; }) => o.attributes.grade == val).id
        }
      }
    }))
  }
  handleGradeLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      secondStepData: {
        ...prevState.secondStepData,
        prevGradeLevel: {
          ...prevState.secondStepData.prevGradeLevel,
          value: e.target.value,
          id: this.state.gradeData.find((o: any) => o.attributes.grade === e.target.value)?.id
        }
      }
    }))
    this.state.secondStepData.studentErrors.prevGradeLevel = ""
    if (e.target.name == 'prevGradeLevel') {
      this.handelGlavel(e.target.value)



    }

    if (e.target.name == 'incomingGradeLevel') {
      this.handelGLavel2(e.target.value)
      this.handleState()
    }
  }


  handleState = () => {
    this.state.thirdStepData.programErrors['incomingGradeLevel'] = ''
  }
  handleCurriculumData = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      secondStepData: {
        ...prevState.secondStepData,
        prevSchoolCurriculum: {
          ...prevState.secondStepData.prevSchoolCurriculum,
          value: e.target.value,
          id: this.state.curriculumData.find((o: any) => o.attributes.curriculum_name === e.target.value)?.id
        }
      }
    }))
    this.state.secondStepData.studentErrors.prevSchoolCurriculum = ""
  }
 
  handleCheckedStudentScholarShip = (e: any) => {
     const studentErrors = { ...this.state.firstStepData.studentErrors };
    if (!e.target.checked) {
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          studentScholarShipType:  {
            ...this.state.firstStepData.studentScholarShipType,
              value: '',
              id: ''
          },
          studentTypesOfScholarShip: {
            ...this.state.firstStepData.studentTypesOfScholarShip,
              value: '',
              id: ''
          },
          studentSpecification:{
            ...this.state.firstStepData.studentSpecification,
            value: '',
            id: ''
          },
          studentProofOfRelationship:{
            ...this.state.firstStepData.studentProofOfRelationship,
            value: '',
            id: ''
          },
          studentWouldYouLikeToApplyForAScholarShip: false,
          studentCauseOfDeath: '',
          studentErrors: {
            ...this.state.firstStepData.studentErrors,
            studentTypesOfScholarShip: '',
            studentScholarShipType: '',
            studentProofOfRelationship: '',
            studentfatherDeath: '',
            studentCauseOfDeath: '',
            studentSpecification: ''
          },
        }
      })
    }
    
    if (e.target.checked) {
    this.setState({
      firstStepData: {
        ...this.state.firstStepData,
        [e.target.name]: e.target.checked,
        studentErrors: studentErrors,
        studentScholarShipType:  {
          ...this.state.firstStepData.studentScholarShipType,
            value: 'choose',
            id: ''
        },
        studentTypesOfScholarShip: {
          ...this.state.firstStepData.studentTypesOfScholarShip,
            value: 'choose',
            id: ''
        },
        studentSpecification:{
          ...this.state.firstStepData.studentSpecification,
          value: 'choose',
          id: ''
        },
        studentProofOfRelationship:{
          ...this.state.firstStepData.studentProofOfRelationship,
          value: 'choose',
          id: ''
        },
        studentCauseOfDeath: 'choose',
        studentfatherDeath: ''
      }
    });
  }
  }
  
  handleChangestudentPwdIdNumber = (e: any) => {
    const repsnums = /^\d*$/;
    const { value, name } = e.target;
  
    if (repsnums.test(value) && value.length <= 12) { 
      this.setState({
     
        firstStepData: { ...this.state.firstStepData, [name]: value },
    
      });
    }
  
    

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentErrors: {
          ...prevState.firstStepData.studentErrors,
          studentPwdIdNumber: '',
        },
      },
    }));  }


  handleChangeScholarshipType = (e: any) => {
    
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentScholarShipType: e?.target.value
      }
    }))
    this.state.firstStepData.studentErrors['studentScholarShipType'] = " "
  }

  scholarshipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentTypesOfScholarShip: {
          ...prevState.firstStepData.studentTypesOfScholarShip,
          value: e.target.value,
          id: this.state.scholarshipSelectedData.find((o: any) => o.scholarship_name == e.target.value)?.id
        }
      }
    }))
    this.state.firstStepData.studentErrors['studentTypesOfScholarShip'] = " "

    if (e.target.value === 'The Ministry Leaders Assistance') {
      this.setState(prevState => ({
        firstStepData: {
          ...prevState.firstStepData,
          programDetailsProgram: {
          
            value: 'choose',
            id: '',
          }
        }
      }))
    }

    if (e.target.value !== 'Scholarship for HG Servant Leaders') {
      this.setState(prevState => ({
        firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
                ...prevState.firstStepData.studentErrors,
                studentSpecification: '',
            },
        },
    }));
    }
    if (e.target.value !== "HG Staff Student Scholarship") {
      this.setState(prevState => ({
        firstStepData: {
            ...prevState.firstStepData,
            studentErrors: {
                ...prevState.firstStepData.studentErrors,
                studentProofOfRelationship : ''
            },
        },
    }));
    }
  }

  handleProofOfRelationship = (e: any) => {
    this.setState({
      firstStepData: {
        ...this.state.firstStepData, studentProofOfRelationship: e.target.value, studentfatherDeath: "", studentSpecification: 'choose', studentCauseOfDeath: 'choose'
      }
    })
    this.state.firstStepData.studentErrors['studentProofOfRelationship'] = " "
  }

  handlestudentSpecification = (e: any) => {
    this.setState({
      firstStepData: {
        ...this.state.firstStepData, studentSpecification: e.target.value, studentfatherDeath: "", studentProofOfRelationship: "choose", studentCauseOfDeath: 'choose'
      }
    })
    this.state.firstStepData.studentErrors['studentSpecification'] = " "
  }
  handleClearFatherDeath = (evnt: any) => {
    const today : Date = this.dateFunction()
    if(evnt.target.value > today) {
      this.state.firstStepData.studentErrors['studentfatherDeath'] = "Death date cannot be in future."
    }
    else {
      this.state.firstStepData.studentErrors['studentfatherDeath'] = " "
    }
    this.setState({
      firstStepData: {
        ...this.state.firstStepData, studentfatherDeath: evnt.target.value, studentProofOfRelationship: "choose", studentSpecification: 'choose'
      }
    })
}
  handleStudentCauseOfDeath = (e: any) => {
    this.setState({
      firstStepData: {
        ...this.state.firstStepData, studentCauseOfDeath: e.target.value, studentProofOfRelationship: "choose", studentSpecification: 'choose'
      }
    })
    this.state.firstStepData.studentErrors['studentCauseOfDeath'] = " "
  }
  handleAdvocatecode = (e: any) => {
    const studentErrors = { ...this.state.firstStepData.studentErrors };
    if (!e.target.checked) {
      studentErrors.studentAdvocatecode = ''; // Clear the error message
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          studentAdvocatecode: '',
          studentReferralProgram: false
        }
      })
    };
    if (e.target.checked) {
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          [e.target.name]: e.target.checked,
          studentErrors: studentErrors,
        }
      });
  }
  }
 

  handlePwdDiscount = (e: any) => {
    const studentErrorsss = { ...this.state.firstStepData.studentErrors };
    if (e.target.checked) {
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          [e.target.name]: e.target.checked,
          studentErrors: studentErrorsss,
        }
      });
    }

    if (!e.target.checked) {
      studentErrorsss.studentPwd = ''; // Clear the error message
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          studentPwdIdNumber: '',
          studentPwd: false
        }
      })
    }
  }
  handleParentDiscount = (e: any) => {
    const studentErrors = { ...this.state.firstStepData.studentErrors };
    if (!e.target.checked) {
      studentErrors.studentParentDiscount = ''; // Clear the error message
      this.setState({
        firstStepData: {
          ...this.state.firstStepData,
          studentSoloParentId: '',
          studentParentDiscount: false
        }
      })
    }
    if (e.target.checked) {
      this.setState({
        firstStepData: {
          ...this.state.firstStepData, [e.target.name]: e.target.checked,
        }
      })
    }
  }

  changeHandlerAutoGetCountriesBirth = (newValue: any) => {
    this.setState((prevState: any) => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentCountryAutoCompleteValue: {
          value: newValue,
        }
      }
    }))

  }
  changeHandlerAutoGetCountriesBirthCallBack = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    this.state.firstStepData.studentErrors.studentCountry = ""
      this.setState({
        firstStepData: { ...this.state.firstStepData, [e.target.name]: e.target.value },
        secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
        fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

      })
    this.changeHandlerAutoGetCountriesBirth(newValue)
  }

  changeHandlerGetCountries = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentCountry: {
          ...prevState.firstStepData.studentCountry,
          value: newInputValue,
          id: newInputValue,
        }
      }
    }))
    this.state.firstStepData.studentErrors['studentCountry'] = "";

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentBirthCity: {
          ...prevState.firstStepData.studentBirthCity,
          value: "",
          id: ""
        }
      }
    }))

    this.setState({ barangayDataBirth: [] })
    this.state.firstStepData.studentErrors['barangayBirth'] = " "

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        barangayBirth: {
          ...prevState.firstStepData.barangayBirth,
          value: "choose",
          id: ""
        }
      }
    }))

    this.state.getAllCountries && this.state.getAllCountries?.filter(async (item: any) => {
      if (newInputValue) {
        if (item.name == newInputValue) {
          let data: any = [];
          let dataMsg: any = [{ name: 'No City found' }]
          const key = 'name';
          if (newInputValue == 'Philippines') {
            this.state.provincesData?.map((city: any) => {
              data?.push({
                name: city,
              })
            })
          }
          else {
            await City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
              data?.push({
                name: city?.name,
              })
            })
          }
          const arrayUniqueByKey = Array.from(new Map(data?.map((item: any) => [item[key], item])).values());

          if (data.length == 0) {
            this.setState({ getAllCities: dataMsg })
          }
          else {
            this.setState({ getAllCities: arrayUniqueByKey })
          }
        }
      }
      else {
        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData, studentBirthAutoCompleteValue: { id: "", value: "" }
          }
        }))

        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData,
            studentBirthCity: {
              ...prevState.firstStepData.studentBirthCity,
              value: "",
              id: ""
            }
          }
        }))
        this.setState({ getAllCities: [] })
      }
    })

  }

  changeHandlerGetCountriesCallBack = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.changeHandlerGetCountries(e, newInputValue)
  }

  changeHandlerAutoGetCitiesBirth = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {

    this.setState((prevState: any) => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentBirthAutoCompleteValue: {
          value: newValue,
          id: newValue,
        }
      }
    }))
  }

  changeHandlerAutoGetCitiesBirthCallBack = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    this.state.firstStepData.studentErrors.studentBirthCity = '';
    this.setState({
      firstStepData: { ...this.state.firstStepData, [e.target.name]: e.target.value },
      secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
      fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

    });
    this.changeHandlerAutoGetCitiesBirth(e, newValue)
  }

  changeResidenceAutoGetCountriesBirth = (newValue: any) => {
    this.setState((prevState: any) => ({
      firstStepData: {
        ...prevState.firstStepData,
        countryResiAutoCompleteValue: {
          value: newValue,
        }
      }
    }))
  }

  changeResidenceAutoGetCountriesBirthCallBack = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.state.firstStepData.studentErrors.studentCountryOfResidence = '';
    this.setState({
      firstStepData: { ...this.state.firstStepData, [e.target.name]: e.target.value },
      secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
      fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

    });
    this.changeResidenceAutoGetCountriesBirth(newInputValue)
  }

  changeResidenceGetCountries = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentCountryOfResidence: {
          ...prevState.firstStepData.studentCountryOfResidence,
          value: newInputValue,
          id: newInputValue,
        }
      }
    }))
    this.state.firstStepData.studentErrors['studentCountryOfResidence'] = " ";

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentCityOfResidence: {
          ...prevState.firstStepData.studentCityOfResidence,
          value: "",
          id: ""
        }
      }
    }))

    this.state.getResidenceCountries && this.state.getResidenceCountries?.filter(async (item: any) => {
      if (newInputValue) {
        if (item.name == newInputValue) {
          let data: any = [];
          let dataMsg: any = [{ name: 'No City found' }]
          const key = 'name';

          await City.getCitiesOfCountry(item?.isoCode)?.map((city: any) => {
            data?.push({
              name: city?.name,
            })
          })

          const arrayUniqueByKey = Array.from(new Map(data?.map((item: any) => [item[key], item])).values());

          if (data.length == 0) {
            this.setState({ getResidenceCities: dataMsg })
          }
          else {
            this.setState({ getResidenceCities: arrayUniqueByKey })
          }
        }
      }
      else {
        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData, cityResiAutoCompleteValue: { value: "" }
          }
        }))

        this.setState(prevState => ({
          firstStepData: {
            ...prevState.firstStepData,
            studentCityOfResidence: {
              ...prevState.firstStepData.studentCityOfResidence,
              value: "",
              id: ""
            }
          }
        }))
        this.setState({ getResidenceCities: [] })
      }
    })

  }

  changeResidenceGetCountriesCallBack = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.changeResidenceGetCountries(e, newInputValue)
  }

  changeResidenceAutoGetCitiesBirth = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {

    this.setState((prevState: any) => ({
      firstStepData: {
        ...prevState.firstStepData,
        cityResiAutoCompleteValue: {
          value: newValue,
        }
      }
    }))
  }

  changeResidenceAutoGetCitiesBirthCallBack = (e: React.ChangeEvent<HTMLInputElement>, newValue: any) => {

    this.setState({
      firstStepData: { ...this.state.firstStepData, [e.target.name]: e.target.value },
      secondStepData: { ...this.state.secondStepData, [e.target.name]: e.target.value },
      fourthStepData: { ...this.state.fourthStepData, [e.target.name]: e.target.value },

    });
    this.state.firstStepData.studentErrors.studentCityOfResidence = '';
    this.changeResidenceAutoGetCitiesBirth(e, newValue)
  }

  handleCycleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        cycle: {
          value: e.target.value,
        }
      }
    }))
    this.state.thirdStepData.programErrors['cycle'] = ' ';
  }

  programDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        program: {
          ...prevState.thirdStepData.program,
          value: e.target.value,
          id: this.state.programData.find((o: any) => o.attributes.program_name == e.target.value)?.id
        }
      }
    }))
    this.state.thirdStepData.programErrors.program = " "
    this.studentsViewDeatilsByID(e.target.value)
  };

  communityDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        community: {
          ...prevState.thirdStepData.community,
          value: e.target.value,
          id: this.state.communityData.find((o: any) => o.attributes.community_name == e.target.value)?.id
        }
      }
    }))
    this.state.thirdStepData.programErrors['community'] = " "
  };

  hubDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        hub: {
          ...prevState.thirdStepData.hub,
          value: e.target.value,
          id: this.state.hubData.find((o: any) => o.attributes.name == e.target.value)?.id
        }
      }
    }))
    this.state.thirdStepData.programErrors['hub'] = " "
  };

  accreditationDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        accreditation: {
          ...prevState.thirdStepData.accreditation,
          value: e.target.value,
          id: this.state.accreditationData.find((o: Accreditation) => o.attributes.name == e.target.value)?.id,
        },
        shsTrack: {
          ...prevState.thirdStepData.shsTrack,
          value: '',
        },
      }
    }))
    this.state.thirdStepData.programErrors['accreditation'] = " "
  };

  shsTrackChange = (e: any) => {
    
    const newValue = e.target.value;
    this.setState(prevState => ({
      thirdStepData: {
        ...prevState.thirdStepData,
        shsTrack: {
          ...prevState.thirdStepData.shsTrack,
          value: newValue,
        },
      },
    }))
    this.state.thirdStepData.programErrors.shsTrack = '';

  };

  reasonDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      reasonForRegistering: {
        ...prevState.reasonForRegistering,
        reason: {
           value: e.target.value ,
           id: this.state.reasonData.find((o: any) => o.attributes.reason == e.target.value)?.id
          }
      }
    }))
    this.state.reasonForRegistering.reasonErrors.reason = " "
  }



  handleCityBirth = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {
    this.setState({ barangayDataBirth: [] })
    this.state.firstStepData.studentErrors['barangayBirth'] = " "

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        barangayBirth: {
          ...prevState.firstStepData.barangayBirth,
          value: "choose",
          id: ""
        }
      }
    }))

    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentBirthCity: {
          ...prevState.firstStepData.studentBirthCity,
          value: newInputValue,
          id: this.state.getAllCities.find((o: any) => o.name == newInputValue)?.id
        }
      }
    }), () => { this.getBarangaysDataBirthApiCall(this.state.firstStepData?.studentBirthCity?.value) })

    this.state.firstStepData.studentErrors['studentBirthCity'] = " "
  }
  handleCityBirthCallBack = (e: any, newInputValue: any) => {
    this.handleCityBirth(e, newInputValue)
  }

  handleResidenceCity = (e: React.ChangeEvent<HTMLInputElement>, newInputValue: any) => {


    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        studentCityOfResidence: {
          ...prevState.firstStepData.studentCityOfResidence,
          value: newInputValue,
          id: this.state.getResidenceCities.find((o: any) => o.name == newInputValue)?.id
        }
      }
    }))

    this.state.firstStepData.studentErrors['studentCityOfResidence'] = " "
  }

  handleResidenceCityCallBack = (e: any, newInputValue: any) => {
    this.handleResidenceCity(e, newInputValue)
  }

  barangayBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      firstStepData: {
        ...prevState.firstStepData,
        barangayBirth: {
          ...prevState.firstStepData.barangayBirth,
          value: e.target.value,
          id: this.state.barangayDataBirth.find((o: any) => o?.attributes?.barangay_name == e.target.value)?.id,
        }
      }
    }))

    this.state.firstStepData.studentErrors['barangayBirth'] = " "
  }
  barangayBirthChangeCallBack = (e: React.ChangeEvent<HTMLInputElement>) => this.barangayBirthChange(e)

  sortPhoneCode = (itemA: ICountryData, itemB: ICountryData) => {
    return itemA.phoneCode - itemB.phoneCode
  }

  getAllCountriesData = () => {
    Country.getAllCountries().map((item: any) => {
      this.state.getAllCountries.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode: item?.isoCode
      })
    })

    const countriesResult: ICountryData[] = worldMapData.getAllCountries()
    const sorted = [...countriesResult].sort(this.sortPhoneCode)
    const phoneCodelistItem: IDItem[] = sorted.map((item) => {
      return {
        label: item.sortname,
        value: item.phoneCode.toString(),
      }
    })
   
    this.setState(prevState => ({
      fourthStepData: {
        ...prevState.fourthStepData,
        countryPhoneCodeListOne: phoneCodelistItem,
      },
    }));
  }

  getResidenceCountriesData = () => {
    Country.getAllCountries().map((item: any) => {
      this.state.getResidenceCountries.push({
        name: item?.name,
        phonecode: item?.phonecode,
        isoCode: item?.isoCode
      })
    })

  }

  getImgLoaderOrText = () => {
    let loadingText = this.state.isLoading ? <CircularProgress /> : 'Submit';
    return this.state.activeStep > 5 ? loadingText : 'Next'
  }

  sortTermsAndCondition = (a: any, b: any) => { return (a.id - b.id) }

  uploadFiles = (file: any, purpose: any) => {
    //istanbul ignore next
    switch (purpose) {
      case 'letterOfRecomendation':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, letterOfRecomendation: file } })
        break;
      case 'pwdID':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, pwdID: file } })
        break;
      case 'birthCertificate':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, birthCertificate: file } })
        break;
      case 'reportCard':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, reportCard: file } })
        break;
      case 'schoolRecords':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, schoolRecords: file } })
        break;
      case 'learnerRefNumberFile':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, learnerRefNumberFile: file } })
        break;
      case 'picture':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, picture: file } })
        break;
      case 'soloParentId':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, soloParentId: file } })
        break;
      case 'validParentId':
        this.setState({ fifthStepData: { ...this.state.fifthStepData, validParentId: file } })
        break;
    }

  }
  generalTAndC = (e: any) => {
    this.setState((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
  }
  refundPoli = (e: any) => {
    this.setState((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }))
  }
  privacyPoli = (e: any) => {
    this.setState((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }))
  }
  pictureAuth = (e: any) => {
    this.setState((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }))
  }
  handlegeneralTermsAndConditions = (e: any) => {
    const isChecked = e.target.checked;
    this.setState({ generalTermsAndConditions: isChecked });
  };
  handleCheckBoxrefundPolicy = (e: any) => {
    const isChecked = e.target.checked;
    this.setState({ refundPolicy: isChecked });
  };
  handleCheckBoxprivacyPolicy = (e: any) => {
    const isChecked = e.target.checked;
    this.setState({ privacyPolicy: isChecked });
  };

  handleCheckBoxpictureAuthorisation = (e: any) => {
    const isChecked = e.target.checked;
    this.setState({ pictureAuthorisation: isChecked });
  };
  handleCheckBox = (e: any) => {


    if (e.target.name === 'refundPolicy') {
      this.refundPoli(e.target?.name)
    }
    if (e.target.name === 'privacyPolicy') {
      this.privacyPoli(e.target?.name)
    }
    if (e.target.name === 'pictureAuthorisation') {
      this.pictureAuth(e.target.name)
    }
  }

  commanFile = (formdata: FormData) => {
    // comman
    if(this.state.fifthStepData.birthCertificate){
      formdata.append("student[psa_birth_certificate]", this.state.fifthStepData.birthCertificate)
    }
    if(this.state.fifthStepData.reportCard){
      formdata.append("student[recent_report_card]", this.state.fifthStepData.reportCard)
    }
    if(this.state.fifthStepData.schoolRecords){
      formdata.append("student[school_records]", this.state.fifthStepData.schoolRecords)
    }
    if(this.state.fifthStepData.learnerRefNumberFile){
      formdata.append("student[learner_reference_number]", this.state.fifthStepData.learnerRefNumberFile)
    }
    if(this.state.fifthStepData.picture){
      formdata.append("student[id_picture]", this.state.fifthStepData.picture)
    }
    if(this.state.fifthStepData.soloParentId){
      formdata.append("student[solo_parent_id_proof]", this.state.fifthStepData.soloParentId)
    }
    if(this.state.fifthStepData.validParentId){
      formdata.append("student[parent_valid_id]", (this.state.fifthStepData.validParentId))
    }
  }

  sixToLastFile = (formdata: FormData) => {
    // 6
    if(this.state.fifthStepData.death){
      formdata.append("student[father_death_certificate]", this.state.fifthStepData.death)
    }
    if(this.state.fifthStepData.letterCommittee){
      formdata.append("student[family_details_letter]", this.state.fifthStepData.letterCommittee)
    }
    if(this.state.fifthStepData.birthStudent){
      formdata.append("student[student_birth_certificate]", this.state.fifthStepData.birthStudent)
    }
    if(this.state.fifthStepData.recentBill){
      formdata.append("student[electric_bill]", this.state.fifthStepData.recentBill)
    }

    // 7
    if(this.state.fifthStepData.birthParent){
      formdata.append("student[parent_birth_certificate]", this.state.fifthStepData.birthParent)
    }
    if(this.state.fifthStepData.birthLuovaStaff){
      formdata.append("student[luova_staff_birth_certificate]", this.state.fifthStepData.birthLuovaStaff)
    }

    // if pwd select in 1st step
    if(this.state.fifthStepData.pwdID){
      formdata.append("student[pwd_id_proof]", this.state.fifthStepData.pwdID)
    }
  }

  oneToFifthFIle = (formdata: FormData) => {
    // 1
    if(this.state.fifthStepData.chrissie){
      formdata.append("student[letter_of_recommendation]", this.state.fifthStepData.chrissie)
    }

    // 2
    if(this.state.fifthStepData.internationalLetter){
      formdata.append("student[letter_of_recommendation]", this.state.fifthStepData.internationalLetter)
    }
    if(this.state.fifthStepData.internationalAchevement){
      formdata.append("student[proof_of_achievements]", this.state.fifthStepData.internationalAchevement)
    }

    // 3
    if(this.state.fifthStepData.ministry){
      formdata.append("student[church_affiliation_letter]", this.state.fifthStepData.ministry)
    }

    // 4
    if(this.state.fifthStepData.hgServant){
      formdata.append("student[letter_of_recommendation]", this.state.fifthStepData.hgServant)
    }

    // 5
    if(this.state.fifthStepData.financial){
      formdata.append("student[letter_of_recommendation]", this.state.fifthStepData.financial)
    }
    if(this.state.fifthStepData.proofIncome){
      formdata.append("student[proof_of_income_or_billing]", this.state.fifthStepData.proofIncome)
    }
  }

  handlers: any = {
    handleChangeText: this.handleChangeText,
    handleChangestudentAdvocatecode:this.handleChangestudentAdvocatecode,
    handleChangeText1: this.handleChangeText1,
    handleChangerelationWithStudent:this.handleChangerelationWithStudent,
    handleChangeText2: this.handleChangeText2,
    handlePhoneNumber: this.handlePhoneNumber,
    handlePhoneNumbers:this.handlePhoneNumbers,
    handlePhoneNumberss:this.handlePhoneNumberss,
    handleChangeprevSchoolAttended: this.handleChangeprevSchoolAttended,
    handleChangeprevSchoolEmail: this.handleChangeprevSchoolEmail,
    handleChangeprevSchoolNumber: this.handleChangeprevSchoolNumber,
    handleChangeprevSchoolAddress: this.handleChangeprevSchoolAddress,
    handleChangeprevSchoolYear: this.handleChangeprevSchoolYear,
    residentialAddressChange: this.residentialAddressChange,
    hideError: this.hideError,
    genderChange: this.genderChange,
    datechange: this.datechange,
    handleGetOptionLabel: this.handleGetOptionLabel,
    dateFunction: this.dateFunction,
    handleGetOptionSelected: this.handleGetOptionSelected,
    handleChildFirstLanguage: this.handleChildFirstLanguage,
    handleChangelearnerRefNumber: this.handleChangelearnerRefNumber,
    handleChangefatherFirstName: this.handleChangefatherFirstName,
    handleChangefatherMiddleName: this.handleChangefatherMiddleName,
    handleChangeguardianAddress:this.handleChangeguardianAddress,
    handleChangeguardianOccupation:this.handleChangeguardianOccupation,
    onCountryCodeSelectedWeb: this.onCountryCodeSelectedWeb,
    handleChangemotherOccupation:this.handleChangemotherOccupation,
    handleChangefatherOccupationt:this.handleChangefatherOccupationt,
    handleChangefatherEmail:this.handleChangefatherEmail,
    handleChangemotherMiddleName:this.handleChangemotherMiddleName ,
    handleChangemotherLastName:this.handleChangemotherLastName,
    handleChangemotherFirstName:this.handleChangemotherFirstName,
    handleChangemotherEmail:this.handleChangemotherEmail,
    handleChangeguardianEmail:this.handleChangeguardianEmail,
    handleChangeguardianName:this.handleChangeguardianName,
    handleChangefatherLastName: this.handleChangefatherLastName,
    uploadLetterOfRecommendationFile: this.uploadLetterOfRecommendationFile,
    uploadPWDIDFile: this.uploadPWDIDFile,
    uploadBirthCertificateFile: this.uploadBirthCertificateFile,
    uploadReportCardFile: this.uploadReportCardFile,
    uploadSchoolRecordsFile: this.uploadSchoolRecordsFile,
    uploadLearnerRefNumberFile: this.uploadLearnerRefNumberFile,
    uploadPictureFile: this.uploadPictureFile,
    uploadSoloParentIdFile: this.uploadSoloParentIdFile,
    uploadValidParentIdFile: this.uploadValidParentIdFile,
    handleChangestudentSoloParentId:this.handleChangestudentSoloParentId,
    handlestudentSpecification: this.handlestudentSpecification,
    handleCheckedStudentScholarShip: this.handleCheckedStudentScholarShip,
    handleChangestudentPwdIdNumber:this.handleChangestudentPwdIdNumber,
    handleChangeScholarshipType: this.handleChangeScholarshipType,
    scholarshipChange: this.scholarshipChange,
    handleProofOfRelationship: this.handleProofOfRelationship,
    handleClearFatherDeath: this.handleClearFatherDeath,
    handleStudentCauseOfDeath: this.handleStudentCauseOfDeath,
    nationalityDataChange: this.nationalityDataChange,
    handleAdvocatecode: this.handleAdvocatecode,
    religionDataChange: this.religionDataChange,
    handlePwdDiscount: this.handlePwdDiscount,
    handleParentDiscount: this.handleParentDiscount,
    handleGradeLevel: this.handleGradeLevel,
    handleCurriculumData: this.handleCurriculumData,
    programDataChange: this.programDataChange,
    communityDataChange: this.communityDataChange,
    hubDataChange: this.hubDataChange,
    accreditationDataChange: this.accreditationDataChange,
    handleCycleChange: this.handleCycleChange,
    shsTrackChange: this.shsTrackChange,
    changeHandlerAutoGetCountriesBirthCallBack: this.changeHandlerAutoGetCountriesBirthCallBack,
    changeResidenceAutoGetCountriesBirthCallBack: this.changeResidenceAutoGetCountriesBirthCallBack,
    handleRenderInput1: this.handleRenderInput1,
    handleRenderInput2:this.handleRenderInput2,
    handleRenderInput3:this.handleRenderInput3,
    changeHandlerGetCountriesCallBack: this.changeHandlerGetCountriesCallBack,
    changeResidenceGetCountriesCallBack: this.changeResidenceGetCountriesCallBack,
    changeHandlerAutoGetCitiesBirthCallBack: this.changeHandlerAutoGetCitiesBirthCallBack,
    changeResidenceAutoGetCitiesBirthCallBack: this.changeResidenceAutoGetCitiesBirthCallBack,
    handleCityBirthCallBack: this.handleCityBirthCallBack,
    handleResidenceCityCallBack: this.handleResidenceCityCallBack,
    barangayBirthChangeCallBack: this.barangayBirthChangeCallBack,
    maritalStatusChange: this.maritalStatusChange,
    uploadFiles: this.uploadFiles,
    reasonDataChange: this.reasonDataChange,
    handleCheckBoxrefundPolicy: this.handleCheckBoxrefundPolicy,
    handlegeneralTermsAndConditions: this.handlegeneralTermsAndConditions,
    handleCheckBoxprivacyPolicy: this.handleCheckBoxprivacyPolicy,
    handleCheckBoxpictureAuthorisation: this.handleCheckBoxpictureAuthorisation,
    sortTermsAndCondition: this.sortTermsAndCondition,
    handleCheckBox: this.handleCheckBox,
    chrissieUploadFile: this.chrissieUploadFile,
    internationalLetterUploadFile: this.internationalLetterUploadFile,
    internationalAchevementUploadFile: this.internationalAchevementUploadFile,
    ministryUploadFile: this.ministryUploadFile,
    HGServantUploadFile: this.HGServantUploadFile,
    financialuploadFile: this.financialuploadFile,
    proofIncomeuploadFile: this.proofIncomeuploadFile,
    deathuploadFile: this.deathuploadFile,
    letterCommitteeuploadFile: this.letterCommitteeuploadFile,
    birthStudent: this.birthStudent,
    recentBilluploadFile: this.recentBilluploadFile,
    birthParentuploadFile: this.birthParentuploadFile,
    birthLuovaStaffuploadFile: this.birthLuovaStaffuploadFile
  }

  onSaveFormData = () => {
    const formdata = new FormData();
    if (this.state.formId){
      formdata.append("student[id]", this.state.formId)
    }
      formdata.append("student[first_name]", this.state.firstStepData.studentFirstName);
      formdata.append("student[middle_name]", this.state.firstStepData.studentMiddleName);
      formdata.append("student[last_name]", this.state.firstStepData.studentLastName);
      formdata.append("student[gender]", this.state.firstStepData.studentGender);
      formdata.append("student[date_of_birth]", this.state.firstStepData.studentDOB);
      formdata.append("student[city_of_birth]", this.state.firstStepData.studentBirthCity?.value);//check
      formdata.append("student[country_of_birth]", this.state.firstStepData.studentCountry?.id);//check
      formdata.append("student[religion_id]", this.state.firstStepData.studentReligion?.id);
      formdata.append("student[other_religion]", "Christian");
      formdata.append("student[nationality_id]", this.state.firstStepData.studentNationality?.id);
      formdata.append("student[child_first_language]", this.state.firstStepData.studentChildsFirstLanguage?.id);
      formdata.append("student[other_language]", this.state.firstStepData.studentEnterChildsFirstLanguage);
      formdata.append("student[scholarship]", this.state.firstStepData.studentWouldYouLikeToApplyForAScholarShip ? "true" : "false")
      if (this.state.firstStepData.studentWouldYouLikeToApplyForAScholarShip) {
        formdata.append("student[scholarship_category]", this.state.firstStepData.studentScholarShipType)
        formdata.append("student[scholarship_type_id]", this.state.firstStepData.studentTypesOfScholarShip?.id)
      } //need
      formdata.append("student[proof_of_relationship]", "Niece");
      formdata.append("student[father_death_date]", "05-05-2002");
      formdata.append("student[country_of_residence]", this.state.firstStepData.studentCountryOfResidence?.id);//check
      formdata.append("student[city_of_residence]", this.state.firstStepData.studentCityOfResidence?.value);//check
      formdata.append("student[referral]", this.state.firstStepData.studentReferralProgram ? "true" : "false")
      if (this.state.firstStepData.studentReferralProgram) {
        formdata.append("student[referral_code]", this.state.firstStepData.studentAdvocatecode) //check
      }
      formdata.append("student[pwd_discount]", this.state.firstStepData.studentPwd ? "true" : "false")
      if (this.state.firstStepData.studentPwd) {
        formdata.append("student[pwd_id]", this.state.firstStepData.studentPwdIdNumber)//check
      }
      formdata.append("student[solo_parent_discount]", this.state.firstStepData.studentParentDiscount ? "true" : "false")
      if (this.state.firstStepData.studentParentDiscount) {
        formdata.append("student[solo_parent_id]", this.state.firstStepData.studentSoloParentId)//check
        formdata.append("student[residential_address]", this.state.firstStepData.studentResidentialAddress)
      }
      if (this.state.firstStepData.residenceCountry == "Philippines") {
        formdata.append("student[philippines_residence_address]", this.state.firstStepData.phResisencyAddress)
        formdata.append("student[barangay_id]", this.state.firstStepData.barangayId)
      } else {
        formdata.append("student[philippines_residence_address]", "")
        formdata.append("student[barangay_id]", "")
      } //need

      formdata.append("previous_school[grade_id]", this.state.secondStepData.prevGradeLevel?.id)
      formdata.append("previous_school[school_attended]", this.state.secondStepData.prevSchoolAttended)
      formdata.append("previous_school[school_curriculum_id]", this.state.secondStepData.prevSchoolCurriculum?.id)
      formdata.append("previous_school[school_email]", this.state.secondStepData.prevSchoolEmail)
      formdata.append("previous_school[school_contact_number]", "+91" + this.state.secondStepData.prevSchoolNumber)
      formdata.append("previous_school[school_address]", this.state.secondStepData.prevSchoolAddress)
      formdata.append("previous_school[last_year_of_school]", this.state.secondStepData.prevSchoolYear)//check
      formdata.append("previous_school[learner_reference_number]", this.state.secondStepData.learnerRefNumber)//check


      formdata.append("program_detail[hub_id]", this.state.thirdStepData.hub?.id)
      formdata.append("program_detail[community_id]", this.state.thirdStepData.community?.id)
      formdata.append("program_detail[grade_id]", this.state.thirdStepData.incomingGradeLevel?.id)
      formdata.append("program_detail[program_id]", this.state.thirdStepData.program?.id)
      formdata.append("program_detail[accreditation_id]", this.state.thirdStepData.accreditation?.id)
      formdata.append("program_detail[cycle]", this.state.thirdStepData.cycle?.value)
      formdata.append("program_detail[shs_track]", this.state.thirdStepData.shsTrack?.value)

      formdata.append("parent[father_first_name]", this.state.fourthStepData.fatherFirstName)
      formdata.append("parent[father_middle_name]", this.state.fourthStepData.fatherMiddleName)
      formdata.append("parent[father_last_name]", this.state.fourthStepData.fatherLastName)
      formdata.append("parent[father_occupation]", this.state.fourthStepData.fatherOccupation)
      formdata.append("parent[father_mobile_number]", this.state.fourthStepData.fatherMobileNumberCode+this.state.fourthStepData.fatherMobileNumber)
      formdata.append("parent[father_email]", this.state.fourthStepData.fatherEmail)
      formdata.append("parent[mother_first_name]", this.state.fourthStepData.motherFirstName)
      formdata.append("parent[mother_middle_name]", this.state.fourthStepData.motherMiddleName)
      formdata.append("parent[mother_last_name]", this.state.fourthStepData.motherLastName)
      formdata.append("parent[mother_occupation]", this.state.fourthStepData.motherOccupation)
      formdata.append("parent[mother_mobile_number]", this.state.fourthStepData.motherMobileNumberCode+this.state.fourthStepData.motherMobileNumber)
      formdata.append("parent[mother_email]", this.state.fourthStepData.motherEmail)
      formdata.append("parent[marital_status]", this.state.fourthStepData.maritalStatus)
      formdata.append("parent[guardian_name]", this.state.fourthStepData.guardianName)
      formdata.append("parent[guardian_address]", this.state.fourthStepData.guardianAddress)
      formdata.append("parent[guardian_occupation]", this.state.fourthStepData.guardianOccupation)
      formdata.append("parent[guardian_mobile_number]", this.state.fourthStepData.guardianMobileNumberCode+this.state.fourthStepData.guardianMobileNumber)
      formdata.append("parent[guardian_email]", this.state.fourthStepData.guardianEmail)
      formdata.append("parent[relationship_to_the_student]", this.state.fourthStepData.relationWithStudent)
      formdata.append("parent[father_country_code]", "91");
      formdata.append("parent[mother_country_code]", "91");
      formdata.append("parent[guardian_country_code]", "91");
      formdata.append("student[reason_of_registering]", this.state.reasonForRegistering.reason?.value);//check
      formdata.append("student[status]", "submitted_for_review")
      formdata.append("student[father_cause_of_death]", "Natural death");
      formdata.append("student[specification]", "HG and FA families");
      formdata.append("previous_school[other_curriculum]", "DEPED");

      this.oneToFifthFIle(formdata);
      this.sixToLastFile(formdata);
      this.commanFile(formdata);

      return formdata;
  }
  onSave = async () => {
    const header = {
      "token": await localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdSubmit = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "student_enrolment"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.onSaveFormData()

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleModelOpen = () => {
    const isCheck = this.studentEnrollmentvalidatio7()
    if (isCheck) {
      this.setState({ open: true })
    }

  }
  handleModelClose = () => {
    this.setState({ open: false })
    this.onSave()
    this.setState({ newEnrollYesNo: false })
  }
  handleModelCloseOnCross = () => {
    this.setState({ open: false })
  }
  YesBtn = () => {
    this.setState({ open: false, newEnrollYesNo: true })
    this.onSave();
  }

  onDraft = () => {
    this.setState({draftTrueFalse: true})
    this.onSave()
  }

  checkNext = () => {
    if (!this.state.draftTrueFalse){
      if (!this.state.newEnrollYesNo) {
        this.setState({ activeStep: this.state.activeStep + 1 })
      }
    }
  }

  formCheck = (formid: number) => {
    if (this.state.activeStep === 7) {
      this.setState({ formId: formid, activeStep: 7 })
    } 
    else if (this.state.newEnrollYesNo && this.state.activeStep === 6) {
      this.setState({ activeStep: 0 })
      this.setState({
        firstStepData: {
          studentFirstName: "",
          studentMiddleName: "",
          studentLastName: "",
          studentGender: "choose",
          studentCountryAutoCompleteValue:
          {
            value: "choose"
          },
          studentDOB: "",
          studentCountry: {
            id: "",
            value: "choose"
          },
          studentBirthCity: {
            id: "",
            value: "choose"
          },
          studentReligion: {
            id: "",
            value: "choose"
          },
          studentBirthAutoCompleteValue: {
            id: "",
            value: "choose"
          },
          studentAge: "",
          studentReligionOthers: '',
          studentNationality: {
            id: "",
            value: "choose"
          },
          studentCountryOfResidence: {
            id: "",
            value: "choose"
          },
          countryResiAutoCompleteValue: {
            value: ""
          },
          studentChildsFirstLanguage: {
            id: "",
            value: "choose"
          },
          studentCountryOfResidenceArray: [],
          studentCityOfResidence: {
            id: '',
            value: "choose"
          },
          barangaySubData: {
            id: '',
            value: "choose"
          },
          cityResiAutoCompleteValue: {
            value: ""
          },
          barangayBirth: {
            id: '',
            value: "choose"
          },
          studentEnterChildsFirstLanguage: '',
          studentWouldYouLikeToApplyForAScholarShip: false,
          studentScholarShipType: {
            id: '',
            value: ""
          },
          studentProofOfRelationship: { id: '', value: "choose" },
          studentTypesOfScholarShip: {
            id: '',
            value: ""
          },
          studentfatherDeath: '',
          studentCauseOfDeath: 'choose',
          studentSpecification: {
            id: '',
            value: "choose"
          },
          studentAdvocatecode: "",
          studentPwdIdNumber: '',
          studentSoloParentId: "",
          studentReferralProgram: false,
          studentParentDiscount: false,
          studentPwd: false,
          studentResidentialAddress: '',
          studentErrors: {},
        },
        secondStepData: {
          prevGradeLevel: {
            id: '',
            value: 'choose'
          },
          prevSchoolAttended: '',
          prevSchoolCurriculum: {
            id: '',
            value: 'choose'
          },
          prevSchoolEmail: '',
          prevSchoolYear: '',
          prevSchoolNumber: '',
          prevSchoolAddress: '',
          learnerRefNumber: '',
          prevSchoolErrors: {
            prevGradeLevel: {
              id: '',
              value: 'choose'
            },
            prevSchoolAttended: '',
            prevSchoolCurriculum: {
              id: '',
              value: 'choose'
            },
            prevSchoolEmail: '',
            prevSchoolAddress: '',
            prevSchoolYear: '',
            prevSchoolNumber: '',
            learnerRefNumber: '',
          },
          studentErrors: {}
        },
        thirdStepData: {
          hub: {
            id: '',
            value: 'choose'
          },
          community: {
            id: '',
            value: 'choose'
          },
          incomingGradeLevel: {
            id: '',
            value: 'choose'
          },
          program: {
            id: '',
            value: 'choose'
          },
          cycle: {
            id: '',
            value: 'choose'
          },
          accreditation: {
            id: '',
            value: 'choose'
          },
          shsTrack: {
            id: '',
            value: ''
          },
          programErrors: {
            hub: '',
            community: '',
            accreditation: '',
            incomingGradeLevel: '',
            program: '',
            cycle: '',
            shsTrack: ""
          }
        },
        fourthStepData: {
          fatherFirstName: '',
          fatherMiddleName: '',
          fatherOccupation: '',
          fatherLastName: '',
          fatherMobileNumberCode: '+91',
          fatherMobileNumber: '',
          fatherEmail: '',
          motherFirstName: '',
          motherMiddleName: '',
          motherLastName: '',
          motherOccupation: '',
          motherMobileNumber: '',
          motherMobileNumberCode: '+91',
          guardianAddress: '',
          motherEmail: '',
          maritalStatus: '',
          guardianName: '',
          guardianOccupation: '',
          guardianMobileNumber: '',
          relationWithStudent: '',
          guardianMobileNumberCode: '+91',
          guardianEmail: '',
          countryPhoneCodeListOne: [] as IDItem[],
          parentsErrors: {
            fatherFirstName: '',
            fatherMiddleName: '',
            fatherLastName: '',
            fatherOccupation: '',
            motherFirstName: '',
            motherMiddleName: '',
            fatherMobileNumber: '',
            fatherEmail: '',
            motherLastName: '',
            motherOccupation: '',
            motherMobileNumber: '',
            motherEmail: '',
            guardianName: '',
            maritalStatus: '',
            guardianAddress: '',
            guardianOccupation: '',
            guardianMobileNumber: '',
            guardianEmail: '',
            relationWithStudent: '',
          },
        },
        fifthStepData: {
          letterOfRecomendation: '',
          chrissie: '',
          internationalLetter: '',
          hgServant: '',
          internationalAchevement: '',
          ministry: '',
          financial: '',
          proofIncome: '',
          death: '',
          birthStudent: '',
          letterCommittee: '',
          recentBill: '',
          birthParent: '',
          birthLuovaStaff: '',
          pwdID: '',
          birthCertificate: '',
          schoolRecords: '',
          reportCard: '',
          learnerRefNumberFile: '',
          picture: '',
          soloParentId: '',
          validParentId: '',
          fileErrors: {
            letterOfRecomendation: '',
            pwdID: '',
            birthCertificate: '',
            reportCard: '',
            schoolRecords: '',
            picture: '',
            learnerRefNumberFile: '',
            soloParentId: '',
            validParentId: '',
            chrissie: '',
            internationalLetter: '',
            internationalAchevement: '',
            hgServant: '',
            ministry: '',
            financial: '',
            proofIncome: '',
            death: '',
            letterCommittee: '',
            birthStudent: '',
            recentBill: '',
            birthParent: '',
            birthLuovaStaff: ''
          }
        },
        sixStepData: {
          error: false
        },
      })
    } 
  }
}


// Customizable Area End