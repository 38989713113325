Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipleStepRegistrationForm2";
exports.labelBodyText = "MultipleStepRegistrationForm2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiEndPointSavetoDraft='/bx_block_student/pending_enrollment_details';
exports.apiEndPointReligionDetail='bx_block_student/religions';
exports.apiEndPointNationalitiesDetail='bx_block_student/nationalities';
exports.apiEndPointlanguagesDetail='bx_block_student/languages';
exports.apiEndPointScholarshipDetail='bx_block_student/scholarship_types';
exports.apiEndPointGradesDetail= 'bx_block_student/grades';
exports.apiEndPointCurriculumDetail = 'bx_block_student/school_curriculums';
exports.apiEndPointProgramDetail = 'bx_block_student/programs';
exports.apiEndPointCommunityDetail = 'bx_block_student/communities';
exports.apiEndPointHubDetail = 'bx_block_student/hubs';
exports.apiEndPointByIDProgram = 'fetch_program_levels?program=';
exports.apiEndPointAccreditationDetail = 'bx_block_student/accreditations';
exports.apiEndPointReasonDetail = 'bx_block_student/reason_of_registerings';
exports.apiEndPointTnCDetail = 'terms_and_conditions';
exports.apiProvincesCallUrl="provinces";
exports.testneww="provinces";
exports.apiEndPointBarangayDetail = 'get_barangays';
exports.apiEndPointSubmit = 'student_enrolment';
exports.testNew = "TestN"
exports.test = "Test"
exports.testnew = "Testdata"
exports.dataNEW = "DataNew"
exports.testPostNEw = "DataNewdata"
// Customizable Area End