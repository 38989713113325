Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name  *";
exports.lastName = "Last Name  *";
exports.labelEmail = "Email Address  *";
exports.labelPassword = "Password  *";
exports.labelRePassword = "Confirm Password  *";
exports.errorCountryCodeNotSelected = "Please select country code  *";
exports.errorBlankField = "can't be blank";
exports.labelContact = "Contact number  *"
exports.labelPhoneNumber = "Phone number  *"
exports.labelEmailPlaceholder = "email@gmail.com  *"
exports.dropdownPlaceHolder = "Please select"
exports.labelResidenceCountry = "Country of Residence"
exports.labelResidenceCity = "City of Residence"
exports.labelResidenceProvince = "Province of Residence"
exports.labelSchoolingReason = "Reason for considering Homeschooling"
exports.labelLearnAboutUs = "How did you learn about us?"

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";

exports.accountsApiCreateMethod = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.homeSchoolingReasonsMethod = "GET";
exports.homeSchoolingReasonsEndPoint = "home_schooling_reasons"

exports.barangayApiMethod = "GET";
exports.barangayApiEndpoint = "get_barangays"

exports.provinceApiMethod = "GET";
exports.provinceApiEndpoint = "provinces"
exports.provinceGetDetasil = "GetDetails"
// Customizable Area End
