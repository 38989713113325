// Customizable Area Start
import React from "react";
// Customizable Area End
import {
  // Customizable Area Start
  Button
  // Customizable Area End
} from "@material-ui/core";

import DashboardController, { Props } from "./DashboardController.web";

// Customizable Area Start



// Customizable Area End

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    return (
      // Customizable Area Start
      <>
      
        <div style={webStyle.btnView}>
          <h2 style={{fontFamily: "'Poppins-Medium', sans-serif"}}>Welcome to the dashbard</h2>
          <Button
            data-test-id="btnNext"
            variant="contained"
            style={{
              width: "200px",
              height: "52px",
              fontFamily: "'Poppins-Medium', sans-serif",
              fontSize: "16px",
              fontWeight: 600,
              color: "#fafafb",
              textAlign: "center",
              backgroundColor: "#fab03d",
              borderRadius: "10px",
              marginLeft: "1em",
              textTransform: "none"
            }}
            onClick={() =>
              this.props.navigation.navigate("CustomFormEnrollment")
            }
          >
            ENROLL NOW student
          </Button>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  btnView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "100px",
    paddingRight: "30px",
    marginTop: "20px"
  }
};
// Customizable Area End
