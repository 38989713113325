// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  CardMedia,
  IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import DashboardController from "./DashboardController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import IntroVideo from "../../../components/src/IntroVideo.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import {
  Backgroundimg,
  HomeSchoolRecomender,
  acc1, acc2, acc3, acc4,
  partner_school1, partner_school2, partner_school3,
} from './assets';
import ChatBot from "../../../components/src/ChatBot.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import HgVideo from "../../../components/src/HgVideo.web";

export interface AllVideosArray {
  id: string
  videoURL: string
  videoName: string
  videoType: string
  videoDesc: string
  priority: number
  thumbnail: string
}

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});


// Customizable Area End

export default class DashBoardNonExpandablePage extends DashboardController {
  // Customizable Area Start
  setTimeout: any;
  height:any;
 
  render() {
    const playerHeight:any = '324px';
   
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
           <AppWithoutDrawer navigation={this.props.navigation} />
            <Sidebarmenu/>
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Grid container style={{ display: 'block' }}>
          
            <Grid item>
              <Container maxWidth={false} >
               
                <Container maxWidth={false} style={{ margin: '0 auto', width: "100%" }}>
                  <Box style={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.welcomeTextStyle} >Welcome {this.state?.userData?.fullName}</Typography>
                    {
                      !this.state.userData?.isActive ?
                        <Typography style={webStyle.verifyTextStyle} >Verify your Email Address to continue with enrollment! </Typography>
                        : null
                    }

                    <Button style={webStyle.btnEnrollNow}
                      data-test-id="btnEnroll"
                      onClick={this.handleNavigation}
                    >
                      ENROLL NOW
                    </Button>
                  </Box>

                  <Grid container style={{ display: 'flex' }}>
                    <HorizontalLine />
                  </Grid>

                  <Container maxWidth={false} style={{ padding: '0' }}>
                 
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                    
                      <Grid item xs={12} lg={5}   >

                        <Typography align='left' style={webStyle.headerTextStyle}>Homeschool Orientation</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerOrientation }} >
                          <Box style={webStyle.boxOrientation}>

                          {!this.state.isLoading && this.state.allVideos_orientationVideoURL != "" ?

                              <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <CircularProgress /></Box>
                              :
                              <IntroVideo
                                isIntroVideo={false}
                                introVideoURL={this.state.allVideos_orientationVideoURL}
                                watchTime={localStorage.getItem('curentTime')}
                                height={playerHeight}

                              />
                            }

                          </Box>
                        </Paper>
                      </Grid>

                     
                      <Grid item xs={12} lg={7}  >
                        <Typography align='left' style={webStyle.headerTextStyle}>Our Programs</Typography>
                          <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerProgram as React.CSSProperties }}  >
                            <div style={webStyle.backgroundOverlay as React.CSSProperties}></div>
                            <Grid container style={{ display: 'flex', alignItems: 'stretch', boxSizing: 'border-box', padding: '0.5vw', position: "relative", zIndex: 2 }}>
                         

                            <Grid item style={webStyle.gridItemProgramStyle}>

                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Touch</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with comprehensive advisor support
                                  involving parental instruction
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle}>
                                <Avatar alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Curated</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum with comprehensive support and guidance from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                           

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Charlotte Mason </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist
                                    </Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[0].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Classical</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Classical Christian curriculum
                                      with comprehensive support
                                      and guidance through weekly
                                      community classes, powered
                                      by Classical Conversations
                                      Philippines
                                    </Typography>

                                  </Box>
                                </Box>
                              </Paper>

                            </Grid>

                           

                            <Grid item style={webStyle.gridItemProgramStylepurple} >

                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Tech</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Parent-led learning with platform- driven essential advisor support involving parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>BJU (Blended)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      High-quality blended curriculum with essential Advisor support
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                             

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>AOP (Fully Online) </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>High-quality fully online
                                      curriculum with essential
                                      Advisor support
                                    </Typography>

                                  </Box>
                                </Box>
                              </Paper>
                            

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[1].attributes?.hg_formula_programs?.data[2].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>
                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Custom (Open)</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable curriculum
                                      with platformdriven essential
                                      Advisor support and basic
                                      academic assistance for
                                      records designed for
                                      home schooling families 3
                                      years and up
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>

                           

                            <Grid item style={webStyle.gridItemProgramStyleGreen}>
                              <Box>
                                <Typography align='left' style={webStyle.programMainText}>Online Academies</Typography>
                                <Typography align='left' style={webStyle.programMainSubText}>
                                  Flexible online learning with teacher
                                  supervision, grading, and optional
                                  academic classes involving minimal
                                  parental supervision
                                </Typography>
                              </Box>

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[0].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>

                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy</Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>
                                      Customizable curriculum
                                      with comprehensive
                                      support and guidance
                                      from seasoned Advisor
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                             

                              <Paper elevation={0} style={webStyle.gridProgramContainerStyle} >
                                <Avatar
                                  alt="program avatar"
                                  src={this.state.hgFormula_ExplorePrograms?.hg_formula_sections?.data[2].attributes?.hg_formula_programs?.data[1].attributes?.program_image}
                                  style={webStyle.programAvatarStyle}
                                />
                                <Box style={webStyle.programBoxStyle}>
                                  <Box>
                                    <Typography align='left' style={webStyle.ProgramInnerTextHeader}>Online Academy Plus </Typography>
                                    <Typography align='left' style={webStyle.programInnerTextSubText}>Customizable Charlotte
                                      Mason curriculum with
                                      comprehensive support and
                                      guidance from a Charlotte
                                      Mason Specialist
                                    </Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>

                   
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: '2em' }}>
                    
                      <Grid item xs={12} lg={5}  >
                        <Typography align='left' style={webStyle.headerTextStyle}>Homeschool Recommender</Typography>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerRecommender }} >
                          <Box style={webStyle.boxOrientation1}>
                            <Box>
                              <CardMedia
                                component="img"
                                image={HomeSchoolRecomender}
                                title="HomeSchoolRecomender Image"
                                style={{ width: '100%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}}
                              />
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Button 
                                onClick={this.handlePersonalisedNavigate}
                              variant='contained' style={{ textTransform: "none", ...webStyle.btnRecommendation }}
                              >Get your Personalised Recommendation</Button>
                            </Box>
                          </Box>
                        </Paper>

                      </Grid>

                     
                      <Grid item xs={12} lg={7}  >
                        <Box>  <Typography align='left' style={webStyle.headerTextStyle}>Testimonials</Typography></Box>

                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainerTestimonial, display: "block",position:"relative" }}  >
                          <Box style={{position:"absolute",top:"48%",left:"50%",transform:"translate(-50%,-50%)"}}>
                            <Box style={{height: '250px', display: 'flex', justifyContent: 'center'}}>
                              <HgVideo introVideoURL={this.state.selectedTestimonialVideo.videoURL} />
                            </Box>

                            <Box style={{ ...webStyle.boxGreenTestimonial, marginLeft: "10%" ,position:"absolute"}}>
                              <Typography align='center' style={webStyle.btnTestimonial}>{this.state.selectedTestimonialVideo.videoName}</Typography>
                              <Typography align='center' style={webStyle.btnTestimonialSubText}>{this.state.selectedTestimonialVideo.videoDesc}</Typography>
                            </Box>

                            <Box style={{ display: "flex", marginTop: "2.5rem", gap: '15px', maxWidth: '400px', width: '100%', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                {this.state.allVideosArray.length &&
                                  this.state.allVideosArray.map((item: AllVideosArray, index: number) => {
                                    return (
                                      <>
                                        <Box style={{ display: 'flex', alignItems: 'center' }} >
                                          <Box style={{ width: '100%', position: 'relative' }}>
                                            <Avatar
                                              alt="Testimonials img"
                                              key={index}
                                              src={item.thumbnail}
                                              style={{ width: '60px', borderRadius: '20px', height: "60px" }}
                                            />
                                            <div style={webStyle.playButton as React.CSSProperties}>
                                              <Box style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                {
                                                  <PlayCircleFilledIcon style={{ marginTop: '55px', fontSize: '30px', color: 'white' }} onClick={()=>this.selectTestimonialVideo(item)} />
                                                }
                                              </Box>
                                            </div>
                                          </Box>
                                        </Box>
                                      </>
                                    );
                                  })
                                }
                              </Box>
                            </Box>
                        </Paper>
                      </Grid>
                    </Grid>

                    
                      <Grid container spacing={3} style={{ display: "flex", alignItems: "stretch", marginTop: "2em", justifyContent: "space-between" }}>
                       
                        <Grid item xs={12} lg={5}>
                          <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer }} >
                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography align='center' style={webStyle.headerTextStyle}>Our Partner Schools</Typography>
                              <Box style={{ width: '173px', backgroundColor: '#E5E5E5', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '1em', boxSizing: 'border-box' }}>
                                <CardMedia
                                  component="img"
                                  image={partner_school1}
                                  title="school partner"

                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                                <CardMedia
                                  component="img"
                                  image={partner_school2}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                                <CardMedia
                                  component="img"
                                  image={partner_school3}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                              </Box>
                            </Box>

                           

                            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                              <Typography align='center' style={webStyle.headerTextStyle}>Our Partner Schools Accreditations</Typography>
                              <Box style={{ width: '263px', backgroundColor: '#E5E5E5', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '1em', boxSizing: 'border-box' }}>
                                <CardMedia
                                  component="img"
                                  image={acc1}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                                <CardMedia
                                  component="img"
                                  image={acc2}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                                <CardMedia
                                  component="img"
                                  image={acc3}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                                <CardMedia
                                  component="img"
                                  image={acc4}
                                  title="school partner"
                                  style={{ width: '40px', height: '40px', borderRadius: '50px' }}
                                />
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      <ChatBot />

                    </Grid>
                  </Container>
                </Container>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      </div>
    )
  }
  // Customizable Area End
}

// Customizable Area Start

const webStyle = {
  btnEnrollNow: {
    height: "52px",
   fontFamily: "'Poppins-SemiBold', sans-serif",
    width: "235px",
    fontSize: "16px",
    color: "#fafafb",
    fontWeight: 400,
    borderRadius: "10px",
    backgroundColor: "#fab03d"
  },
  verifyTextStyle: {
    color: "#92929d",
    fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: '18px',
    fontWeight: 400,
    letterSpacing: "0.075px",
    lineHeight:" 10px"
  },
  backGround: {
    backgroundImage: `url(${Backgroundimg})`,
    height: "100%",
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: "20px"
  },
  cardAvatarStyle: {
    width: '50px',
    height: '50px',
    marginRight: '10px'
  },
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '24px',
    fontWeight: 600,
  },
  HomeSchoolRecomender: {
    color: " #171725",
   fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: 'left'
  },
  outerProgramsStyle: {
    width: '100%',
    height: '90%',
    borderRadius: '20px',
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerProgramsStyle: {
    backgroundColor: 'red',
    position: 'absolute' as 'absolute',
    height: '90%',
    width: '96%',
  },

  paperContainer: {

    alignText: 'center',

    minHeight: '100px',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center',
    padding: '0.5em',
  },
  paperContainerProgram: {
    position: 'relative',
    alignText: 'center',
    minHeight: '20vw',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5vw',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  paperContainerOrientation: {

    alignText: 'center',
    width: '100%',
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.5em',
    paddingBottom: '0em',
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
  paperContainerTestimonial: {
    alignText: 'flex-start',
    width: '100%',
    height: '441px',
    
    borderRadius: '15px',
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop:"0.45rem"
  },


  paperContainerRecommender: {

    alignText: 'center',
    width: '100%',
    minHeight: '470px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    marginTop:"0.45rem"

  },

  gridProgramContainerStyle: {

    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
    padding: '0.3vw',
    display: 'flex',
    marginTop: '0.5vw',
    boxSizing: 'border-box' as 'border-box',
    cursor: 'pointer',
    height:"auto"
  },
  gridItemProgramStyle: {
    margin: '0.5vw',
    width: '30%', flexGrow: 1,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '0.5vw',
    border: '3px solid #fab03d',
    borderRadius: '15px'
  },
  gridItemProgramStylepurple: {
    margin: '0.5vw',
    width: '30%', flexGrow: 1,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '0.5vw',
    border: '3px solid #b366ff',
    borderRadius: '15px'
  },
  gridItemProgramStyleGreen: {
    margin: '0.5vw',
    width: '30%', flexGrow: 1,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '0.5vw',
    border: '3px solid #99ff99',
    borderRadius: '15px'
  },
  programAvatarStyle: {
    width: '2.7vw',
    height: '2.7vw',
  },

  programBoxStyle: {
    marginLeft: '0.8vw',
    marginRight: '0.2vw',
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
  },

  ProgramInnerTextHeader: {
    color: ' #757575',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '1.2'
  },

  programInnerTextSubText: {
    color: ' #757575',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '1.2'
  },
  programMainText: {

    color: ' #757575',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '20px',
    fontWeight: 800,
    alignText: 'left',

  },
  programMainSubText: {

    color: ' #757575',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    marginBottom: '10px',
    lineHeight: '1.2'
  },

  headerTextStyle: {
    color: " #171725",
    fontFamily: 'Poppins, sans-serif',
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "1em",
    
  lineHeight: "18px",
  letterSpacing: "0.06666667px"
  },

  btnWatchLater: {

    width: "235px",
    height: "52px",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '-10px',
    zIndex: 2,
  },
  btnRecommendation: {
    height: "52px",
   fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: '#FAFAFB',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    marginTop: '0.8em',
  },

  boxGreenTestimonial: {
    width: '80%',
    height: "60px",
    borderRadius: "10px",
    backgroundColor: "#8FBE49",
    marginTop: '-1.9vw',
    marginBottom: '0.5em',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column' as 'column'
  },
  btnTestimonial: {
   fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: '#fff',
  },
  btnTestimonialSubText: {
       fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: '#fff',
  },


  boxOrientation: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5em'
  },
  boxOrientation1: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  boxPartnerSchool: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5em',
  },
  boxTestimonial: {
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  boxTestimonial1: {
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '120px',

  },

  chatText: {
    color: '#44444f',
      fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: '16px',
    fontWeight: 700,
  },
  btnChat: {
    width: '104px',
    height: "28px",
   fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: "12px",
    fontWeight: 500,
    color: '#fff',
    borderRadius: "10px",
    backgroundColor: "#fab03d",
    margin: '0.6em'
  },
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '520px',
    height: '235px',
    border: 'none',
    focus: 'none',
    borderRadius: '20px',
    paddingTop: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '3px',
    backgroundColor: '#ffffff',
  },
  modalTextTitle: {
    color: '#171725',
   fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '24px',

  },
  modalTextSubTitle: {
    color: '#92929d',
      fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '10px'
  },
  modalTextSubTitleVideo: {
    color: '#171725',
   fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '25px',
  },
  modalButtonVerify: {
    marginTop: '10px',
    marginRight: '10px',
    width: '150px',
    height: '38px',
    borderRadius: '10px',
    backgroundColor: '#fab03d',
   fontFamily: "'Poppins-Medium', sans-serif",
    color: '#fafafb',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
  },
  display: {
    display: 'none'
  },
  testimonialBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
  },
  testimonialImage: {
    width: '60px',
    height: '60px',
    borderRadius: '5px',
    position: 'relative' as 'relative',
    cursor: 'pointer',
  },
  testimonialIcon: {
    fontSize: '2vw',
    color: 'white',
    position: 'absolute' as 'absolute',
    zIndex: 5,
    cursor: 'pointer',
  },
  styleViewAll: {
    color: ' #92929d',
      fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: '14px',
    fontWeight: 400,
  },
  playButton: {
    position: "absolute",
    top: -20,
    right: 0,
    left: 0,
    bottom: 0,
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${Backgroundimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.5,
    zIndex: 1,
    borderRadius: '15px',
  }
}

// Customizable Area End