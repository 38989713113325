// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Dynamiccontent2 from "../../blocks/dynamiccontent2/src/Dynamiccontent2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";

import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Imagethumbnail from "../../blocks/imagethumbnail/src/Imagethumbnail";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Ticketsystem2 from "../../blocks/ticketsystem2/src/Ticketsystem2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Multiplestepregistrationform2 from "../../blocks/multiplestepregistrationform2/src/Multiplestepregistrationform2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import Algorithmforfeecalculator2 from "../../blocks/algorithmforfeecalculator2/src/Algorithmforfeecalculator2";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import DashboardIntroVideo from '../../blocks/dashboard/src/DashboardIntroVideo.web';
import HgFormula from '../../blocks/dashboard/src/HgFormula.web';
import CustomFormEnrollment from '../../blocks/customform3/src/CustomformEnrollment.web';
import DashBoardNonExpandablePage from '../../blocks/dashboard/src/DashboardNonExpandablePage.web';
import DashBoardDayInLifeVideo from '../../blocks/dashboard/src/DashBoardDayInLifeVideo.web';
import StudentsDetails from "../../blocks/ordermanagement/src/StudentsDetails.web"
import StudentEnrollmentFormStepper from '../../blocks/multiplestepregistrationform2/src/StudentEnrollmentFormStepper.web';
import AccountSettings from "../../blocks/customisableuserprofiles/src/AccountSettings.web"
import TicketStatusInfo from "../../blocks/ticketsystem2/src/TicketStatusInfo.web"
import CreateTickets from "../../blocks/ticketsystem2/src/CreateTickets.web"
import HomeschoolButtons from '../../blocks/customform3/src/HomeschoolButtons.web';
import HomeschoolProgram from '../../blocks/customform3/src/HomeschoolProgram.web';
import HomeschoolStyle from '../../blocks/customform3/src/HomeschoolStyle.web';
import Assessmentfaqtest from "../../blocks/assessmenttest/src/Assessmentfaqtest.web"
import Checkout from "../../blocks/ordermanagement/src/Checkout.web"




const routeMap = {
   
  Assessmentfaqtest:{
    component: Assessmentfaqtest,
    path: "/faqs"
  },
 
  HomeschoolButtons:{
    component: HomeschoolButtons,
    path: "/Exploreprograms"
  },
  CreateTickets:{
    component: CreateTickets,
    path: "/create-tickets"
  },
  HomeschoolStyle:{
    component: HomeschoolStyle,
    path: "/explorehomeschoolstyle"
  },
  HomeschoolProgram:{
    component: HomeschoolProgram,
    path: "/explorehomeschoolprogram"
  },
  AccountSettings:{
    component: AccountSettings,
    path: "/accountsettings"
  },
  TicketStatusInfo:{
    component: TicketStatusInfo,
    path: "/ticket-status"
  },
  StudentsDetails:{
    component: StudentsDetails,
    path: "/studentsDetails"
  },
Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  Dynamiccontent2: {
    component: Dynamiccontent2,
    path: "/Dynamiccontent2"
  },
  Customform3: {
    component: Customform3,
    path: "/Customform3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Imagethumbnail: {
    component: Imagethumbnail,
    path: "/Imagethumbnail"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Ticketsystem2: {
    component: Ticketsystem2,
    path: "/Ticketsystem2"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Videolibrary2: {
    component: Videolibrary2,
    path: "/Videolibrary2"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword",
  },
  Multiplestepregistrationform2: {
    component: Multiplestepregistrationform2,
    path: "/Multiplestepregistrationform2"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  DashboardIntroVideo: {
    component: DashboardIntroVideo,
    path: "/DashboardIntroVideo"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ChatView: {
    component: ChatView,
    path: "/ChatView"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  Algorithmforfeecalculator2: {
    component: Algorithmforfeecalculator2,
    path: "/Algorithmforfeecalculator2"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  HgFormula: {
    component: HgFormula,
    path: '/HgFormula'
  },
  CustomFormEnrollment: {
    component: CustomFormEnrollment,
    path: '/CustomFormEnrollment'
  },
  DashBoardNonExpandablePage: {
    component: DashBoardNonExpandablePage,
    path: '/DashBoardNonExpandablePage'
  },
  DashBoardDayInLifeVideo: {
    component: DashBoardDayInLifeVideo,
    path: '/DashBoardDayInLifeVideo'
  },
  StudentEnrollmentFormStepper: {
    component: StudentEnrollmentFormStepper,
    path: '/StudentEnrollmentFormStepper'
  },
  Checkout: {
    component: Checkout,
    path: '/Checkout'
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      drawerOpen: false, // Initial state for the drawer
    };
  }



  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
       {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
