import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RecommendationEngineController, {
  Props,
} from "./RecommendationEngineController";

export default class RecommendationEngine extends RecommendationEngineController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getDefaultSettingProducts();
    this.getCustomSettingProducts();
    // Customizable Area End
  }

  // Customizable Area Start
  renderProductItem = (
    product: {id: string, attributes: {main_image: string, base_price: string, compare_at_price: string, name: string}},
    index: number
    ) => {
    return (
      <Grid item xs={4} key={`${product.id}-${index}`} id={`ProductItemGrid-${index}`}>
        <Card>
          <CardActionArea>
            <CardMedia
              image={product?.attributes?.main_image}
              style={{ height: "200px" }}
              title="Contemplative Reptile"
            />
            <CardContent>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography gutterBottom variant="body2" component="span"
                  style={{ fontSize: "18px", fontWeight: "bold" }}>
                  ${product?.attributes?.base_price}
                </Typography>
                <Typography gutterBottom variant="body2" component="span"
                  style={{ marginLeft: "10px", fontSize: "14px", alignSelf: "flex-end" }}>
                  M.R.P:
                </Typography>
                <Typography gutterBottom variant="body2" component="span"
                  style={{ fontSize: "14px", alignSelf: "flex-end", textDecoration: "line-through" }}>
                  ${product?.attributes?.compare_at_price}
                </Typography>
              </div>

              <Typography variant="body2" color="textSecondary" component="p">
                {product?.attributes?.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Grid container spacing={3} id="ProductListGrid">
              {
                this.state.productList.map((productItem: {id: string, attributes: {main_image: string, base_price: string, compare_at_price: string, name: string}}, index: number) => {
                  return (
                    this.renderProductItem(productItem, index)
                  )
                })
              }
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Poppins-Medium, sans-serif",
    alignItems: "center",
    paddingBottom: "30px",
    flexDirection: "row",
    display: "flex",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
    height: "100px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonStyle: {
    height: "45px",
    width: "100%",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
