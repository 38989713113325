// Customizable Area Start
import React, { useRef, useState } from "react";
import {
    Box,
    Button,
    Typography,
    IconButton,
    Grid
} from "@material-ui/core";
import {
    chat_bubble, call_bubble, robot,
} from '../../blocks/dashboard/src/assets';
import Ticketsystem2 from "../../blocks/ticketsystem2/src/Ticketsystem2.web";


const ChatBot = () => {
    const [open, setOpen] = useState(true);
    const childRef = useRef<Ticketsystem2>(null)

    const handleOpen = () => {
        if (childRef.current) {
            setOpen(false)
            childRef.current.handleOpenModal(true);
        }
    };
    return (
        <Grid item xs={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} >
            {open &&
                <Grid item xs={12} sm={6} style={{ padding: '0.6em', border: '1px  solid #f1f1f5', boxShadow: '0px 5px 15px rgba(68,68,79,0.1' }}>
                    <IconButton onClick={() => setOpen(false)} style={{ marginTop: '-30px', marginLeft: '-30px' }}>
                        <Box style={{ borderRadius: '50px', color: '#fff', backgroundColor: '#B98ED6', width: '25px', height: '25px' }}>
                            <small>x</small>
                        </Box>
                    </IconButton>
                    <Box >
                        <Typography style={webStyle.chatText}>Got questions about HG or any of our programs</Typography>
                    </Box>
                    <hr>
                    </hr>
                    <Box style={{ ...webStyle.robotImgBox, flexWrap: 'wrap' }}>
                        <img src={robot}></img>
                        <Button variant='contained' style={{ textTransform: 'none', ...webStyle.btnChat }} onClick={() => handleOpen()}>Chat Now</Button>
                    </Box>
                </Grid>
            }
            <Grid item xs={12} sm={1}>
                <IconButton style={{ marginRight: '0.5em' }} onClick={() => setOpen(!open)}>
                    <img src={chat_bubble}></img>
                </IconButton>
            </Grid>
            <Grid item xs={12} sm={1}>
                <IconButton style={{ marginLeft: '0.5em' }}>
                    <img src={call_bubble}></img>
                </IconButton>
            </Grid>
            <Ticketsystem2 navigation={undefined} id={""} ref={childRef}/>
        </Grid>
    )
}


const webStyle = {
    robotImgBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',       
    },
    chatText: {
        color: '#44444f',
       fontFamily: "'Poppins-SemiBold', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: "18px",
      letterSpacing: "0.06666667px"
    },
    btnChat: {
        width: '104px',
        height: "28px",
      fontFamily: "'Poppins-SemiBold', sans-serif",
        fontSize: "12px",
        fontWeight: 500,
        color: '#fff',
        borderRadius: "10px",
        backgroundColor: "#fab03d",
        margin: '0.6em'
    },

}

export default ChatBot;
// Customizable Area End