import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AttachFile from "@material-ui/icons/AttachFile";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { emptyUser, file } from "./assets";
import { ChatUser, GroupedMessages, IChatData } from "./ChatViewController";
import HorizontalLine from "../../../components/src/HorizontalLine.web";
import GetAppIcon from '@material-ui/icons/GetApp';

const theme = createTheme({
  palette: {
    primary: {
        main: "#nnn",
        contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatViewController, { IMessage, Props } from "./ChatViewController";
import { configJSON } from "./ChatController";

// Customizable Area Start
const getChatMessage = (element:ChatUser) => {
  if (element.chat_dialog_id == null) {
    return "Start a new chat";
  }
  
  if (element.attachment_type && element.message) {
    return element.message + " " + element.attachment_type;
  }
  
  if (element.attachment_type) {
    return element.attachment_type.charAt(0).toUpperCase() + element.attachment_type.slice(1);
  }
  
  if (element.message && element.message.length > 25) {
    return element.message.substring(0, 25) + "...";
  }
  
  return element.message;
};

const userListName = (element: ChatUser) => {
  return (
    <div style={webStyles.avtarStyle}>
      <Typography style={webStyles.listItemStyle as React.CSSProperties}>{element.name && element.name.length > 25 ? element.name.substring(0, 15) + "..." : element.name}</Typography>
      <Typography variant="caption" style={{color: 'black', fontFamily: 'Poppins, sans-serif'}}>{element.chat_time && element.chat_time.replace(" ", "")}</Typography>
    </div>
  )
}

const unreadMessages = (element: ChatUser) => {
  return (
    <div style={webStyles.divStyles}>
      <span
        style={{
          ...webStyles.spanStyles,
          color: element.unread_messages > 0 ? 'black' : 'rgba(0, 0, 0, 0.54)',
          fontWeight: element.unread_messages > 0 ? 700 : 400,
        }}
      >
        {getChatMessage(element)}
      </span>
      {element.unread_messages > 0 && <span style={webStyles.unreadCount}>{element.unread_messages}</span>}
    </div>
  )
}
// Customizable Area End

export default class ChatView extends ChatViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUser = () => {
    const { userList,selectedChatUser} = this.state;
    return(
    <Box sx={webStyles.leftChatBox}>   

    {!this.state.usersLoading ? 
      <List style={webStyles.listStyle as React.CSSProperties}>
        { userList && userList.map((element, index) => {
          const isSelected = selectedChatUser && element.id === selectedChatUser.id; 
          return (
            <Box>
            <ListItem
              button
              key={element.id}
              data-test-id={`userListItem-${index}`}
              style={{
                ...webStyles.userChatContainer,
                backgroundColor: isSelected ? 'rgb(145, 103, 172)' : 'transparent',
                border: isSelected ? '' : '2px solid #F0EFF3',
              }}
              onClick={() => this.btnSelectChatUser(element)}
            >
              <Avatar alt='Icon' src={element.photo ? element.photo : emptyUser} style={webStyles.userAvatar} data-test-id={`userAvatar-${index}`} />
              <div style={webStyles.divStyleAvtar}>

                {userListName(element)}
                {unreadMessages(element)}
              </div>
            </ListItem>
              </Box>
          );
        })}
      </List>
      : (
        <div style={webStyles.circularProgress}>
          <CircularProgress />
        </div>
      )
    }
  </Box>)
  }
  renderMessages = () => {
    const { chatData } = this.state;
  
    return (
      <>
        {this.state.chatLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }} data-test-id="loadingIndicator">
            <CircularProgress />
          </div>
        ) : (
          <>
           {chatData && (() => { 
             const reversedChatData = this.reverseChatData()

             return reversedChatData && reversedChatData.map((item: GroupedMessages, index: number) => (
         
              <Box key={`group-${index}`} data-test-id="messageContainerComponent">
                <Box style={{ textAlign: 'center' }}>
                  <Typography style={webStyles.chatDate} data-test-id="chatDate">
                    {Object.keys(item)[0]}
                  </Typography>
                </Box>
                {(item )[Object.keys(item)[0]].map((element:IChatData, idx: number) => (
                  (element.message != null || element.attachment_type) && 
                  <Box
                    data-test-id="messageComponent"
                    style={{ display: 'flex', justifyContent: element.sender_qb_id === this.state.selectedChatUser?.qb_id ? 'start' : 'end' }}
                    key={`message-${idx}`}
                  >
                      {this.renderAttachment(element)}
                  </Box>
                ))}
              </Box>
             ));
            })()}
          </>
        )}
      </>
    );
  };
  renderAttachment(element: IChatData) {
    return (
      <>
        {(element.file && element.attachment_type) && element.attachment_type.includes('image') && (
          <Box sx={webStyles.messageBoxContainerNew}>
            <Box style={{ display: 'flex', alignItems: 'baseline' }}>
              <img src={element.file} data-test-id="img-click" alt="attached image" style={{ maxWidth: 400, maxHeight: 200, marginBottom: '5px', cursor: 'pointer' }} onClick={() => this.downloadDocument(String(element.file))} />
              <GetAppIcon data-test-id="img-click" style={{ cursor: 'pointer', marginBottom: '2px' }} onClick={() => this.downloadDocument(String(element.file))} />
              <Typography variant="subtitle2" style={{ fontSize: 'small', alignItems: 'center', color: 'grey', marginLeft: 12, fontFamily: 'Poppins, sans-serif' }} data-test-id="msgDate">
                {element.created_at}
              </Typography>
            </Box>
            <Typography>
              {element.attachment_name}
            </Typography>
          </Box>
        )}
        {element.file && !element.attachment_type?.includes('image') && (
          <>
            <Box sx={webStyles.messageBoxContainerNew}>
              <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Box>
                <img style={{ cursor: 'pointer' }} height={100} width={100} src={file} alt="file" onClick={() => this.downloadDocument(String(element.file))} />
                <GetAppIcon style={{ cursor: 'pointer', marginBottom: '2px' }} onClick={() => this.downloadDocument(String(element.file))} /></Box>
                <Typography variant="subtitle2" style={{ fontSize: 'small', alignItems: 'center', color: 'grey', marginLeft: 12, fontFamily: 'Poppins, sans-serif' }} data-test-id="msgDate">
                  {element.created_at}
                </Typography>
              </Box>
              <Typography>
                {element.attachment_name}
              </Typography>
            </Box>
          </>
        )}
        {!element.file &&
          <Box sx={webStyles.messageBoxContainer}>
            <Box style={{ display: 'inline-block', wordWrap: 'break-word', width: '70%', padding: '10px 10px'}}>
            <Typography style={{ flex: '1', fontSize: 17, color: '#4a4a4a', fontFamily: 'Poppins, sans-serif', lineHeight: 1, whiteSpace: 'pre-wrap', wordWrap: 'break-word', }} data-test-id="messageContent" >{element.message}</Typography>
              </Box>
            <Typography variant="subtitle2" style={{ fontSize: 'small', alignItems: 'center', color: 'gray', marginLeft: -2, textAlign: 'end', width: '100px', fontFamily: 'Poppins, sans-serif' }} data-test-id="msgDate">
              {element.created_at}
            </Typography>
          </Box>
        }
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedChatUser } = this.state;
    return (
      <div style={webStyles.container}>
        <AppWithoutDrawer navigation={this.props.navigation} />
        <Sidebarmenu />
        <ThemeProvider theme={theme}>
          <Container maxWidth={false}>
            <Box style={{ marginTop: '85px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography style={webStyles.welcomeTextStyle} >Get in touch with team</Typography>
            </Box>
            <Grid container style={{ display: 'flex' }}>
              <HorizontalLine />
            </Grid>
            <Box sx={{ display: "flex", marginTop: '25px'}}>
            {this.renderUser()}        
              <Box style={{...webStyles.rightChatBox, flexDirection: 'column'}}>
                <Box style={webStyles.userToolbar}>
                  <Avatar alt={'Icon'} src={selectedChatUser && selectedChatUser.photo ? selectedChatUser.photo : emptyUser} style={webStyles.userAvatar} />
                  <div>
                    <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Poppins, sans-serif'}}>{selectedChatUser && selectedChatUser.name}</Typography>
                  </div>
                </Box>
                <Box sx={{ position: 'relative', ...webStyles.messageContainer }} >
                  {this.state.chatLoader &&
                    <div style={{ position: 'fixed', top: '50%', left: '63%' }} data-test-id="loadingIndicator">
                      <CircularProgress />
                    </div>
                  }
                  {this.renderMessages()}
                </Box>
                <Box sx={webStyles.messageDiv}>
                  <Box sx={webStyles.bottomContainer}>
                    <Box sx={webStyles.messageInputView}>
                      <TextField
                        id="standard-textarea"
                        data-test-id={"inputMessage"}
                        placeholder="Type a message"
                        value={this.state.message}
                        InputProps={{ disableUnderline: true }}
                        fullWidth={true}
                        onChange={(event) =>
                          this.handleMessageChange(event.target.value ?? "Hi")
                        }
                        style={webStyles.input}
                        multiline
                      />
                    </Box>
                    <IconButton
                      data-test-id="btnInsertImage"
                      onClick={this.handleInsertImage}
                      style={{transform: 'rotate(45deg)'}}
                    >
                      <AttachFile fontSize="medium" />
                    </IconButton>
                    <Input
                      data-test-id="FileInput"
                      inputRef={this.fileInputRef}
                      style={webStyles.fileInput}
                      type="file"
                      inputProps={{ accept: "image/png, image/jpeg, application/pdf, video/mp4" }}
                      onChange={this.handleFileChange}
                    />
                    <Button
                      data-test-id="btnSendMessage"
                      variant="contained"
                      style={webStyles.sendMessageBttn}
                      disabled={this.state.message.length === 0}
                      onClick={this.handleSendMessage}
                    >
                      {configJSON.sendText}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: { 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    width: '100%', 
    height: '86vh'
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    width: 600,
    border: "1px solid #ccc",
    justifyContent: "space-between",
    padding: 20,
    alignItems: "center",
  },
  headerButtonView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  leftChatBox: {
    width: '30%', 
    border: '0.5 grey solid', 
    display: "flex", 
    justifyContent: "center"
  },
  rightChatBox: {
    width: '69%',
    paddingBottom: 10,
    height:'15%', 
    display: "flex", 
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.5)'
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    width: '100%',
    height: 'calc(100vh - 300px)',
    overflow: "auto",
    scrollbarWidth: 'thin'
  },
  chatDate: { 
    marginBottom: 20, 
    marginTop: 20, 
    color: '#5b5b5b', 
    fontSize: 12, 
    fontWeight: 700,
  },
  messageBoxContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: 15,
    borderWidth: 1,
    padding: 10,
    marginLeft: 25,
    marginTop: 4,
    marginBottom: 4,
    marginRight: 25,
    boxShadow: '#9d9d9d8a 0px 0px 8px',
    maxWidth: '50%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  messageBoxContainerNew: {
    flexDirection: "row",
    alignItems: 'center',
    backgroundColor: "white",
    borderRadius: 15,
    borderWidth: 1,
    padding: 10,
    marginLeft: 25,
    marginTop: 4,
    marginBottom: 4,
    marginRight: 25,
    boxShadow: '#9d9d9d8a 0px 0px 8px',
    width: 'max-content',
  },
  messageInputView: {
    width: "100%",
    marginRight: 10,
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    marginTop: 6,
    width: '90%',
    borderRadius: 12,
    elevation: 6,
    background: "rgba(236, 235, 235, 0.57)",
  },
  sendMessageBttn: { 
    maxHeight: '95%', 
    width: '35%', 
    backgroundColor: '#ffb631', 
    color: 'white', 
    fontSize: 12, 
    borderRadius: 10, 
    fontWeight: 700
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewModalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    width: '80%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  userToolbar: {
    width: '100%', 
    display: 'flex', 
    alignItems: 'center',
    borderBottom: '0.3px #cacaca solid'
  },
  previewImage: {
    width: "100%",
    height: "auto"
  },
  previewInput: {
    marginLeft: 10,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  previewModalButtons: {
    margin: 10,
    display: "flex",
    flexDirection: "row"
  },
  unreadMessage: {
    color: "red",
  },
  readMessage: {
    color: "black",
  },
  fileInput: {
    display: "none",
  },
  userChatContainer: {
    padding: '6px 10px',
    marginBottom: 2,
    height: '15%',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  userAvatar: {
    marginTop: '1%',
    marginBottom: '1%',
    marginRight: '3%',
    marginLeft: '2%',
    borderRadius: 8
  },
  input: {
    boxShadow: '0px 0px 2px #cacaca',
    borderRadius: 12,
    background: 'white',
    fontSize: 12,
    paddingLeft: 12,
    maxHeight: '100px',
    overflow: 'auto'
  },
  unreadCount: {
    marginBottom: 4,
    marginRight: 6,
    padding: '1px 6px',
    backgroundColor: '#99cb51',
    borderRadius: 12,
    fontWeight: 600,
    fontSize: 12,
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
  },

  listStyle :{
    width: '90%',
     maxHeight: '76vh', 
     overflowY: 'scroll',
     scrollbarWidth: 'thin'
  },
  circularProgress : {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100px' 
  },
  divStyles : {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between' 
  },
  listItemStyle : {
     fontWeight: 'bold',
     color: 'black',
     fontFamily: 'Poppins, sans-serif',
  },
  spanStyles : {
    fontSize: '0.75rem',
    fontFamily: 'Poppins, sans-serif',
  },
  avtarStyle : {
     display: 'flex', 
     alignItems: 'center', 
     justifyContent: 'space-between'
  },
  divStyleAvtar : {
    width:"100%"
  },
  welcomeTextStyle: {
    color: '#171725',
    fontFamily: "'Poppins-SemiBold', sans-serif",
    fontSize: '24px',
    fontWeight: 600,
  },
  messageDiv: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  preformatted: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  }
};
// Customizable Area End