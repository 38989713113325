import React, { CSSProperties } from "react";

import {

  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  IconButton,
  styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import moment from 'moment';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Popover from "@material-ui/core/Popover";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
// import CheckBoxOutlinedIcon from '@material-ui/core/CheckBoxOutlined';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import ChatBot from "../../../components/src/ChatBot.web";
import CheckIcon from "@material-ui/icons/Check";

const theme: any = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "none",
          transition: "none",
          content: "none",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiCardActions: {
      root: {
        padding: "0px",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
        padding: "0px",
        transition: "none",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.3rem",
      },
    },
    MuiContainer: {},
  },

});


const Boxcontainer = styled(Box)({
  marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
  "@media (max-width: 768px)": {

    flexWrap: "wrap",
    flexDirection: "column"
  }


})
const Boxcontainertwo = styled(Box)({
  display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "auto"



})
// Customizable Area End

import Customform3Controller, {
  Props,

} from "./Customform3Controller";

class Customform3 extends Customform3Controller {
  popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End 
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <AppWithoutDrawer navigation={this.props.navigation} />
          <Sidebarmenu />
          <ThemeProvider theme={theme}>
            <Container maxWidth={false} style={{ alignItems: 'center', justifyContent: 'center' }} >
              <Grid container style={{ display: 'block' }}>
                <Grid item>
                  <Container>
                    <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                      <Boxcontainer>
                        <Typography style={webStyle.welcomeTextStyle} >Students </Typography>
                        <Button variant='contained' onClick={this.NavigateCheckout} style={{ textTransform: 'none', ...webStyle.ProceedCheckout }}>Proceed to Checkout</Button>
                      </Boxcontainer>
                      <Grid container style={{ display: 'flex' }}>
                        <Grid item xs={4}>
                          <Box style={{ width: '100%', height: '3px', backgroundColor: '#fab03d' }}><p></p></Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box style={{ width: '100%', height: '3px', backgroundColor: '#9167ac' }}><p></p></Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box style={{ width: '100%', height: '3px', backgroundColor: '#99cb51' }}><p></p></Box>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={webStyle.textStudentsListStyle}>Students List</Typography>
                        <Box>
                          <span style={webStyle.textShowStyle}>Show: </span>
                          <Select
                            test-ID="valueSelect"
                            value={this.state.valueShow}
                            onChange={this.handleChangeShow}
                            displayEmpty
                            style={{ fontFamily: "Poppins, sans-serif",}}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null 
                            }}
                          >
                            <MenuItem style={{ fontFamily: "Poppins, sans-serif",}} value={3}>3</MenuItem>
                            <MenuItem style={{ fontFamily: "Poppins, sans-serif",}} value={5}>5</MenuItem>
                            <MenuItem style={{ fontFamily: "Poppins, sans-serif",}} value={10}>10</MenuItem>
                            <MenuItem style={{ fontFamily: "Poppins, sans-serif",}} value="All">All</MenuItem>
                          </Select>
                        </Box>
                      </Grid>

                      <Grid container style={webStyle.gridContentContainerStyle}>
                        {
                          this.state.loading ?
                            <Boxcontainertwo>
                              <CircularProgress color="inherit" />
                            </Boxcontainertwo>
                            :

                            <Grid item xs={12}>
                              <ThemeProvider theme={theme}>

                                {

                                  this.filterStudents().map((get_studentdata: any, id: any, index: any) => (
                                    <Card key={id} variant="outlined" style={webStyle.cardContainerStyle}>
                                      <Grid container style={webStyle.cardGridContainerStyle}>
                                        {/* ---------------grid container starts ----------------------- */}
                                        <Grid item md={2}>
                                          <CardContent>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                              <IconButton data-test-id="checkboxStudent" onClick={()=>this.changecheckboxState(get_studentdata.id)} style={{ padding: '0px' }}>
                                                {
                                                  this.state.selectedStudentIds.includes(get_studentdata.id) ?
                                                  <CheckBoxOutlined style={{ color: "#9167ac", marginRight: "10px" }} />
                                                  : <CheckBoxOutlineBlank style={{ color: "#9167ac", marginRight: "10px" }} />
                                                }
                                              </IconButton>
                                              <Avatar
                                                alt="student avatar"
                                                src={get_studentdata?.attributes.id_picture?.url} style={webStyle.cardAvatarStyle} />
                                              <Box>
                                                <Typography style={webStyle.cardInnerTextHeader}>{get_studentdata.attributes.first_name}</Typography>
                                                <Box width={"100px"}>
                                                  <Typography style={webStyle.cardInnerTextSubText}>{"Grade Level:"} {get_studentdata.attributes.previous_school.data.attributes.grade}</Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </CardContent>
                                        </Grid>
                                        <Grid item md={1}>
                                          <CardContent>
                                            <Box>
                                              <Typography style={webStyle.cardInnerTextHeader}>Program</Typography>
                                              <Typography style={webStyle.cardInnerTextSubText}>{get_studentdata?.attributes.program_detail?.data?.attributes?.program}</Typography>
                                            </Box>
                                          </CardContent>


                                        </Grid>
                                        <Grid item md={2}>
                                          <CardContent style={{ display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                            <Box>
                                              <Typography style={webStyle.cardInnerTextHeader}>School Year</Typography>
                                              <Typography style={webStyle.cardInnerTextSubText}>{"SY "}{get_studentdata?.attributes?.school_year}</Typography>
                                            </Box>
                                          </CardContent>
                                        </Grid>
                                        <Grid item md={2}>
                                          <CardContent>
                                            <Box>
                                              <Typography style={webStyle.cardInnerTextHeader}>Submitted</Typography>
                                              <Typography style={webStyle.cardInnerTextSubText}>{this.formatSchoolYearDate(get_studentdata.attributes.submitted_date)}</Typography>
                                            </Box>
                                          </CardContent>
                                        </Grid>
                                        <Grid item md={2} style={{ padding: '16px 0' }}>
                                          <CardActions>

                                            {/* --------------------------- here is snackbar and alert--------------------------- */}
                                            <Button
                                              style={{ textTransform: "none", backgroundColor: "#64b7e2", ...webStyle.btnStatusid2 }}


                                            >
                                              <span
                                                style={{ textTransform: "none", ...webStyle.buttonStylescommon }}
                                              >Pending for payment</span>
                                            </Button>


                                          </CardActions>
                                        </Grid>
                                        <Grid item md={1} style={{ padding: '16px 0' }}>
                                          <CardActions style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                            <IconButton
                                              test-id="openpop"

                                              onClick={(e: any) => this.openPopover(e, get_studentdata.id)}

                                            >
                                              <MoreHorizIcon style={{ color: '#92929d' }} />
                                            </IconButton>
                                            <Popover
                                              test-Id="popoverThenClick"
                                              elevation={1}
                                              open={Boolean(this.state.popoverAnchor)}
                                              anchorEl={this.state.popoverAnchor}
                                              onClose={this.handlePopoverClosepop}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                              }}
                                              >
                                              <Box style={webStyle.popoverBoxStyle}>
                                                <Button
                                                  size='small'
                                                  variant='text'
                                                  test-Id="ViewStudent"
                                                  style={webStyle.popoverButTextStyle} onClick={() => this.getStudentId(get_studentdata.attributes.id)}>
                                                  View
                                                </Button>
                                                <button style={webStyle.cancelButton} onClick={this.handleClosePopover}>x
                                                </button>
                                                <Button size='small' variant='text' style={webStyle.popoverButTextStyle}>Edit</Button>
                                                <Button size='small' variant='text' style={webStyle.popoverButTextStyle} >Reenroll</Button>

                                              </Box>
                                            </Popover>


                                          </CardActions>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  ))

                                }

                              </ThemeProvider>
                            </Grid>


                        }

                        {
                          !this.state.loading && this.state.studentsListdata.length <= 0 && <p style={webStyle.boldFont}>No records found</p>
                        }


                      </Grid>

                      <Box style={webStyle.chatbot}>
                        <ChatBot />
                      </Box>

                    </Container>
                  </Container>
                </Grid>
              </Grid>
            </Container >
          </ThemeProvider>

        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle: Record<string, CSSProperties> = {
  buttonStylescommon: {
    color: "#ffffff",
    fontFamily: "Poppins-Semibold, sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    textAlign: "center",
    width: "100%"
  },
  popoverBoxStyle: {
    display: "block",
    alignItems: "flex-start",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    width: "120px",
  },
  popoverButTextStyle: {
    color: "#1b1b1b",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "14px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  cardContainerStyle: {
    margin: "10px",
  },
  cardGridContainerStyle: {
    //margin: "10px",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    gap: '30px'

  },
  cardAvatarStyle: {
    width: "42px",
    height: "42px",
    marginRight: "10px",
  },
  cardInnerTextHeader: {
    color: "#171725",
    fontFamily: "Poppins-Semibold, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
  },
  cardInnerTextSubText: {
    color: "#92929d",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "0.8vw",
    fontWeight: 400,
  },
  cardInnerTextAuthor: {
    color: "#4bc0cf",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "0.7vw",
    fontWeight: 400,
    textDecoration: "underline",
  },
  btnStatus: {

    width: "100%",
    padding: "0.7rem",
    borderRadius: "10px",
    backgroundBlendMode: "normal"

  },

  btnStatusid2: {

    width: '100%',
    padding: '0.7rem',
    borderRadius: "10px",
    backgroundBlendMode: "normal"

  },


  btnStatusid3: {
    width: "100%",
    padding: "0.7rem",
    borderRadius: "10px",
    backgroundBlendMode: "normal"

  },

  btnCross: {
    cursor: "pointer",
    color: "#1b1b1b",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "14px",
    padding: "0",
    fontWeight: 700,
  },
  gridContentContainerStyle: {
    marginTop: '40px',
    border: '1px solid #f1f1f5',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
    padding: '20px',

  },

  textShowStyle: {
    height: '22px',
    color: '#92929d',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  textStudentsListStyle: {
    height: '36px',
    color: '#171725',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  ProceedCheckout: {
    minWidth: '235px',
    height: '52px',
    borderRadius: '10px',
    border: "none",
    backgroundColor: "#fab03d",
    color: '#fafafb',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: ' 16px',
    fontWeight: 600,
    backgroundBlendMode: 'normal',
  },
  fileNames: {
    borderRadius: "10px",
    height: '50px',
    padding: "0",
    border: "1px solid #e2e2ea",
    backgroundColor: '#9167ac',
  },
  Checkout: {
    width: '235px',
    height: '52px',
    borderRadius: '10px',
    border: "none",
    backgroundColor: "#fab03d",
    color: '#fafafb',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: ' 16px',
    fontWeight: 600,
    backgroundBlendMode: 'normal',
  },
  StudentsDetailsHeading: {
    color: " #171725",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: " 14px",
    fontWeight: 400,
    marginTop: '8px',
  },
  namesStyle: {
    color: "#ffff",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    flexGrow: 1,

  },
  // namesStyle1: {
  //      fontFamily: "'Poppins-Medium', sans-serif",
  //     fontSize: "16px",
  //     fontWeight: 600,
  //     flexGrow: 1,
  // },
  // namesStyle2: {
  //      fontFamily: "'Poppins-Medium', sans-serif",
  //     fontSize: "14px",
  //     fontWeight: 500,
  //     flexGrow: 1,
  //     textTransform: "capitalize",
  //     // paddingLeft: "3em"
  // },

  welcomeTextStyle: {
    //marginTop: '50px',
    color: '#171725',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '24px',
    fontWeight: 600,
    width: "100%"
  },
  heading: {
    color: " #555555",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "22px",

  },
  subheading: {
    color: "#424244",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: '2em',
    paddingLeft: '24px'
  },
  mainheading: {
    color: "#424244",
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0.075px",
    marginTop: "2em",
    marginBottom: "1em",
    paddingLeft: '24px'
  },
  GridWrapper: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    padding: "2em",
    maxWidth: "auto",

    // boxShadow: "0px 5px 13px 0px #1a44444f",
  },
  GridWrapper1: {
    borderRadius: "15px",
    background: "#ffffff",
    border: "1px solid #f1f1f5",
    padding: '0em',
    boxShadow: "1px 0px 14px 2px #e2e2e2",
  },
  chatbot: {
    justifyContent: 'end',
    display: 'flex',
    marginTop: '75px'
  },
  cancelButton: {
    position: "absolute",
    right: "3px",
    background: "rgb(185, 142, 214)",
    color: "white",
    borderRadius: '50%',
    top: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  boldFont: {
    fontWeight: 600
  }
}
export default Customform3
// Customizable Area End
