import React, { useState, useEffect } from "react";

import {
    // Customizable Area Start
  Box,
  Typography,
  IconButton,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const theme = createTheme({

  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCardMedia: {
      media: {
        width: '42px',
        height: '48px',
      },
    },

    // MuiIconButton: {
    //   root: {
    //     padding: '0px',
    //   }
    // },
    // MuiButtonBase: {
    //   root: {
    //     padding: '0px',
    //   }
    // },

  },
});


const FaqCard = ({view,questionNo,answer}: any) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
   };
    
   useEffect(() => {
    setExpanded(view)
     
   }, [view])
   
  
  return (

    // Customizable Area Start
    <>
    
      <ThemeProvider theme={theme}>

        <Card elevation={0} style={webStyle.faqCardStyle}>
          <CardContent>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography style={webStyle.styleQuestion}>{questionNo} </Typography>
              </Box>

              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                {expanded ? <RemoveCircleOutlineIcon style={{color: '#9167ac'}}  /> : <AddCircleOutlineIcon style={{ color: '#99cb51' }} />}
               
              </IconButton>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={webStyle.cardContentStyle}>
              <Typography align='justify' style={webStyle.styleAnswer} > {answer} </Typography>
            </CardContent >
          </Collapse>
          </CardContent>
      
        </Card>

      </ThemeProvider>

    </>
  );
}

// Customizable Area Start
const webStyle = {

  faqCardStyle: {
    // marginTop: '20px',
    // marginBottom: '30px',
    borderBottom: '1px solid #e2e2ea',
  },
  cardContentStyle: {
    paddingTop: 0,
     paddingBottom: '1em',
    paddingLeft: '0em',
    paddingRight: '1em',
  },

  styleAnswer: {
    color: '#171725',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    marginBottom: '10px',
    paddingRight: '3.5em',
    },

  styleQuestion: {
    color: '#171725',
    fontFamily: "'Poppins-Medium', sans-serif",
    fontSize: '14px',
    fontWeight: 600,
  }

};

export default FaqCard;
// Customizable Area End