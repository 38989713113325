//@ts-nocheck
import React, { useEffect, useState } from "react";
// Customizable Area Start 
import {
  // Customizable Area Start
  Container,

  Grid,
  // Customizable Area End
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import {

  Box,
  Button,

  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,


  Collapse,

  styled
} from "@material-ui/core";

import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { studentgreen, account_green, bulletsgreen, bullets, overviewicon, students_icon, getin_touchicon, overviewgreen, Faqgreen, Faqicon, account_setting, H_omeicon, home_icon } from '../../blocks/dashboard/src/assets'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getStorageData } from "../../framework/src/Utilities";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,


    }),

  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',



  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),






  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
    backgroundColor: "#99cb51",
    overflowX: 'hidden',
    overflowY: "-moz-hidden-unscrollable",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      borderRadius: "0px 0px 40px 0px",
      backgroundColor: "#99cb51",
    },
    [theme.breakpoints.down('sm')]: {
      display: "none !important"
    },
    // [theme.breakpoints.up('sm')]: {
    //   display:"block !important"},
    // },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  filter_width: {
    width: "19px",
    height: "18px",
    cursor:"pointer"
  },
  hoverEffect: {
    " &:hover ": {
      /* Apply hover effect using the hoverEffect object */


      backgroundColor: "rgba(0, 0, 0, 0.04)"


      // borderLeft: "0.4rem solid rgb(153, 203, 81)"
    }
  },

  bulletsStyle: {
    fill: "currentColor",
    width: "0.5em",
    height: "0.5em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: "0",
    userSelect: "none",

  },
  meattheteam: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#ffffff",
    fontFamily: "Poppins, sans-serif",
    textTransform: "lowercase"
  }


}));
const Boxcontainer = styled(Box)({
  fontSize: " 14px",
  fontWeight: "300",
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
  fontFamily: "Poppins, sans-serif",
  paddingBottom: "0px", color: "#757575"

})
const Boxcontainerbutton = styled(Box)({
  backgroundColor: "rgb(184, 184, 184)",
  fontFamily: "Poppins, sans-serif",
  fontSize: "12px",
  fontWeight: "500",
  marginLeft: "3rem",
  marginTop: "1rem",
  textAlign: "center",
  width: "127px",
  borderRadius: "10px"
})
const Boxcommonthree = styled(Box)({
  display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "2rem"
})
const Boxcollapseparent = styled(Box)({
  display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "7rem"
})
const Boxcollapsechild = styled(Box)({
  display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1rem"
})
// Customizable Area End

export default function Sidebarmenu() {

  // Customizable Area Start
  const classes = useStyles();
  const theme = useTheme();
  //   console.log(routes)
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false)
  const [watchComplete, setWatchComplete] = useState(false)
  // const complete = localStorage.getItem('complete') === 'true';
  const [open, setOpen] = useState(false);
  const [selectedcolor, setSelectedcolor] = useState("");
  const [selectedcolorstyle, setSelectedcolorstyle] = useState("");
  const [selectedcolorstyleone, setSelectedcolorstyleone] = useState("");
  const [selectedcolorstylefaqaccount, setSelectedcolorstylefaqaccount] = useState("");
  const [getintouchcolorstyle, setGetintouchcolorstyle] = useState("")
  const [subArrayState, setSubArrayState] = React.useState({
    Home: false,
    Overview: false,
    Students: false,
    touch: false,
    FAQs: false,
    Account: false,
  });

  const routes = [

    {
      id: 0,
      routeName: watchComplete ? "/DashBoardDayInLifeVideo" : "/DashBoardNonExpandablePage",
      isActive: false,
      title: "Home",
      icon: selectedcolorstyle === 0 ? home_icon : home_icon ,//grey
      route: ["home"],
      key: "Home",

    },
    {
      id: 1,
      routeName: "/overview",
      isActive: false,
      title: "Overview",
      icon: selectedcolorstyle === 1 ? overviewgreen : overviewicon,
      route: ["overview"],
      key: "Overview",
      subArray: [
        { id: 0, nav: "MainDrawer", icon: selectedcolorstyleone === 0 ? bulletsgreen : bullets, routeName: "/DashboardIntroVideo", title: "Orientation", isActive: false },
        { id: 1, nav: "MainDrawer", icon: selectedcolorstyleone === 1 ? bulletsgreen : bullets, routeName: "/hgformula", title: "HG Formulae", isActive: false },
        { id: 2, nav: "MainDrawer", icon: selectedcolorstyleone === 2 ? bulletsgreen : bullets, routeName: "/Exploreprograms", title: "Explore Programs", isActive: false },
        { id: 3, nav: "MainDrawer", icon: selectedcolorstyleone === 3 ? bulletsgreen : bullets, routeName: "/HgFormula#link3", title: "Info Pack & E-book", isActive: false },
        { id: 4, nav: "MainDrawer", icon: selectedcolorstyleone === 4 ? bulletsgreen : bullets, routeName: "/HgFormula#link4", title: "About HG", isActive: false },

        // ... add more subaArray items
      ],
    },
    {
      id: 2,
      routeName: "/Customform3",
      isActive: false,
      title: "Students",
      icon: selectedcolorstyle === 2 ? studentgreen : students_icon,
      route: ["students"],
      key: "Students",
    },



    // Add more route objects as needed
  ];

  const routesone = [
    {

      id: 1,
      routeName: "get-in-touchs",
      isActive: false,
      title: "Get in touch with team",
      icon: getintouchcolorstyle === 1 ? getin_touchicon : getin_touchicon,
      route: ["get-in-touch"],
      key: "touch",
      subArrayone: [
        { id: 0, nav: "MainDrawer", icon: selectedcolor === 0 ? bulletsgreen : bullets, routeName: "/ChatView", title: "Chat", isActive: false },
        { id: 1, nav: "MainDrawer", icon: selectedcolor === 1 ? bulletsgreen : bullets, routeName: "/ticket-status", title: "Tickets", isActive: false },
        // ... add more subArray items
      ],
    },
  ]
  const routestwo = [
    {
      id: 1,
      routeName: "/faqs",
      isActive: false,
      title: "FAQs",
      icon: selectedcolorstylefaqaccount === 1 ? Faqgreen : Faqicon,//grey
      route: ["faqs"],
      key: "FAQs",
      bulletsgreen: bullets,
    },
    {
      id: 2,
      routeName: "/accountsettings",
      isActive: false,
      title: "Account Settings",
      icon: selectedcolorstylefaqaccount === 2 ? account_green : account_setting,//grey
      route: ["account-settings"],
      key: "Account",
      bulletsgreen: bullets,
    },
  ]


  const handleDrawerOpen = () => {
    setOpen(true);

  };

  const token = async() => {
    const drop = await getStorageData('backdrop');
    if (drop !== null ){
      setBackdrop(drop);
    }
  }

  const setButton = async() => {
    const test = await getStorageData('complete');
    if (test === "true") {
      setWatchComplete(true)
    } else {
      setWatchComplete(false)
    }
  }

  useEffect (() => {
    token()
    setButton()
  },[])
  const handleDrawerClose = () => {
    console.log("closefunction")
    setOpen(false);
  };
  const handleSubmenuClick = (title, id) => {



    setSelectedcolorstyle(id)
    setSubArrayState((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
    setSubmenuOpen((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));



  }
  const handleSubmenuClickone = (title, id) => {
    setGetintouchcolorstyle(id)
    setSubArrayState((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
    setSubmenuOpen((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  const handleSubmenuClicktwo = (title, id) => {
    setSelectedcolorstylefaqaccount(id)
    setSubmenuOpen((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  const changeColor = (id) => {
    setOverviewsubmenu(id);
  };
  const changeColorgetintouch = (id) => {
    setSelectedcolor(id);
  };
  const handleSubmenuoreintationClick = (title, id) => {
    setSelectedcolorstyleone(id)
    setSubArrayState((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
    setSubmenuOpen((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  }




  return (
    //Merge Engine DefaultContainer

    <>
      <div>
        <div className="basedondivicehideandshow">

          <Drawer

            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                // marginTop:"4rem"

              },
            }}

            anchor="left"
            open={open}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),

            }}
          >
            {/* //implementation of routes */}
            <span style={{width: '100%'}}>
              {backdrop && <Backdrop style={{ zIndex: 100, color: "#fff" }} open={backdrop}></Backdrop>}
            <div style={{ marginTop: "4.7rem" }}>
              {routes.map((parent: any, index: number, id) => (
                <Box key={parent + index} >
                  <ListItem
                    component={parent.title !== "Overview" && Link}
                    to={parent.routeName}
                    onClick={() => {

                      if (parent.title === "Home") {
                        console.log(open)
                        if (open === false) {
                          console.log(open)
                          handleDrawerOpen()

                        } else {
                          handleDrawerClose()
                          setSubmenuOpen(false)
                        }
                      } else {
                        handleDrawerOpen()
                        console.log("hllow", open)
                      }
                      setSelectedcolorstyleone(""),
                      setSelectedcolorstylefaqaccount("")
                      setGetintouchcolorstyle("")
                      handleSubmenuClick(parent.title, parent.id)
                    }}

                    style={{
                      paddingTop: "1em",
                      gap: "0.4rem",
                      borderLeft: selectedcolorstyle === parent.id ? '0.4rem solid #99cb51' : '0rem solid'
                    }}
                    className={classes.hoverEffect}>
                    < Boxcollapseparent>
                      <Boxcollapsechild style={{ display: "flex", justifyContentL: "flex-start", alignItems: "center", gap: "0.4rem" }}>
                        <ListItemIcon style={{ marginLeft: selectedcolorstyle === parent.id ? '0rem' : '0.4rem', color: parent.isActive && open ? "#99cb51" : "#171725", minWidth: '3.2em', }}>
                          <img src={parent?.icon} alt="HG_application" className={classes.filter_width} />
                        </ListItemIcon>

                        <ListItemText primary={<span style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgb(23, 23, 37)",
                          fontFamily: "Poppins, sans-serif",
                          color: selectedcolorstyle === parent.id ? "#99cb51" : "#171725",

                        }}
                        >{parent.title}</span>} />
                      </Boxcollapsechild>

                      <div>
                        {
                          parent.title === "Overview" ? subArrayState[parent.title] ?
                            <KeyboardArrowUpIcon /> :
                            <KeyboardArrowDownIcon /> : ""
                        }
                      </div>
                    </Boxcollapseparent>

                  </ListItem>


                  {Array.isArray(parent.subArray) && (
                    <Collapse in={submenuOpen[parent.title]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {parent.subArray.map((item, id) => (
                          <>
                            <ListItem key={item.routeName} style={{ paddingLeft: '3.1em', cursor: "pointer" }}
                              component={Link}
                              to={item.routeName}
                              onClick={() => {
                                setSelectedcolorstyle("")
                                handleSubmenuoreintationClick(item.title, item.id);
                              }}
                              className={classes.hoverEffect}>
                              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "2rem" }} >
                                <img src={item?.icon} alt="HG__application" className={classes.bulletsStyle} />
                                <Boxcontainer style={{
                                  color: selectedcolorstyleone === id ? "rgb(153, 203, 81)" : ""
                                }} onClick={() => {

                                  changeColor(id)
                                }

                                }>{item.title} </Boxcontainer>

                              </div>
                            </ListItem>
                          </>

                        ))}
                      </List>
                      </Collapse>
                  )}




                </Box>
              ))}
            </div>
            {/* //implementation of routesone */}
            {routesone.map((child: any, index: number, id: any) => (
              <Box key={child + index} >
                <ListItem
                  component={child.title !== "Get in touch with team" && Link}
                  onClick={() => {

                    if (child.title === "Home") {
                      console.log(open)
                      if (open === false) {
                        console.log(open)
                        handleDrawerOpen()

                      } else {
                        handleDrawerClose()
                        setSubmenuOpen(false)
                      }
                    } else {
                      handleDrawerOpen()
                      console.log("hllow", open)
                    }
                  }}
                  to={child.routeName}
                  style={{ paddingTop: "1em", gap: "0.4rem", borderLeft: getintouchcolorstyle === child.id ? '0.4rem solid #99cb51' : '0rem solid' }} 
                  className={classes.hoverEffect} >
                  <ListItemIcon style={{ marginLeft: getintouchcolorstyle === child.id ? '0rem' : '0.4rem', color: child.isActive && open ? "#99cb51" : "#171725", minWidth: '3.1em', }}>
                    <img src={child?.icon} alt="HG_application" className={classes.filter_width} />
                    {/* {child?.icon} */}
                  </ListItemIcon>
                  <ListItemText primary={<span style={{
                    fontSize: "14px", fontWeight: 500,
                    color: getintouchcolorstyle === child.id ? "#99cb51" : "#171725",
                    fontFamily: "Poppins, sans-serif",
                  }}
                    onClick={() => {
                     
                      setSelectedcolorstyle("")
                      setSelectedcolorstylefaqaccount("")
                      handleSubmenuClickone(child.title, child.id);

                    }}
                  > {child.title} </span>} /> </ListItem>
                {Array.isArray(child.subArrayone) && (
                  <Collapse in={submenuOpen[child.title]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                      {child.subArrayone.map((items, id) => (
                        <>
                          <ListItem key={items.routeName} style={{ paddingLeft: '3em', }}
                            component={Link}
                            to={items.routeName}
                            onClick={() => {
                              setSelectedcolorstylefaqaccount("")
                              handleSubmenuClickone(items.title);
                            }}
                            className={classes.hoverEffect}
                          >
                            <Boxcommonthree>
                              <img src={items?.icon} alt="HG__application" className={classes.bulletsStyle} />

                              <Boxcontainer style={{
                                color: selectedcolor === id ? "rgb(153, 203, 81)" : ""
                              }} onClick={() => changeColorgetintouch(id)}>{items.title}
                              </Boxcontainer>
                              </Boxcommonthree>
                               </ListItem>

                        </>

                      ))}
                    </List>
                     <Boxcontainerbutton>
                      <Button><span className={classes.meattheteam}>Meet the team</span></Button>
                    </Boxcontainerbutton>
                    </Collapse>
                )}
                 </Box>
            ))}
            {routestwo.map((subchild: any, index: number, id) => (
              <Box key={subchild + index}>
                <ListItem
                  component={Link}
                  to={subchild.routeName}
                  onClick={() => {
                    if (subchild.title === "FAQs" || subchild.title === "Account Settings"){
                      console.log(open)
                      if (open === false) {
                        console.log(open)
                        handleDrawerOpen()

                      } 
                    }
                     setSelectedcolor("");
                    setGetintouchcolorstyle("")
                    setSelectedcolorstyle("")
                    handleSubmenuClicktwo(subchild.title, subchild.id);

                  }}
                  style={{ paddingTop: "1em", gap: "0.4rem", borderLeft: selectedcolorstylefaqaccount === subchild.id ? '0.4rem solid #99cb51' : '0rem solid' }} className={classes.hoverEffect} >
                  <ListItemIcon style={{ marginLeft: selectedcolorstylefaqaccount === subchild.id ? '0rem' : '0.4rem', color: subchild.isActive && open ? "#99cb51" : "#171725", minWidth: '3.1em', }}>
                    <img src={subchild?.icon} alt="HG_application" className={classes.filter_width} />
                  </ListItemIcon>
                   <ListItemText primary={<span style={{
                    fontSize: "14px", fontWeight: 500,
                    color: selectedcolorstylefaqaccount === subchild.id ? "#99cb51" : "#171725",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  >
                    {subchild.title}
                  </span>} />
                </ListItem>
              </Box>
            ))}
            </span>
          </Drawer>
           </div>
      </div>
    </>

    //Merge Engine End DefaultContainer
  );
  // Customizable Area End

}
// Customizable Area Start