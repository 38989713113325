export const logo = require("../assets/logo.png");
export const avatar = require("../assets/avatar.png");
export const backgroundTwoHgFormula = require("../assets/backgroundTwoHgFormula.png");
export const backgroundOneHgFormula_new = require("../assets/backgroundOneHgFormula_new.png");
export const img_bkg = require("../assets/img_bkg.png");
export const img_bkg2 = require("../assets/img_bkg2.png");
export const img_bkg3 = require("../assets/img_bkg3.png");
export const img_bkg4 = require("../assets/img_bkg4.png");
export const img_bkg5 = require("../assets/img_bkg5.png");
export const overviewicon = require("../assets/Overviewicon.png");
export const home_icon = require("../assets/homeIconset.png");
export const getin_touchicon = require("../assets/getintouchicon.png");
export const Faqicon = require("../assets/Faqicon.png");
export const account_setting = require("../assets/accountsetting.png");
export const students_icon = require("../assets/studentsicon.png");
export const bullets = require("../assets/Bullets.png");
export const groupmenu = require("../assets/groupmenu.png");
export const H_omeicon = require("../assets/Homeicon.png");
export const account_green = require("../assets/accountgreen.png");
export const overviewgreen = require("../assets/overviewgreen.png");
export const studentgreen = require("../assets/studentgreen.png");
export const Faqgreen = require("../assets/Faqgreen.png");
export const bulletsgreen = require("../assets/bulletsgreen.png");
export const Backgroundimg = require("../assets/Backgroundimg.png");
export const HomeSchoolRecomender = require("../assets/HomeSchoolRecomender.png");
export const acc1 = require("../assets/acc1.png");
export const acc2 = require("../assets/acc2.png");
export const acc3 = require("../assets/acc3.png");
export const acc4 = require("../assets/acc4.png");
export const partner_school1 = require("../assets/partner_school1.png");
export const partner_school2 = require("../assets/partner_school2.png");
export const partner_school3 = require("../assets/partner_school3.png");
export const robot = require("../assets/robot.png");
export const call_bubble = require("../assets/call_bubble.png");
export const chat_bubble = require("../assets/chat_bubble.png");
export const introvideo_thumbnail = require("../assets/introvideo_thumbnail.png");
export const questions = require("../assets/questions.png");
